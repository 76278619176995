import React, { ReactNode } from 'react';
import styles from './link.module.scss';

interface IProps {
    children: ReactNode,
    href?: string,
    target?: string,
}

const Link: React.SFC<IProps> = (props) => {
    return (<a href={props.href} target={props.target} className={styles.link}>{props.children}</a>);
}

Link.defaultProps = {
    href: '#',
    target: undefined,
};

export default Link;