import { SagaIterator } from '@redux-saga/core';
import { getContext, apply } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import IRecaptchaService from '../../domain/services/IRecaptchaService';
import RecaptchaService from '../../domain/services/RecaptchaService';
import RecaptchaErrorAction from '../actions/RecaptchaErrorAction';

// re-triggers ReCAPTCHA on error
export default function* handleRecaptchaError(_: RecaptchaErrorAction): SagaIterator {
    const container: Container = yield getContext('container');
    const recaptchaService: IRecaptchaService = container.get<IRecaptchaService>(RecaptchaService);

    yield apply(recaptchaService, recaptchaService.trigger, []);
}