import { SagaIterator } from '@redux-saga/core';
import { put } from '@redux-saga/core/effects';
import ActionTypes from '../actions/ActionTypes';
import ClearConsentIdAction from '../actions/ClearConsentIdAction';

export default function* handleClearConsentIdInit(action: ClearConsentIdAction): SagaIterator {
    yield put<ClearConsentIdAction>({
        type: ActionTypes.CLEAR_CONSENT_ID,
    });
}
