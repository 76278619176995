import React, { createRef, RefObject } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import './global.scss';
import configureStore from './store';
import ActionTypes from './redux/actions/ActionTypes';
import BankSelect from './containers/BankSelect/BankSelectPage';
import ErrorContainer from './containers/error/ErrorContainer';
import configureContainer from './di/configureContainer';
import { Switch, Route, Router } from 'react-router';
import { createMemoryHistory } from 'history';
import ProviderSelectionPage from './containers/providerSelection/ProviderSelectionPage';
import ContentLayout from './components/app/ContentLayout';
import ConsentPage from './containers/consent/ConsentPage';
import PaymentAccountConfirmationSummaryPage from './containers/paymentAccountConfirmationSummary/PaymentAccountConfirmationSummaryPage'
import ConnectionSuccessPage from './containers/connectionSuccess/ConnectionSuccessPage';
import LoaderContainer from './containers/loader/LoaderContainer';
import ReCAPTCHA from 'react-google-recaptcha';
import Recaptcha from './containers/common/Recaptcha';
import getCorrelationId from './utils/CorrelationIdHelper';
import InitialScreen from './components/app/InitialScreen';

let recaptchaRef: RefObject<ReCAPTCHA> = createRef<ReCAPTCHA>();
const correlationId: String = getCorrelationId();

const container = configureContainer(recaptchaRef, correlationId);
const history = createMemoryHistory();

const store = configureStore(history, {
  container: container,
  correlationId: correlationId,
});
store.dispatch({ type: ActionTypes.INIT_APPLICATION });

const App: React.FC = () => {
  return (
    <>
      <ReduxProvider store={store}>
        <ContentLayout>
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={InitialScreen} />
              <Route exact path="/bank-select" component={BankSelect} />
              <Route exact path="/error" component={ErrorContainer} />
              <Route exact path="/end-success" component={ConnectionSuccessPage} />
              <Route exact path="/provider-selection" component={ProviderSelectionPage} />
              <Route exact path="/payment-account-confirmation-summary" component={PaymentAccountConfirmationSummaryPage} />
              <Route exact path="/consent" component={ConsentPage} />
            </Switch>
          </Router>
          <Recaptcha
            size={application.recaptcha.SIZE}
            sitekey={application.recaptcha.SITE_KEY}
            reference={recaptchaRef}
          />
          <LoaderContainer />
        </ContentLayout>
      </ReduxProvider>
    </>
  );
}

export default App;
