import React from 'react';
import { Trans } from 'react-i18next';
import Link from '../links/Link';

const YapilyDisclaimer: React.FC = () => {
    return (
      /* prettier-ignore */ <p>
        <Trans>
          DirectID is operating under a license from Yapily Connect UAB. Yapily Connect UAB is authorised and regulated by the Bank of Lithuania under Payments Law (LB002045) for the provision of Account Information and Payment Initiation services. For further details please see our <Link href={"https://www.direct.id/policies"} target="_blank">policies</Link>.
        </Trans>
      </p>
    );
};

export default YapilyDisclaimer;