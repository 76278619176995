import { injectable, inject } from 'inversify';
import IAccessTokenStorage from './IAccessTokenStorage';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import CallbackTokenScopeEnum from '../../types/enums/callbackTokenScopeEnum';

const DID_TOKEN_STORE_KEY = 'did.access_token';
const DID_CALLBACK_TOKEN_SCOPE_STORE_KEY = 'did.callback_token_scope';

@injectable()
export default class AccessTokenStorage implements IAccessTokenStorage {
    @inject(ThirdPartyTypes.SessionStorage)
    private sessionStorage!: Storage;

    setToken(token: string): void {
        this.sessionStorage.setItem(DID_TOKEN_STORE_KEY, token);
    }

    hasToken(): boolean {
        return null !== this.sessionStorage.getItem(DID_TOKEN_STORE_KEY);
    }
    
    getToken(): string | null {
        return this.sessionStorage.getItem(DID_TOKEN_STORE_KEY);
    }

    setCallbackTokenScope(callbackTokenScope: string): void {
        this.sessionStorage.setItem(DID_CALLBACK_TOKEN_SCOPE_STORE_KEY, callbackTokenScope);
    }
    
    hasCallbackTokenScope(callbackTokenScope: CallbackTokenScopeEnum): boolean {
        return this.sessionStorage.getItem(DID_CALLBACK_TOKEN_SCOPE_STORE_KEY) === callbackTokenScope;
    }
}