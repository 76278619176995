import React from 'react';
import {useTranslation} from 'react-i18next';
import IAccountProps from '../../types/IAccountProps';
import getSymbolFromCurrency from 'currency-symbol-map';
import styles from './connectionSummaryTile.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    account: IAccountProps;
}

const maskAccountNumber = (accNumber: string | null) => {
    if (!accNumber) {
        return '****';
    }

    if (accNumber.length <= 4) {
        const masked = '*'.repeat(4 - accNumber.length);
        return `${masked}${accNumber}`;
    }

    const masked = '*'.repeat(accNumber.length - 4);
    const visible = accNumber.slice(-4);
    return `${masked}${visible}`;
};

const formatBalance = (balance: number, currencyCode: string) => {
    if (balance >= 0) {
        return `${getSymbolFromCurrency(currencyCode)}${balance.toFixed(2)}`;
    }

    const balanceStr = balance.toFixed(2).slice(1);

    return `-${getSymbolFromCurrency(currencyCode)}${balanceStr}`;
};

const ConnectionSummaryTile: React.FC<IProps> = ({ account }: IProps) => {
    const { t } = useTranslation();
    return (<div className={styles.wrapper}>
        <div className={styles.inner}>
            <ul className={styles.list}>
                <li className={styles.accountNumber}>
                    {`${t('Account')} : ${maskAccountNumber(account.accountNumber)}`}
                </li>
                {typeof account.currentBalance !== 'undefined' && account.currentBalance !== null && (
                <li className={styles.balance}>
                    {`${t('Balance')} : ${formatBalance(account.currentBalance, account.currencyCode)}`}
                </li>
                )}
            </ul>
            <div className={styles.iconWrapper}>
                <FontAwesomeIcon icon={faLock} />
            </div>
        </div>
    </div>);
};

export default ConnectionSummaryTile;