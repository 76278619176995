import ResponseDto from '../dto/IConsentConnectionResponseDto';
import IConsentConnectionService from './IConsentConnectionService';
import { injectable, inject } from 'inversify';
import { AxiosInstance } from 'axios';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import { GatewayServiceEndpoints } from '../../constants/apiRoutes';

@injectable()
export default class ConsentConnectionService implements IConsentConnectionService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async createConsentConnection(url: string, consentId: string): Promise<ResponseDto> {
        const response = await this.apiClient.post<ResponseDto>(
            GatewayServiceEndpoints.createConsentConnection(url, consentId)
        );

        return response.data;
    }

    public async reauthenticateConsentConnection(url: string, consentId: string, token: string): Promise<ResponseDto> {
        const response = await this.apiClient.post<ResponseDto>(
            GatewayServiceEndpoints.reauthenticateConsentConnection(url, consentId),
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    }
}
