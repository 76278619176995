import { SagaIterator } from "@redux-saga/core";
import { put } from "@redux-saga/core/effects";
import { goBack, RouterAction } from "react-router-redux";
import ActionTypes from "../actions/ActionTypes";
import GoBackAction from "../actions/GoBackAction";
import ClearConsentIdAction from "../actions/ClearConsentIdAction";

export default function* handleGoBack(_: GoBackAction): SagaIterator {
    yield put<ClearConsentIdAction>({
        type: ActionTypes.CLEAR_CONSENT_ID,
    });
    yield put<RouterAction>(goBack());
}
