import ActionTypes from '../actions/ActionTypes';
import { take, getContext, put, select } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import IAccessTokenStorage from '../../domain/services/IAccessTokenStorage';
import AccessTokenStorage from '../../domain/services/AccessTokenStorage';
import IConsentStorage from '../../domain/services/IConsentStorage';
import ConsentStorage from '../../domain/services/ConsentStorage';
import { SimpleApplicationAction, InitialisationAction, ErrorAction } from '../actions/ApplicationAction';
import LoaderAction from '../actions/LoaderAction';
import { RouterAction, push } from 'react-router-redux';
import ProviderAuthStatusEnum from '../../types/enums/providerAuthStatusEnum';
import CallbackTokenScopeEnum from '../../types/enums/callbackTokenScopeEnum';
import { GetConnectionSummaryAction } from '../actions/GetConnectionSummaryAction';
import getApplicationState from '../selectors/getApplicationState';
import { IApplicationProps } from '../../types/IApplicationProps';
import { GetPaymentAccountConfirmationSummaryAction } from '../actions/GetPaymentAccountConfirmationSummaryAction';
import InitReauthenticateFinalStageAction from '../actions/InitReauthenticateFinalStageAction';

export default function* initFinalStage(action: InitialisationAction) {
    const container: Container = yield getContext('container');
    const applicationState: IApplicationProps = yield select(getApplicationState);

    const accessTokenStorage: IAccessTokenStorage = container.get<IAccessTokenStorage>(AccessTokenStorage);
    const consentStorage: IConsentStorage = container.get<IConsentStorage>(ConsentStorage);

    let url: string =
        action.payload.state!.ProviderAuthStatus === ProviderAuthStatusEnum.Success ? '/end-success' : '/end-error';

    if (applicationState.reAuthentication) {
        yield put<InitReauthenticateFinalStageAction>({
            type: ActionTypes.INIT_REAUTHENTICATE_FINAL_STAGE,
            payload: consentStorage.getConsentEnd()!,
        });

        if (applicationState.error) {
            yield put<ErrorAction>({
                type: ActionTypes.INIT_REAUTHENTICATE_STAGE_ERROR,
                payload: applicationState.error,
            });
            return;
        }
    }

    if (accessTokenStorage.hasToken() && action.payload.state!.ProviderAuthStatus === ProviderAuthStatusEnum.Success) {
        if (accessTokenStorage.hasCallbackTokenScope(CallbackTokenScopeEnum.Aisp)) {
            yield put<GetConnectionSummaryAction>({
                type: ActionTypes.GET_CONNECTION_SUMMARY,
                payload: accessTokenStorage.getToken()!,
            });

            yield take(ActionTypes.GET_CONNECTION_SUMMARY_SUCCESS);

            url = '/end-success';
        } else if (accessTokenStorage.hasCallbackTokenScope(CallbackTokenScopeEnum.PaymentConfirmation)) {
            yield put<GetPaymentAccountConfirmationSummaryAction>({
                type: ActionTypes.GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY,
                payload: accessTokenStorage.getToken()!,
            });

            yield take(ActionTypes.GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_SUCCESS);

            url = '/payment-account-confirmation-summary';
        }
    }

    if (applicationState.error) {
        yield put<ErrorAction>({
            type: ActionTypes.REDIRECTION_ERROR,
            payload: applicationState.error,
        });
        return;
    }

    yield put<RouterAction>(push(url));

    yield put<SimpleApplicationAction>({
        type: ActionTypes.INIT_FINAL_STAGE_SUCCESS,
    });

    yield put<LoaderAction>({
        type: ActionTypes.LOADER_EVENT,
        payload: null,
    });
}
