import { SagaIterator } from '@redux-saga/core';
import { put, select } from '@redux-saga/core/effects';
import { IApplicationState } from '../../types/applicationState';
import ActionTypes from '../actions/ActionTypes';
import CompleteBankChoiceAction from '../actions/CompleteBankChoiceAction';
import { RouterAction, push } from 'react-router-redux';
import CompleteProviderChoiceAction from '../actions/CompleteProviderChoiceAction';
import UpdateBankChoiceAction from '../actions/UpdateBankChoiceAction';
import { IBankProps } from '../../types/IBankProps';
import CreateProviderSelection from '../actions/CreateProviderSelectionAction';
import getProviderSelection from '../../utils/providerSelectionHelper';

export function getBrandByBrandIdSelector(state: IApplicationState, brandId: number): IBankProps {
    const bank = state.banks.data.find((bank) => bank.brandId === brandId);

    if (!bank) {
        throw Error(`No providers found for brand with brandId: ${brandId}`);
    }

    return bank;
}

export default function* handleBrandSelection(action: CompleteBankChoiceAction): SagaIterator {
    let bank: IBankProps;
    try {
        bank = yield select(getBrandByBrandIdSelector, action.payload.brandId);
    } catch (e) {
        //TODO: dispatch an error event
        return;
    }

    yield put<UpdateBankChoiceAction>({
        type: ActionTypes.UPDATE_BANK_SELECTION,
        payload: {
            bank,
        },
    });

    if (bank.providers.length > 1) {
        yield put<CreateProviderSelection>({
            type: ActionTypes.CREATE_PROVIDER_SELECTION,
            payload: {
                providerSelection: getProviderSelection(bank),
            },
        });

        yield put<RouterAction>(push('/provider-selection'));

        return;
    }

    const firstProvider = bank.providers.first();

    yield put<CompleteProviderChoiceAction>({
        type: ActionTypes.COMPLETE_PROVIDER_SELECTION,
        payload: { providerId: firstProvider.providerId },
    });
}
