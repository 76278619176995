import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import { GetConnectionSummarySuccessAction } from '../actions/GetConnectionSummaryAction';
import IConnectionSummaryProps from '../../types/IConnectionSummaryProps';

type NullableProps = IConnectionSummaryProps | null;

const connectionSummaryReducer: Reducer<NullableProps, GetConnectionSummarySuccessAction> = (state: NullableProps = null, action: GetConnectionSummarySuccessAction): NullableProps => {
    if (action.type !== ActionTypes.GET_CONNECTION_SUMMARY_SUCCESS) {
        return state;
    }

    return action.payload;
};

export default connectionSummaryReducer;