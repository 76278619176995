import React, { ReactNode } from 'react';
import styles from './toggleButton.module.scss';
import Button from './Button';

interface IProps {
    children: ReactNode,
    active: boolean,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    isDisabled?: boolean,
}

const ToggleButton: React.FC<IProps> = (props) => {

    return (<Button  className={props.active? styles.activetoggle:  styles.toggle } {...props}></Button>);
}

ToggleButton.defaultProps = {
    isDisabled: false,
    active: false,
};

export default ToggleButton;