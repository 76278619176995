import { SagaIterator } from '@redux-saga/core';
import { getContext, apply, select, put } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import ConsentAcceptedAction from '../actions/ConsentAcceptedAction';
import IRecaptchaService from '../../domain/services/IRecaptchaService';
import RecaptchaService from '../../domain/services/RecaptchaService';
import ActionTypes from '../actions/ActionTypes';
import { ErrorAction } from '../actions/ApplicationAction';
import IValidationTokenStorage from '../../domain/services/IValidationTokenStorage';
import ValidationTokenStorage from '../../domain/services/ValidationTokenStorage';
import ValidationTokenUpdateAction from '../actions/ValidationTokenUpdateAction';
import ReauthenticateConsentConnectionAction from '../actions/ReauthenticateConsentConnectionAction';
import getApplicationState from '../selectors/getApplicationState';
import { IApplicationProps } from '../../types/IApplicationProps';

export default function* handleConsentAccepted(_: ConsentAcceptedAction): SagaIterator {
    const container: Container = yield getContext('container');
    const recaptchaService: IRecaptchaService = container.get<IRecaptchaService>(RecaptchaService);
    const accessTokenManager: IValidationTokenStorage = container.get<IValidationTokenStorage>(ValidationTokenStorage);

    const applicationState: IApplicationProps = yield select(getApplicationState);

    try {
        if (applicationState.useValidationToken) {
            const validationToken = accessTokenManager.getValidationToken();
            if (validationToken === null) {
                yield put<ErrorAction>({
                    type: ActionTypes.CONSENT_ACCEPTED_ERROR,
                    payload: new Error('Validation Token not present'),
                });
                return;
            }

            yield put<ValidationTokenUpdateAction>({
                type: ActionTypes.VALIDATION_TOKEN_UPDATE,
                payload: { validationToken: validationToken },
            });
        } else if (applicationState.consentId) {
            yield put<ReauthenticateConsentConnectionAction>({
                type: ActionTypes.REAUTHENTICATE_CONSENT_CONNECTION,
                payload: { consentId: applicationState.consentId },
            });
        } else {
            yield apply(recaptchaService, recaptchaService.trigger, []);
        }
    } catch (error) {
        yield put<ErrorAction>({
            type: ActionTypes.CONSENT_ACCEPTED_ERROR,
            payload: error,
        });
    }
}
