import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import { ValidateBankDataAction } from '../actions/ValidateBankDataAction';
import IPaymentAccountConfirmationSummaryProps from '../../types/IPaymentAccountConfirmationSummaryProps';
import ApplicationAction from '../actions/ApplicationAction';

const initialState: IPaymentAccountConfirmationSummaryProps = {
    accounts: [],
    countryCode: '',
    customerName: '',
}

type NullableProps = IPaymentAccountConfirmationSummaryProps | null;

const paymentAccountConfirmationSummaryReducer: Reducer<IPaymentAccountConfirmationSummaryProps, ValidateBankDataAction> =
    (state: IPaymentAccountConfirmationSummaryProps = initialState, action: ApplicationAction<IPaymentAccountConfirmationSummaryProps>): IPaymentAccountConfirmationSummaryProps => {
        if(action.type === ActionTypes.ACCOUNT_NUMBER_VALIDATION_COMPLETE || 
           action.type === ActionTypes.BANK_CODE_VALIDATION_COMPLETE || 
           action.type === ActionTypes.VALIDATE_BANK_DATA_COMPLETED){
            return { ...action.payload };
        }

        return state;
};

export default paymentAccountConfirmationSummaryReducer;