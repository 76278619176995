import React, { useState} from 'react';
import styles from './paymentAccountConfirmationSummaryPage.module.scss';
import PrimaryButton from '../buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import SecondaryButton from '../buttons/SecondaryButton';
import AccountInputValidation from './accountInputValidation'
import IPaymentAccountConfirmationSummaryProps from '../../types/IPaymentAccountConfirmationSummaryProps';
import IPaymentConfirmationAccountDataProps from '../../types/IPaymentConfirmationAccountDataProps';
import SelectAccountPage from '../../components/paymentAccountConfirmationSummary/selectAccount';

interface IProps {
    confirm: (account:IPaymentConfirmationAccountDataProps) => void,
    finishConnection: () => void,
    paymentAccountConfirmationSummary: IPaymentAccountConfirmationSummaryProps,
    onAccountNumberChange: (value:string, accountId:string ) => void;
    onBankCodeChange: (value:string, accountId:string ) => void;
};


const PaymentAccountConfirmationSummaryPage: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const customerName = props.paymentAccountConfirmationSummary.customerName;
    const [selectedAccountValue, setSelectedAccountValue] = useState<IPaymentConfirmationAccountDataProps>(props.paymentAccountConfirmationSummary.accounts.first());
    const onAccountSelectionChange = (account: IPaymentConfirmationAccountDataProps) => {
        setSelectedAccountValue(account);
    };
    return (
        <div className={styles.screen}>
            <div>
                <h1>{t("Select an account for payments")}</h1>
                <div className={styles.content}>
                    {t('Your account information has been securely shared with {{customerName}}. Please select the account you wish to use for the payments.', { customerName })}
                </div>
                <SelectAccountPage
                    paymentAccountConfirmationSummary={
                        props.paymentAccountConfirmationSummary
                    }
                    onAccountSelectionChange={onAccountSelectionChange}
                    selectedAccountId={selectedAccountValue.accountId}
                />
                <AccountInputValidation
                    data={selectedAccountValue}
                    onAccountNumberChange={props.onAccountNumberChange}
                    onBankCodeChange={props.onBankCodeChange}
                    countryCode={props.paymentAccountConfirmationSummary.countryCode}
                />
            </div>
            <div className={styles.buttonsWrapper}>
                <div className={styles.buttonWrapper}>
                    <SecondaryButton onClick={props.finishConnection}>
                        {t("Quit")}
                    </SecondaryButton>
                </div>
                <div className={styles.buttonWrapper}>
                    <PrimaryButton
                        isDisabled={
                            !(selectedAccountValue.accountNumberFormFieldValue.isValid &&
                                selectedAccountValue.bankCodeFormFieldValue.isValid)}
                        onClick={() => props.confirm({ ...selectedAccountValue })}
                    >
                        {t("Confirm")}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};
export default PaymentAccountConfirmationSummaryPage;