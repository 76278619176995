import React from 'react';
import { useTranslation } from 'react-i18next';
import commonStyles from './style/endResultStageCommon.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    bankName: string;
    companyName: string;
    extendedConsentEnd?: Date;
}

const BankUpdated: React.FC<IProps> = ({ bankName, companyName, extendedConsentEnd }: IProps) => {
    const { t } = useTranslation();
    return (
        <div className={commonStyles.topWrapper}>
            <FontAwesomeIcon icon={faCheck} className={commonStyles.icon} />
            <h1>{t('Bank access updated')}</h1>
            <div className={commonStyles.topContent}>
                {extendedConsentEnd?
                t('Access to your {{bankName}} account data has been updated.', {
                    bankName: bankName,
                }):
                t('Your {{bankName}}  account data has been successfully reconnected.', {
                    bankName: bankName,
                })}
            </div>
            {!extendedConsentEnd || 
            <div className={commonStyles.content}>
                {t('{{companyName}} will now be able to access data until the following date:', {
                    companyName: companyName,
                })}
            </div>
            }
            {!extendedConsentEnd || 
            <div className={commonStyles.dateContent}>{
                extendedConsentEnd.toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
            })}
            </div>
            }
        </div>
    );
};

export default BankUpdated;
