import qs from 'qs';
import { injectable, inject } from 'inversify';
import IStateInitializer from './IStateInitializer';
import { IApplicationProps } from '../types/IApplicationProps';
import ThirdPartyTypes from '../di/ThirdPartyTypes';
import { Base64_ as Base64 } from 'js-base64';
import ProviderAuthStatusEnum from '../types/enums/providerAuthStatusEnum';
import { isNullOrUndefined } from 'util';

@injectable()
export default class StateInitializer implements IStateInitializer {
    @inject(ThirdPartyTypes.Base64)
    private base64!: Base64;

    public init(): IApplicationProps {
        const queryParameters = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
            decoder: decodeURIComponent,
        });

        const application = (({
            client_id,
            provider_id,
            provider_name,
            config_id,
            customer_ref,
            bank_code,
            country_code,
            redirect_uri,
            state,
            error,
            useValidationToken,
            consent_id,
            reauth,
            invitation_id
        }) => {
            // probably not the best way to check?
            if (StateInitializer.isInteger(String(provider_id))) {
                provider_id = parseInt(String(provider_id));
            } else {
                provider_id = undefined;
            }

            const data = {
                clientId: client_id ? String(client_id) : '',
                providerId: provider_id ? Number(provider_id) : undefined,
                providerName: provider_name ? String(provider_name) : undefined,
                configurationId: config_id ? String(config_id) : undefined,
                customerReference: customer_ref ? String(customer_ref) : '',
                bankCode: bank_code ? String(bank_code) : undefined,
                countryCode: country_code ? String(country_code) : undefined,
                redirectUrl: redirect_uri ? String(redirect_uri) : undefined,
                state: state ? JSON.parse(this.base64.decode(String(state))) : undefined,
                error: error ? String(error) : undefined,
                useValidationToken: Boolean(useValidationToken),
                consentId: consent_id ? String(consent_id) : undefined,
                reAuthentication: reauth === "True",
                invitationId: invitation_id ? String(invitation_id) : undefined
            };

            if (data.redirectUrl && data.state) {
                data.redirectUrl = StateInitializer.appendQueryParamsToRedirectUri(
                    data.redirectUrl,
                    data.state.ProviderAuthStatus,
                    data.customerReference,
                    data.state.ConsentId,
                    data.error
                );
            }

            return data;
        })(queryParameters);
        return {
            ...application,
            language: StateInitializer.getLocale(),
            hideExitButton: false,
            rpName: ''
        };
    }

    private static appendQueryParamsToRedirectUri(
        redirectUri: string,
        status: ProviderAuthStatusEnum,
        customerReference: string,
        consentId: string | undefined,
        error: string | undefined
    ) {
        const url = new URL(redirectUri);

        const queryParameters = qs.parse(url.search, {
            ignoreQueryPrefix: true,
            decoder: decodeURIComponent,
        });

        queryParameters['state'] = status.toLowerCase();
        queryParameters['customer_ref'] = customerReference;
        if (!isNullOrUndefined(error)) {
            queryParameters['error'] = error;
        }
        if(status.toLocaleLowerCase() === 'success'){
            queryParameters['consent_id'] = consentId;
        }
        url.search = qs.stringify(queryParameters);

        return url.toString();
    }

    private static isInteger(data: string): boolean {
        return /^\d+$/.test(data);
    }

    private static getLocale(): string {
        if (navigator.languages && navigator.languages.length !== 0) {
            return navigator.languages[0];
        }

        return navigator.language || 'en';
    }
}
