import { SagaIterator } from '@redux-saga/core';
import { apply, put, delay, select, getContext } from '@redux-saga/core/effects';
import { push, RouterAction } from 'react-router-redux';
import { CompleteConnectionAction } from '../actions/CompleteConnectionAction';
import LoaderAction from '../actions/LoaderAction';
import ActionTypes from '../actions/ActionTypes';
import { IApplicationProps } from '../../types/IApplicationProps';
import getApplicationState from '../selectors/getApplicationState';
import IValidationTokenStorage from '../../domain/services/IValidationTokenStorage';
import ValidationTokenStorage from '../../domain/services/ValidationTokenStorage';
import IConsentStorage from '../../domain/services/IConsentStorage';
import ConsentStorage from '../../domain/services/ConsentStorage';
import { Container } from 'inversify';
import ConfigurationStorageService from "../../domain/services/ConfigurationStorageService";
import IConfigurationStorageService from "../../domain/services/IConfigurationStorageService";

export function* completeConnection(_: CompleteConnectionAction): SagaIterator {
    yield put<LoaderAction>({
        type: ActionTypes.LOADER_EVENT,
        payload: null,
    });

    const container: Container = yield getContext('container');
    const accessTokenManager: IValidationTokenStorage = container.get<IValidationTokenStorage>(ValidationTokenStorage);
    const consentStorage: IConsentStorage = container.get<IConsentStorage>(ConsentStorage);
    const configurationStorageService: IConfigurationStorageService = container.get<IConfigurationStorageService>(ConfigurationStorageService);
    const application: IApplicationProps = yield select(getApplicationState);
    if (application.redirectUrl) {
        yield apply(accessTokenManager, accessTokenManager.removeValidationToken, []);
        yield apply(consentStorage, consentStorage.removeConsentEnd, []);
        yield apply(configurationStorageService,configurationStorageService.removeItem,[application.clientId]);
        yield put<LoaderAction>({
            type: ActionTypes.LOADER_EVENT,
            payload: {
                display: true,
                showRecaptchaDisclaimer: false,
                spinnerText: application.providerName
                    ? `Redirecting you back to ${application.providerName}`
                    : undefined,
            },
        });
        yield put<RouterAction>(push('/'));
        yield delay(2000);
        window.location.assign(application.redirectUrl);
    } else {
        yield apply(accessTokenManager, accessTokenManager.removeValidationToken, []);
        yield apply(consentStorage, consentStorage.removeConsentEnd, []);
        yield apply(configurationStorageService,configurationStorageService.removeItem,[application.clientId]);
        window.close();
    }
}
