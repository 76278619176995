import ActionTypes from '../actions/ActionTypes';
import { getContext, put, apply, select } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import IConfigurationService from '../../domain/services/IConfigurationService';
import ConfigurationService from '../../domain/services/ConfigurationService';
import { GetSettingsSuccessAction, InitGetSettingsSuccessAction } from "../actions/GetSettings";
import { ErrorAction } from '../actions/ApplicationAction';
import { IApplicationProps } from "../../types/IApplicationProps";
import getApplicationState from "../selectors/getApplicationState";
import IConfigurationStorageService from "../../domain/services/IConfigurationStorageService";
import ConfigurationStorageService from "../../domain/services/ConfigurationStorageService";
import { ISettingsDto } from "../../domain/dto/ISettingsDto";


export default function* handleGetSettingsAction() {
    const container: Container = yield getContext('container');
    const configurationService = container
        .get<IConfigurationService>(ConfigurationService);
    const configurationStorageService = container
        .get<IConfigurationStorageService>(ConfigurationStorageService);
    const applicationState: IApplicationProps = yield select(getApplicationState);

    try {
        const settings: ISettingsDto = yield apply(configurationService, configurationService.getSettings, [applicationState.clientId, applicationState.configurationId]);

        if (!configurationStorageService.hasItem(applicationState.clientId)) {
            configurationStorageService.setItem(applicationState.clientId, JSON.stringify(settings));
        }

        yield put<GetSettingsSuccessAction>({
            type: ActionTypes.GET_SETTINGS_SUCCESS,
            payload: settings,
        });

        yield put<InitGetSettingsSuccessAction>({
            type: ActionTypes.INIT_GET_SETTINGS_SUCCESS,
            payload: { ...applicationState, rpName: settings.customerName }
        })
    } catch (error) {
        yield put<ErrorAction>({
            type: ActionTypes.GET_SETTINGS_ERROR,
            payload: error,
        });
    }
}
