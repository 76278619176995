import React from 'react';
import Link from '../links/Link';
import styles from './searchFeedback.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import Countries from '../../constants/countries';

interface IProps {
  count: number;
  countryCode: string;
}

/* prettier-ignore */
const SearchFeedback: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { count, countryCode } = props;

  return (<>
        <p>{t('Found {{count}} matches in {{countryName}}', {
            count: count, countryName: t(Countries[countryCode])
        })}:</p>
        {count === 0 && <>
            <p>{t('Your search returned no results.  Why not try one of the following')}:</p>
            <ul className={styles.searchSuggestionList}>
                <li>{t('Check your spelling')}</li>
                <li>{t('Try fewer words')}</li>
                <li><Trans>Report that <Link href={'https://support.direct.id/hc/en-us/requests/new?ticket_form_id=8774557661332'} target="_blank">your bank is missing</Link> from our records</Trans></li>
            </ul>
        </>}
    </>);
};

export default SearchFeedback;