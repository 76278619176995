import React, { ReactNode } from 'react';

interface IProps {
    className?: string,
    children: ReactNode,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    isDisabled?: boolean,
}

const Button: React.FC<IProps> = (props) => {
    return (<button className={props.className} onClick={props.onClick} disabled={props.isDisabled || false}>
        {props.children}
    </button>);
};

Button.defaultProps = {
    isDisabled: false,
};

export default Button;