import React from 'react';
import { useTranslation } from 'react-i18next';
import commonStyles from './style/endResultStageCommon.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    bankName: string;
    companyName: string;
}

const BankConnected: React.FC<IProps> = ({ bankName, companyName }: IProps) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className={commonStyles.topWrapper}>
                <FontAwesomeIcon icon={faCheck} className={commonStyles.icon} />
                <h1>{t('Bank connected!')}</h1>
            </div>
            <div className={commonStyles.topWrapper}>
                <div className={commonStyles.divGap}>
                {t(
                    'Your {{bankName}} account data has been shared safely and securely with {{companyName}}.',
                    {
                        bankName: bankName,
                        companyName: companyName,
                    }
                )}
                </div>
            </div>
        </div>
    );
};

export default BankConnected;
