import React, { SyntheticEvent } from 'react';
import styles from './scaledImage.module.scss';
import defaultLogo from '../../resources/img/bank-logo-placeholder.svg';

interface IProps {
    url?: string,
    alt: string,
    scaleWidth: number,
    scaleHeight: number,
}

interface IState {
    toScale: boolean,
    fallback: boolean,
}

class ScaledImage extends React.Component<IProps, IState> {
    public readonly state: IState = {
        toScale: false,
        fallback: false,
    }

    constructor(props: IProps) {
        super(props);

        this.resize = this.resize.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    private resize(event: SyntheticEvent<HTMLImageElement, Event>) {
        const target = event.currentTarget;

        if (target == null) {
            return;
        }

        const { scaleWidth, scaleHeight } = this.props;

        const imgWidth = target.naturalWidth;
        const imgHeight = target.naturalHeight;
        
        if (imgWidth <= scaleWidth / 2 && imgHeight <= scaleHeight / 2) {
            this.setState({
                fallback: true,
            });
        } else if (imgWidth > scaleWidth || imgHeight > scaleHeight) {
            this.setState({
                toScale:  true
            });
        }
    }

    private handleError() {
        this.setState({
            fallback: true,
        });
    }

    render() {
        const logoUrl = (!this.props.url || this.state.fallback) ?
            defaultLogo :
            this.props.url;

        return (
            <img
                className={this.state.toScale ? styles.contain : styles.cover}
                src={logoUrl}
                alt={this.props.alt}
                onLoad={this.resize}
                onError={this.handleError}
            />
        )
    }
}

export default ScaledImage;