import ActionTypes from '../actions/ActionTypes';
import { getContext, put, apply, select } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import IConnectionSummaryService from '../../domain/services/IConnectionSummaryService';
import ConnectionSummaryService from '../../domain/services/ConnectionSummaryService';
import { GetConnectionSummaryAction, GetConnectionSummarySuccessAction } from '../actions/GetConnectionSummaryAction';
import { ErrorAction } from '../actions/ApplicationAction';
import { ServiceUrls } from '../../types/ServiceUrls';
import getServiceUrls from '../selectors/getServiceUrls';

export default function* handleGetConnectionSummary(action: GetConnectionSummaryAction) {
    const container: Container =  yield getContext('container');
    const connectionSummaryService = container
        .get<IConnectionSummaryService>(ConnectionSummaryService);
    const serviceUrls: ServiceUrls = yield select(getServiceUrls);

    try {
        const summary = yield apply(connectionSummaryService, connectionSummaryService
            .getConnectionSummary, [serviceUrls.gatewayService, action.payload]);
    
        yield put<GetConnectionSummarySuccessAction>({
            type: ActionTypes.GET_CONNECTION_SUMMARY_SUCCESS,
            payload: summary,
        });
    } catch (error) {
        yield put<ErrorAction>({
            type: ActionTypes.GET_CONNECTION_SUMMARY_ERROR,
            payload: error,
        });
    }
}