import React from 'react';
import i18next from 'i18next';
import styles from './idCoTermsContent.module.scss';
import ChevronRightLight from '../icons/ChevronRightLight';

interface IProps {
    consentText: string | null;
    consentDate: Date | undefined;
    t: i18next.TFunction;
}

const Chevron = (
    <span className={styles.bulletPoint}>
        <ChevronRightLight></ChevronRightLight>
    </span>
);

const IdCoTermsContent: React.FC<IProps> = (props: IProps) => {
    const { t, consentDate, consentText } = props;

    return (
        <ul className={styles.list}>
            <li>
                {Chevron}
                <div className={styles.content}>
                    {t('Please make sure you have your online banking details to hand before confirming')}
                </div>
            </li>
            <li>
                {Chevron}
                <div className={styles.content}>{t('DirectID will not be able to see any of your login details')}</div>
            </li>
            {consentText && (
                <li>
                    {Chevron}
                    <div className={styles.content}>
                        {consentText}
                        <strong>
                            {consentDate &&
                                consentDate.toLocaleDateString(undefined, {
                                    year: 'numeric',
                                    month: 'long',
                                    day: '2-digit',
                                })}
                        </strong>
                    </div>
                </li>
            )}
            <li>
                {Chevron}
                <div className={styles.content}>
                    {t("You can revoke DirectID's access at any time via your bank's online banking services")}
                </div>
            </li>
            <li>
                {Chevron}
                <div className={styles.content}>{t('This will not affect your credit score')}</div>
            </li>
        </ul>
    );
};

export default IdCoTermsContent;
