import { SagaIterator } from '@redux-saga/core';
import { apply, getContext, put } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import CallbackTokenScopeInitializer from '../../utils/CallbackTokenScopeInitializer';
import ICallbackTokenScopeInitializer from '../../utils/ICallbackTokenScopeInitializer';
import { SimpleApplicationAction } from '../actions/ApplicationAction';
import IAccessTokenStorage from '../../domain/services/IAccessTokenStorage';
import AccessTokenStorage from '../../domain/services/AccessTokenStorage';
import ActionTypes from '../actions/ActionTypes';

export function* initCallbackTokenScopeFromFragment(_: SimpleApplicationAction): SagaIterator {
    const container: Container = yield getContext('container');
    const callbackTokenScopeInitializer: ICallbackTokenScopeInitializer = container
        .get<ICallbackTokenScopeInitializer>(CallbackTokenScopeInitializer);
    const accessTokenStorage: IAccessTokenStorage = container
        .get<IAccessTokenStorage>(AccessTokenStorage);

    const callbackTokenScope = (yield apply(callbackTokenScopeInitializer, callbackTokenScopeInitializer.init, []));

    if (callbackTokenScope) {
        yield apply(accessTokenStorage, accessTokenStorage.setCallbackTokenScope, [callbackTokenScope]);
    }

    yield put<SimpleApplicationAction>({
        type: ActionTypes.INIT_CALLBACK_TOKEN_SCOPE_COMPLETE,
    });
}