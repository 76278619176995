import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import PopulateCountryCodesAction from '../actions/PopulateCountryCodesAction';

const initialState: string[] = [];

const countryListReducer: Reducer<string[], PopulateCountryCodesAction> = (state: string[] = initialState, action: PopulateCountryCodesAction): string[] => {
    if (action.type !== ActionTypes.POPULATE_COUNTRY_CODES) {
        return state;
    }

    return action.payload;
};

export default countryListReducer;
