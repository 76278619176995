import IConfigurationService from "./IConfigurationService";
import ITermsDto from "../dto/ITermsDto";
import { AxiosInstance } from "axios";
import { injectable, inject } from "inversify";
import ThirdPartyTypes from "../../di/ThirdPartyTypes";
import { ConfigurationServiceEndpoints } from "../../constants/apiRoutes";
import { CUSTOMER_APP_ID_HEADER } from '../../constants/constants';
import IDiscoveryUrlsDto from "../dto/IDiscoveryUrlsDto"
import { ServiceUrls } from '../../types/ServiceUrls'
import {ISettingsDto} from "../dto/ISettingsDto";

@injectable()
export default class ConfigurationService implements IConfigurationService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getTerms(clientId: string, language: string, configurationId?: string): Promise<ITermsDto> {
        const url = ConfigurationService.getUrl('terms', configurationId, language);
        const terms = await this.apiClient
            .get<ITermsDto>(
                url,
                {
                    headers: {
                        [CUSTOMER_APP_ID_HEADER]: clientId,
                    },
                }
            );

        return terms.data;
    };

    public async getDiscoveryUrls(clientId: string) : Promise<ServiceUrls> {
        const urls = await this.apiClient
            .get<IDiscoveryUrlsDto>(
                `${ConfigurationServiceEndpoints.getDiscoveryUrls(clientId)}`
            );

        return {
            consentService: urls.data.consent_service,
            providerSearchService: urls.data.provider_search_service,
            gatewayService: urls.data.gateway_service,
            insightsService: urls.data.insights_service
        };
    }

    public async getSettings(clientId: string, configurationId?: string): Promise<ISettingsDto> {
        const url = ConfigurationService.getUrl('settings', configurationId);
        const settings = await this.apiClient
            .get<ISettingsDto>(
                url,
                {
                    headers: {
                        [CUSTOMER_APP_ID_HEADER]: clientId,
                    },
                }
            );

        return settings.data;
    };

    private static getUrl(urlType: 'terms' | 'settings', configurationId?: string, language?: string): string {
        let url = urlType === 'terms' ? ConfigurationServiceEndpoints.getTerms : ConfigurationServiceEndpoints.getSettings;
        url = url.replace('{name}', configurationId || 'default');
        if (language) {
            url += `?lang=${language}`;
        }

        return url;
    };
};
