import React from 'react';
import styles from './searchInput.module.scss';
import IFormInputProps from './interfaces/IFormInputProps';
import TextInputTypes from './enums/TextInputTypes';
import TextInput from './TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from "@fortawesome/free-solid-svg-icons";

interface IProps extends IFormInputProps {
    value?: string,
    type?: TextInputTypes,
    maxLength?: number,
    pattern?: string,
    placeholder?: string,
    forwardedRef?: React.RefObject<HTMLInputElement>,
}

const SearchInput: React.FC<IProps> = (props: IProps) => {
    const { onChangeValue, forwardedRef, ...passThroughProps } = props;
    
    return (
        <div className={styles.search} >
            <span className={styles.icon} >
            <FontAwesomeIcon icon={faSearch} />
            </span>
            <TextInput
                forwardedRef={forwardedRef}
                type={TextInputTypes.SEARCH}
                onChangeValue={props.onChangeValue}
                {...passThroughProps}
            />
        </div>);
}

TextInput.defaultProps = {
    type: TextInputTypes.TEXT,
};

export default SearchInput;