import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './error.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../buttons/PrimaryButton';
import PaymentAccountConfirmationErrorScreenTypeEnum from '../../types/enums/errorScreenTypeEnum'


interface IProps {
    customerName: string;
    errorType: PaymentAccountConfirmationErrorScreenTypeEnum;
    finishConnection: () => void,
}

const PaymentAccoutConfirmationError: React.FC<IProps> = ({ customerName, errorType, finishConnection }: IProps) => {
    const { t } = useTranslation();

    const bankSharedMessage = t('Your bank data has been shared with {{customerName}}, ', { customerName });
    let icon = faExclamationTriangle;
    let message = bankSharedMessage + t('but we are unable to confirm your account for payment at this time.');
    
    if (errorType === PaymentAccountConfirmationErrorScreenTypeEnum.PaymentAccountConfirmationCancelledError) {
        icon = faTimes
        message = bankSharedMessage + t('but you have not selected an account for payment.');
    }

    return (
        <div className={styles.screen}>
            <div className={styles.topWrapper}>
                <FontAwesomeIcon icon={icon} className={styles.icon} />
                <h1>{t('Payment account selection cancelled')}</h1>
                <div className={styles.content}>
                    <p>{message}</p>
                    <p>{t('You may be asked to provide more information at a later time.')}</p>
                </div>     
            </div>
            <div className={styles.buttonWrapper}>
                <PrimaryButton onClick={finishConnection}>{t('Return to {{customerName}}', { customerName })}</PrimaryButton>
            </div>
        </div>
    );
}
export default PaymentAccoutConfirmationError;
