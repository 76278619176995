import React, { ReactNode } from 'react';
import styles from './linkButton.module.scss';
import Button from './Button';

interface IProps {
    children: ReactNode,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    isDisabled?: boolean,
}

const LinkButton: React.FC<IProps> = (props) => {
    return (<Button className={styles.link} {...props}></Button>);
}

LinkButton.defaultProps = {
    isDisabled: false,
};

export default LinkButton;