import { Reducer } from 'redux'
import ActionTypes from "../actions/ActionTypes";
import IBankSelectionProps from '../../types/IBankSelectionProps';

const initialState: IBankSelectionProps = {
  loading: false,
  data: [],
  query: '',
  showAll: false,
  hasError: false,
}

const reducer: Reducer<IBankSelectionProps> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BANKS:
    case ActionTypes.SELECT_COUNTRY_CODE: {
      const query = action.payload && action.payload.query !== undefined ? action.payload.query : state.query;

      return { ...state, loading: true, data: [], query: query, showAll: false };
    }
    case ActionTypes.SHOW_ALL_BANKS: {
      return { ...state, showAll: true };
    }
    case ActionTypes.GET_BANKS_SUCCESS: {
      return { ...state, loading: false, data: action.payload, hasError: false };
    }
    case  ActionTypes.GET_BANKS_ERROR: {
      return { ...state, loading: false, data: [], showAll: false, hasError: true };
    }
    default: {
      return state;
    }
  }
}

export { reducer as getBanksReducer }