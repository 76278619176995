import { SagaIterator } from '@redux-saga/core';
import { put } from '@redux-saga/core/effects';
import ActionTypes from '../actions/ActionTypes';
import CreateConsentSuccessButtonAction from '../actions/CreateConsentSuccessButtonAction';

export default function* handleCreateConsentSuccessInit(action: CreateConsentSuccessButtonAction): SagaIterator {
    const consentId: string | undefined = action.payload.consentId;
    yield put<CreateConsentSuccessButtonAction>({
        type: ActionTypes.CREATE_CONSENT_SUCCESS,
        payload: {
            consentId
        }
    });
}
