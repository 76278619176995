import React, { createRef } from 'react';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import { IApplicationState } from '../../types/applicationState';
import BankSelectionPage from "../../components/bankSelect/BankSelectionPage";
import { GetBanksAction } from '../../redux/actions/BankSelectAction';
import { Dispatch } from 'redux';
import ResetSelectionAction from '../../redux/actions/ResetSelectionAction';

interface IBasicProps {
    searchInput?: React.RefObject<HTMLInputElement>,
    onFocusSearch?: () => void,
}

interface IStateToProps {
  query: string,
  isLoaderEnabled: Boolean,
  banksLoading: Boolean
};

interface IDispatchToProps {
    onBankSearch: (event: React.FormEvent<HTMLInputElement>) => void,
    onMount: () => void,
};

interface IProps extends IBasicProps, IDispatchToProps, IStateToProps { };

class BankSelect extends React.Component<IProps> {
  private searchInput = createRef<HTMLInputElement>();
  private onFocusSearch() {
    this.searchInput.current && this.searchInput.current.focus();
  }

  render() {
    if (this.props.isLoaderEnabled) {
      return null;
    }

    return (
        <BankSelectionPage
          {...this.props}
          searchInput={this.searchInput}
          searchQuery={this.props.query}
          onFocusSearch={this.onFocusSearch.bind(this)}
          loading={this.props.isLoaderEnabled || this.props.banksLoading}
        />
    );
  }
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
  query: state.banks.query,
  isLoaderEnabled: state.loader.display,
  banksLoading: state.banks.loading,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onBankSearch: (event: React.FormEvent<HTMLInputElement>) => dispatch<GetBanksAction>({
      type: ActionTypes.GET_BANKS,
      payload: { query: event.currentTarget.value }
    }),
    onMount: () => dispatch<ResetSelectionAction>({
      type: ActionTypes.RESET_SELECTION_ACTION,
    })
});

export default connect<IStateToProps, IDispatchToProps, IBasicProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(BankSelect);