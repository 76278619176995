import React, { useEffect } from 'react';
import styles from './bankSelectionPage.module.scss';
import SearchInput from '../forms/SearchInput';
import { useTranslation } from 'react-i18next';
import BankResultsContainer from '../../containers/BankSelect/BankResultsContainer';
import FindYourBankButton from './FindYourBankButton';
import CountryDropdownContainer from '../../containers/BankSelect/CountryDropdownContainer';

interface IProps {
    onBankSearch: (event: React.FormEvent<HTMLInputElement>) => void,
    searchInput: React.RefObject<HTMLInputElement>,
    searchQuery: string,
    onFocusSearch: () => void,
    loading: Boolean,
}

const BankSelectionPage: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();

    useEffect(()=>{
        if (window.hj) {
            window.hj(
                 'tagRecording',
                 ['Screen: Select Your Bank']
             );
        }
    },[])

    return (
        <>
            <div className={styles.bankSelectContainerContent}>
                <h1>{t('Select your bank')}</h1>
                <p>{t('Please select your bank from the list below or find it using the search box.')}</p>
                <div className={styles.searchWrapper}>
                    <div className={styles.countryDropdown}>
                        <CountryDropdownContainer />
                    </div>
                    <div className={styles.searchInput}>
                        <SearchInput
                            forwardedRef={props.searchInput}
                            placeholder={t('Start typing the name of your bank...')}
                            value={props.searchQuery}
                            onChangeValue={props.onBankSearch}
                            data-cy="bank_search_input"
                        />
                    </div>
                </div>
            </div>
            <BankResultsContainer />
            {// Not an ideal solution, but that would require a significant
                // re-factor of the whole component tree for bank selection
                // and in the future this button will be most likely gone
            !props.searchQuery && !props.loading && <FindYourBankButton focusSearch={props.onFocusSearch} />}
        </>
    );
};

export default BankSelectionPage;
