import React from 'react';
import ILoaderProps from '../../types/ILoaderProps';
import Loader from '../../components/loader/Loader';
import { connect } from 'react-redux';
import { IApplicationState } from '../../types/applicationState';

const LoaderContainer: React.FC<ILoaderProps> = (props: ILoaderProps) => <Loader {...props} />;

const mapStateToProps = (state: IApplicationState) => state.loader;

export {LoaderContainer};
export default connect<ILoaderProps, any, any, IApplicationState>(mapStateToProps)(LoaderContainer);