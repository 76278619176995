import React from 'react';
import styles from './paymentAccountConfirmationSummaryPage.module.scss';
import TextInputTypes from '../forms/enums/TextInputTypes';
import TextInput from '../forms/TextInput';

interface IProps {
    label:string | null
    placeholder:string
    isRequired:boolean;
    isValid:boolean;
    value:string | undefined;
    validationMessage?: string;
    alwaysDisplay: boolean;
    onInputChange: (event: React.FormEvent<HTMLInputElement>) => void;
    resetRef: React.RefObject<HTMLFormElement>
};

const TextInputWithValidation: React.FC<IProps> = (props: IProps) => {
    const { isRequired, alwaysDisplay, label, placeholder, value,  onInputChange, isValid, validationMessage, resetRef } = props;
    const readOnly = alwaysDisplay && !isRequired;

    return isRequired || alwaysDisplay ? (
      <form className={styles.textInput} ref={resetRef}>
        <div className={styles.content}>{label}</div>
        <TextInput
          data-hj-suppress
          type={TextInputTypes.DIGIT}
          placeholder={placeholder}
          value={value ? value : undefined}
          onChangeValue={onInputChange}
          readOnly={readOnly}
        />
        {!isValid ? (
          <div className={styles.error}>{validationMessage}</div>
        ) : null}
      </form>
    ) : null;
};

export default TextInputWithValidation;