
enum ConsentStatus {
    Active = 'Active',
    Pending = 'Pending',
    Revoked = 'Revoked',
    Abandoned = 'Abandoned',
    Failed = 'Failed',
    Expired = 'Expired',
};

export default ConsentStatus;