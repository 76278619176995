import React, {useEffect, useState} from 'react';
import styles from './header.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from "@fortawesome/free-solid-svg-icons";
import didLogoWhite from "../../resources/img/direct-id-logo-white.svg"
import {ISettingsDto} from "../../domain/dto/ISettingsDto";
import {IApplicationState} from "../../types/applicationState";
import {connect} from "react-redux";

interface IStateToProps {
    settings: ISettingsDto
}

interface IProps {
    className?: string,
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
}

const Header: React.FC<IStateToProps & IProps> = ({ settings }) => {
    const [customerLogo, setCustomerLogo] = useState<string | null>(null);
    const [directIdLogo, setDirectIdLogo] = useState<string>(didLogoWhite);

    useEffect(() => {
        if (settings?.logoUrl) {
            setCustomerLogo(settings.logoUrl);
        }

        if (settings?.directIDLogoUrl) {
            setDirectIdLogo(settings.directIDLogoUrl);
        }
    }, [settings]);

    return (<header className={styles.header}>
        {customerLogo ?
            <img src={customerLogo} alt="Logo" className={styles.customerLogo} />
            :
            (
                <div className={styles.lock}>
                    <FontAwesomeIcon icon={faLock} />
                </div>
            )}
        <img src={directIdLogo} alt="Direct ID" className={styles.didLogo} />
    </header>);
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    settings: state.settings
});

export default connect<IStateToProps, any, IProps, IApplicationState>(mapStateToProps)(Header);
