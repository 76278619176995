import ActionTypes from '../actions/ActionTypes';
import { getContext, put, apply, take, select } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import IPaymentAccountConfirmationService from '../../domain/services/IPaymentAccountConfirmationService';
import PaymentAccountConfirmationService from '../../domain/services/PaymentAccountConfirmationService';
import { GetPaymentAccountConfirmationSummaryAction, 
         GetPaymentAccountConfirmationSummarySuccessAction,
         GetPaymentAccountConfirmationSummaryErrorAction } from '../actions/GetPaymentAccountConfirmationSummaryAction';
import { ValidateBankDataAction } from '../actions/ValidateBankDataAction';
import IPaymentAccountConfirmationSummaryProps from '../../types/IPaymentAccountConfirmationSummaryProps';
import { ServiceUrls } from '../../types/ServiceUrls';
import getServiceUrls from '../selectors/getServiceUrls';

export default function* handleGetPaymentAccountConfirmationSummary(action: GetPaymentAccountConfirmationSummaryAction) {
    const container: Container =  yield getContext('container');
    const paymentAccountConfirmationService = container
        .get<IPaymentAccountConfirmationService>(PaymentAccountConfirmationService);
    const serviceUrls: ServiceUrls = yield select(getServiceUrls);

    try {
        const summary : IPaymentAccountConfirmationSummaryProps =
            yield apply(
                paymentAccountConfirmationService,
                paymentAccountConfirmationService.getPaymentAccountConfirmationSummaryData,
                [serviceUrls.gatewayService, action.payload]
            );

            if(!summary || !summary.accounts || summary.accounts.length === 0) {
                throw new Error("no account data found");
            }

            yield put<ValidateBankDataAction>({
                type: ActionTypes.VALIDATE_BANK_DATA,
                payload: summary
            });

            yield take(ActionTypes.VALIDATE_BANK_DATA_COMPLETED);

            yield put<GetPaymentAccountConfirmationSummarySuccessAction>({
                type: ActionTypes.GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_SUCCESS
            });
    } catch (error) {
        yield put<GetPaymentAccountConfirmationSummaryErrorAction>({
            type: ActionTypes.GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_ERROR,
            payload: error,
        });
    }
}