import ValidationHelpers from './validationHelpers';
import IFormFieldValue from '../../types/IFormFieldValue';
import PaymentConfirmationErrorEnum from '../../types/enums/PaymentConfirmationValidationErrorEnum';

const minLength: Number = 4;
const maxLength: Number = 17;

export default class AccountNumberValidator {

    static bankDataIsValid = (accountNumber: string | null | undefined) : boolean => {
        if(!accountNumber ||
            ValidationHelpers.isMasked(accountNumber) ||
            !ValidationHelpers.isBetweenLengthRange(accountNumber, minLength, maxLength)) {
            return false;
        }

        return true;
    }

    static isValidAccountNumber = (accountNumber: string | null | undefined, originalValue: string | null) : IFormFieldValue => {

        let validationResult: IFormFieldValue = {
            isValid: false,
            userValue: accountNumber as string,
            validationError: null,
        };

        if(!accountNumber){
            return validationResult;
        }
        else
        if(!ValidationHelpers.isBetweenLengthRange(accountNumber!, minLength, maxLength)) {
            validationResult.validationError = PaymentConfirmationErrorEnum.ACCOUNT_NUMBER_FORMAT_INVALID;
            return validationResult;
        }
        else
        if(!ValidationHelpers.digitsAppearInOrder(accountNumber, originalValue)) {
            validationResult.validationError = PaymentConfirmationErrorEnum.ACCOUNT_NUMBER_DOES_NOT_MATCH_BANK;
            return validationResult;
        }

        validationResult.isValid = true;
        return validationResult;
    };
}