import React from 'react';
import styles from './providerTile.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    name: string,
    onClick: () => void,
};

const ProviderTile: React.FC<IProps> = (props: IProps) => {
    return (<div tabIndex={0} className={styles.container} onClick={props.onClick}>
        <span className={styles.label} data-cy="bank_name_provider_tile">{props.name}</span>
        <span className={styles.arrow}><FontAwesomeIcon icon={faChevronRight} /></span>
    </div>);
};

export default ProviderTile;