import React from 'react';
import Link from '../links/Link';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface IProps {
    errorCode?: string
}

const ErrorSupportLink: React.FC<IProps> = ({errorCode}: IProps) => {
    const { t } = useTranslation();

    const supportEmailSubject = `DIDConnect Support ${moment().toISOString()}${errorCode? `, error code: ${errorCode}`: ''}`;

    return (
      <Link href={`mailto:support@directid.co?subject=${supportEmailSubject}`} target="_blank">{t('support team')}</Link>
    );
};

export default ErrorSupportLink;