import React from 'react';
import styles from './error.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import SecondaryButton from '../buttons/SecondaryButton';

interface IProps {
    chooseDifferentBank: () => void,
    finishConnection: () => void,
};

const DataSharingCancelled: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.screen}>
            <div className={styles.topWrapper}>
                <FontAwesomeIcon icon={faTimes} className={styles.icon} />
                <h1>{t('Bank data sharing cancelled')}</h1>
                <div className={styles.content}>
                    {t('None of your bank data was shared')}
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <PrimaryButton onClick={props.chooseDifferentBank}>{t('Choose a different bank')}</PrimaryButton>
                <SecondaryButton onClick={props.finishConnection}>{t('Quit')}</SecondaryButton>
            </div>
        </div>);
};

export default DataSharingCancelled;