import React, {useEffect} from 'react';
import styles from './consentPageContent.module.scss';
import i18next from 'i18next';
import ConditionalImage from '../app/ConditionalImage';
import ConsentStatus from '../../domain/enums/ConsentStatus';

interface IProps {
    t: i18next.TFunction;
    bankName: string;
    bankLogoUrl?: string;
    oldConsent: Date;
    consentStatus?: ConsentStatus;
    isImplicit?: boolean;
}

const getActiveOrExpiredConsentText = (t: i18next.TFunction, consentStatus?: ConsentStatus) => {

    if (consentStatus === ConsentStatus.Expired || consentStatus === ConsentStatus.Revoked) {
        return `${t('Your current shared access expired on ')}`;
    }

    return `${t('Your current shared access will expire on ')}`;
};

const getSharingInformation = (t: i18next.TFunction, oldConsent? : Date) => {
    
    if(!oldConsent){
        return `${t('In order to continue using shared data, you will need to reconnect your account.')}`;
    }

    return `${t(
        'In order to keep your data safe, we have to periodically check to make sure you still want to share your information.'
    )}`;
};

const getTitle = (t: i18next.TFunction, bankName: string, isImplicit: boolean | undefined) => {
    if(isImplicit){
        return `${t('Connect your {{bankName}} account', { bankName: bankName })}`;
    }

    return `${t(
        'Consent to share financial information'
    )}`;
};

const ReauthenticateConsentPageContent: React.FC<IProps> = (props: IProps) => {
    const { t, bankName, bankLogoUrl, oldConsent, consentStatus, isImplicit } = props;
    const sharingInformation = getSharingInformation(t, oldConsent);
    const activeOrExpiredConsentText = getActiveOrExpiredConsentText(t, consentStatus);
    const title = getTitle(t, bankName, isImplicit);

    useEffect(() => {
        if (window.hj) {
            window.hj(
                'tagRecording',
                ['Screen: Reauthentication Consent']
            );
        }
    }, [])

    return (
        <>
            {bankLogoUrl ? (
                <div className={styles.introduction}>
                    <h1 className={styles.title} data-cy="consent_page_title">{title}</h1>
                    <p className={styles.paragraph}>{sharingInformation}</p>
                    <div className={styles.imageWrapper}>
                        <ConditionalImage url={bankLogoUrl} alt={bankName} thresholdHeight={25} thresholdWidth={44} />
                    </div>
                </div>
            ) : (
                <>
                    <h1>{title}</h1>
                    <p>{sharingInformation}</p>
                </>
            )}
            <p className={styles.paragraph}>{t('We’ll aim to make this as quick and easy as possible.')}</p>
            { !oldConsent || (
            <p className={styles.paragraph}>
                {activeOrExpiredConsentText}
                <strong>
                    {oldConsent.toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                    })}
                </strong>
            </p>
            )}
        </>
    );
};

export default ReauthenticateConsentPageContent;
