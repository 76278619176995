import { ErrorAction } from './../actions/ApplicationAction';
import { SagaIterator } from '@redux-saga/core';
import { put } from '@redux-saga/core/effects';
import { push, RouterAction } from 'react-router-redux';
import LoaderAction from '../actions/LoaderAction';
import ActionTypes from '../actions/ActionTypes';

export default function* error(_: ErrorAction): SagaIterator {
    yield put<RouterAction>(push('/error'));
    yield put<LoaderAction>({
        type: ActionTypes.LOADER_EVENT,
        payload: null,
    });
}
