import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import InitReauthenticateStageSuccessAction from '../actions/InitReauthenticateStageSuccessAction';
import IConsentProps from '../../types/IConsentProps';

type NullableIConsentProps = IConsentProps | null;

const reauthenticateConsentReducer: Reducer<NullableIConsentProps, InitReauthenticateStageSuccessAction> = (
    state: NullableIConsentProps = null,
    action: InitReauthenticateStageSuccessAction
): NullableIConsentProps => {
    if (action.type !== ActionTypes.INIT_REAUTHENTICATE_STAGE_SUCCESS) {
        return state;
    }

    return {
        daysOfHistoricalTransactions: action.payload.daysOfHistoricalTransactions,
        permissions: action.payload.permissions,
        consentStatus: action.payload.consentStatus,
        consentEnd: action.payload.consentEnd,
    };
};

export default reauthenticateConsentReducer;
