import React, { RefObject } from 'react'
import { ReCAPTCHAProps } from 'react-google-recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import RecaptchaUpdateAction from '../../redux/actions/RecaptchaUpdateAction';
import ActionTypes from '../../redux/actions/ActionTypes';
import RecaptchaErrorAction from '../../redux/actions/RecaptchaErrorAction';
import { IApplicationState } from '../../types/applicationState';

interface IDispatchToProps {
    onChange: (value: string | null) => void,
    onErrored: () => void,
};

interface IStateToProps {
    enabled:boolean
  };

type ICAPTCHAPProps = ReCAPTCHAProps & {
    reference: RefObject<ReCAPTCHA>
}

interface IProps extends ICAPTCHAPProps, IStateToProps { };

class Recaptcha extends React.Component<IProps> {
    render() {
        const {reference,enabled, ...props} = this.props;

        if (enabled) {
            return <ReCAPTCHA {...props} ref={reference} />;
        }
        return null;
    }
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    enabled: !(state.application.useValidationToken)
  });


const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChange: (value: string | null) => dispatch<RecaptchaUpdateAction>({
        type: ActionTypes.RECAPTCHA_UPDATE,
        payload: value,
    }),
    onErrored: () => dispatch<RecaptchaErrorAction>({
        type: ActionTypes.RECAPTCHA_ERROR
    }),
});

export default connect<IStateToProps, IDispatchToProps, any, IApplicationState>(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
})(Recaptcha);
export {
    Recaptcha
};