import { IBankProps } from "../types/IBankProps";
import { AccountType } from "../types/AccountType";


export default function getProviderSelection(bank: IBankProps) {
 
    let personalProviders = bank.providers.filter(p => p.supportedAccounts.Personal && p.supportedAccounts.Personal.length > 0).map(provider => ({...provider}));
    let businessProviders = bank.providers.filter(p => p.supportedAccounts.Business && p.supportedAccounts.Business.length > 0).map(provider => ({...provider}));

    return{
        selectedAccountType: personalProviders.length >0 ? AccountType.Personal: AccountType.Business,
        currentProviders: personalProviders.length >0 ? personalProviders: businessProviders,
        personalProviders: personalProviders,
        businessProviders: businessProviders
    }
};