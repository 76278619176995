import React from 'react';
import { IBankProps } from '../../types/IBankProps';
import styles from './bankTile.module.scss';
import ScaledImage from '../app/ScaledImage';


interface IProps {
    className?: string,
    data: IBankProps,
    onClick?:  (event: React.MouseEvent<HTMLDivElement>) => void,
}

const BankTile: React.FC<IProps> = (props) => {
    return (<div title={props.data.name} tabIndex={0} className={styles.bankTile} onClick={props.onClick}>
        { props.data.logoUrl ?
            <ScaledImage scaleWidth={172} scaleHeight={96} url={props.data.logoUrl} alt={props.data.name} />
             :
             <span className={props.data.name.length > 40 ? styles.longText : styles.shortText}>{props.data.name}</span>
        }
        </div>
        );
};

export default BankTile;