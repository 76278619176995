import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import { IBankProps } from '../../types/IBankProps';

const bankSelectedReducer: Reducer<IBankProps | null> = (
    state: IBankProps | null = null,
    action
): IBankProps | null => {
    if (action.type !== ActionTypes.UPDATE_BANK_SELECTION && action.type !== ActionTypes.RESET_SELECTION_ACTION) {
        return state;
    }

    if (!action.payload || !action.payload.bank) {
        return null;
    }

    const bank: IBankProps = action.payload.bank;

    return {
        ...bank,
        providers: bank.providers.map((provider) => ({ ...provider })),
    };
};

export default bankSelectedReducer;
