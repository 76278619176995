import AccountNumberValidator from '../../utils/validators/accountNumberValidator';
import BankCodeValidator from '../../utils/validators/bankCodeValidator';

import ActionTypes from '../actions/ActionTypes';
import { ErrorAction } from '../actions/ApplicationAction';
import { put } from '@redux-saga/core/effects';
import { ValidateBankDataAction, ValidateBankDataCompletedAction } from '../actions/ValidateBankDataAction';

export default function* handlePaymentAccountConfirmationValidation(action: ValidateBankDataAction) {

    try {
            for(var i=0; i<action.payload.accounts.length; i++){
                let isValidAccountNumber = AccountNumberValidator
                    .bankDataIsValid(action.payload.accounts[i].accountNumber);
                
                let isValidBankCode = BankCodeValidator
                    .bankDataIsValid(action.payload.accounts[i].bankCode, action.payload.countryCode);


                action.payload.accounts[i].accountNumberFormFieldValue = {
                    isValid: isValidAccountNumber,
                    userValue: null,
                    validationError: null
                };
                action.payload.accounts[i].bankCodeFormFieldValue = {
                    isValid: isValidBankCode,
                    userValue: null,
                    validationError: null
                };

                action.payload.accounts[i].accountNumberIsRequired  = !isValidAccountNumber;
                action.payload.accounts[i].bankCodeIsRequired = !isValidBankCode;
            }

            yield put<ValidateBankDataCompletedAction>({
                type: ActionTypes.VALIDATE_BANK_DATA_COMPLETED,
                payload: action.payload,
            });
    } catch (error) {
        yield put<ErrorAction>({
            type: ActionTypes.VALIDATION_ERROR,
            payload: error.message,
        });
    }
}