import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from './resources/i18n';

function getLocale() {
    if (navigator.languages && navigator.languages.length !== 0) {
        return navigator.languages[0];
    }

    return navigator.language || 'en';
}

const lang = getLocale();

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: translations,
        lng: lang,
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        ns: ['translations'],
        nsSeparator: '__',
        defaultNS: 'translations',
    });

  export default i18n;