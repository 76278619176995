import { useTranslation } from 'react-i18next';
import IUserFeedbackFields from './IUserFeedbackFields'
import PaymentConfirmationValidationErrorEnum from '../../../types/enums/PaymentConfirmationValidationErrorEnum';

const AccountNumberUserFeedbackFields = (countryCode: string, type: PaymentConfirmationValidationErrorEnum | null): IUserFeedbackFields => {
    const { t } = useTranslation();

    const doesNotMatchBank: Map<string, string> = new Map([
        ['US', t("Please make sure you have entered a valid account number matching the one selected above")],
        ['CA', t("Please make sure you have entered a valid account number matching the one selected above")]]);
    const doesNotMatchBankDefault = t("Please make sure you have entered a valid account number matching the one selected above")

    const formatInvalid: Map<string, string> = new Map([
        ['US', t("Account number must be a valid 4 to 17-digit number")],
        ['CA', t('Account number must be a valid 4 to 17-digit number')]]);
    const formatInvalidDefault = t('Account number must be a valid 4 to 17-digit number')

    const placeHolder: Map<string, string> = new Map([
        ['US', t("Please confirm your account number")],
        ['CA', t("Please confirm your account number")]]);
    const placeHolderDefault = t("Please confirm your account number")

    const label: Map<string, string> = new Map([
        ['US', t("Account number")],
        ['CA', t("Account number")]]);
    const labelDefault = t("Account number")

    const getFeedbackError = (countryCode: string, type: PaymentConfirmationValidationErrorEnum | null): string | undefined => {

        if (type === null) {
            return undefined;
        }      

        switch (type) {
            case PaymentConfirmationValidationErrorEnum.ACCOUNT_NUMBER_DOES_NOT_MATCH_BANK:
                return doesNotMatchBank.has(countryCode) ? doesNotMatchBank.get(countryCode) : doesNotMatchBankDefault;
            case PaymentConfirmationValidationErrorEnum.ACCOUNT_NUMBER_FORMAT_INVALID:
            default:
                return formatInvalid.has(countryCode) ? formatInvalid.get(countryCode) : formatInvalidDefault;
        }
    };

    const getPlaceHolder = (countryCode: string): string => {
        return placeHolder.has(countryCode) ? placeHolder.get(countryCode)! : placeHolderDefault;
    };

    const getLabel = (countryCode: string): string => {
        return label.has(countryCode) ? label.get(countryCode)! : labelDefault;
    };

    return {
        label: getLabel(countryCode),
        placeHolder: getPlaceHolder(countryCode),
        validationError: getFeedbackError(countryCode, type)
    };
}

export default AccountNumberUserFeedbackFields;