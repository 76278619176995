import qs from 'qs';
import uuid from 'uuid/v4';
import { isNullOrUndefined } from 'util';

export default function getCorrelationId() {
    var queryParameters = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
        decoder: decodeURIComponent
    });

    let correlation_id = uuid();
    if (!isNullOrUndefined(queryParameters.correlation_id)) {
        correlation_id = sanitizedCorrelationId(queryParameters, correlation_id);
    }
    return correlation_id;
};

function sanitizedCorrelationId(queryParameters: any, correlation_id: string) { 
    let corrId: string = queryParameters.correlation_id;
    if (!isNullOrUndefined(corrId)) {
        if (corrId.includes('|')) {
            corrId = corrId.replace('|', '');
        }
        correlation_id = corrId.split('.')[0];
    }
    return correlation_id;
}
