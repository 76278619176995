import { Reducer } from 'redux';
import {GetSettingsSuccessAction} from "../actions/GetSettings";
import ActionTypes from '../actions/ActionTypes';
import {ISettingsDto} from "../../domain/dto/ISettingsDto";

type NullableITermsProps = ISettingsDto | null;

const getSettingsReducer: Reducer<NullableITermsProps, GetSettingsSuccessAction> = (state: NullableITermsProps = null, action: GetSettingsSuccessAction): NullableITermsProps => {
    if (action.type !== ActionTypes.GET_SETTINGS_SUCCESS) {
        return state;
    }

    return {
        ...action.payload
    };
};

export default getSettingsReducer;
