import { SagaIterator } from '@redux-saga/core';
import { all, takeEvery, takeLatest, throttle } from '@redux-saga/core/effects';
import ActionTypes from '../actions/ActionTypes';
import { initStateFromQueryString } from './initState';
import { initAccessTokenFromFragment } from './initAccessToken';
import { initCallbackTokenScopeFromFragment } from './initCallbackTokenScope';
import { initValidationTokenFromFragment } from './initValidationToken';
import handleBrandSelection from './brandChoice';
import handleProviderSelection from './providerChoice';
import goToPage from './goToPage';
import error from './error';
import getBanks from './getBanks';
import handleTermsSuccess from './initConsentScreen';
import handleCreateConsentSuccessInit from './createConsentButtonRedirection';
import { completeConnection } from './completeConnection';
import { createConsentConnectionRedirection } from './createConsentConnectionRedirection';
import handleGoBack from './goBack';
import handleConsentAccepted from './consentAccepted';
import handleRecaptchaUpdate from './recaptchaUpdate';
import handleValidationTokenUpdate from './validationTokenUpdate';
import handleRecaptchaError from './recaptchaError';
import handleProviderPreSelection from './providerPreSelect';
import handleBankCodePreSelection from './bankCodePreSelect';
import getCountryCodes from './getCountryCodes';
import initialiseApplication from './initApplication';
import handleInitStateSuccess from './initStateSuccess';
import initBankSearch from './initBankSearch';
import initFinalStage from './initFinalStage';
import getConnectionSummary from './getConnectionSummary';
import paymentAccountConfirmationSummary from './paymentAccountConfirmationSummary';
import handlePaymentAccountConfirmationValidation from './validatePaymentAccountConfirmation';
import reauthenticateConsentConnectionRedirection from './reauthenticateConsentConnectionRedirection';
import {
    handleAccountNumberFormFieldValidation,
    handleBankCodeFormFieldValidation,
} from './validatePaymentAccountConfirmationFormField';
import handleSavePaymentAccountConfirmationSummary from './savePaymentAccountConfirmationSummary';
import handleReauthenticate from './reauthenticate';
import getServiceUrls from './getServiceUrls';
import getSettings from "./getSettings";
import { exitApplication } from './exitApplication';
import handleClearConsentIdInit from './clearConsentId';

export default function* rootSaga(): SagaIterator {
    yield all([
        takeLatest(ActionTypes.INIT_APPLICATION, initialiseApplication),
        takeLatest(ActionTypes.INIT_STATE, initStateFromQueryString),
        takeLatest(ActionTypes.INIT_ACCESS_TOKEN, initAccessTokenFromFragment),
        takeLatest(ActionTypes.INIT_CALLBACK_TOKEN_SCOPE, initCallbackTokenScopeFromFragment),
        takeLatest(ActionTypes.INIT_VALIDATION_TOKEN, initValidationTokenFromFragment),
        takeLatest(ActionTypes.INIT_STATE_SUCCESS, handleInitStateSuccess),
        takeLatest(ActionTypes.INIT_BANK_SEARCH_STAGE, initBankSearch),
        takeLatest(ActionTypes.INIT_FINAL_STAGE, initFinalStage),
        takeLatest(ActionTypes.GET_DISCOVERY_URLS, getServiceUrls),
        takeLatest(ActionTypes.GET_CONNECTION_SUMMARY, getConnectionSummary),
        takeLatest(ActionTypes.GET_SETTINGS, getSettings),
        takeLatest(ActionTypes.GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY, paymentAccountConfirmationSummary),
        takeLatest(ActionTypes.VALIDATE_ACCOUNT_NUMBER, handleAccountNumberFormFieldValidation),
        takeLatest(ActionTypes.VALIDATE_BANK_CODE, handleBankCodeFormFieldValidation),
        takeLatest(ActionTypes.VALIDATE_BANK_DATA, handlePaymentAccountConfirmationValidation),
        takeLatest(
            ActionTypes.COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY,
            handleSavePaymentAccountConfirmationSummary
        ),

        takeEvery(ActionTypes.INIT_STATE_ERROR, error),
        takeEvery(ActionTypes.PROVIDER_PRESELECTION_ERROR, initBankSearch),
        takeEvery(ActionTypes.BANK_CODE_PRESELECTION_ERROR, initBankSearch),
        takeEvery(ActionTypes.GET_COUNTRY_CODES_ERROR, error),
        takeEvery(ActionTypes.INIT_BANK_SEARCH_STAGE_ERROR, error),
        takeEvery(ActionTypes.GET_CONNECTION_SUMMARY_ERROR, error),
        takeEvery(ActionTypes.GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_ERROR, error),
        takeEvery(ActionTypes.VALIDATION_ERROR, error),
        takeEvery(ActionTypes.CREATE_CONSENT_ID_ERROR, error),
        takeEvery(ActionTypes.CONSENT_ACCEPTED_ERROR, error),
        takeEvery(ActionTypes.CREATE_CONSENT_ID_CONNECTION_ERROR, error),
        takeEvery(ActionTypes.REDIRECTION_ERROR, error),
        takeEvery(ActionTypes.COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_ERROR, error),
        takeEvery(ActionTypes.COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_CANCELLED_ERROR, error),
        takeEvery(ActionTypes.GET_SETTINGS_ERROR, error),

        takeLatest(ActionTypes.GET_COUNTRY_CODES, getCountryCodes),
        takeLatest(ActionTypes.COMPLETE_BANK_SELECTION, handleBrandSelection),
        takeLatest(ActionTypes.PROVIDER_PRESELECTION, handleProviderPreSelection),
        takeLatest(ActionTypes.BANK_CODE_PRESELECTION, handleBankCodePreSelection),
        takeLatest(ActionTypes.COMPLETE_PROVIDER_SELECTION, handleProviderSelection),
        takeLatest(ActionTypes.GO_TO_PAGE, goToPage),
        throttle(250, ActionTypes.GET_BANKS, getBanks),
        throttle(250, ActionTypes.SELECT_COUNTRY_CODE, getBanks),
        takeLatest(ActionTypes.COMPLETE_CONNECTION, completeConnection),
        takeLatest(ActionTypes.GET_TERMS_SUCCESS, handleTermsSuccess),
        takeLatest(ActionTypes.CONSENT_ACCEPTED, handleConsentAccepted),
        takeLatest(ActionTypes.CREATE_CONSENT_SUCCESS_INIT, handleCreateConsentSuccessInit),
        takeLatest(ActionTypes.CREATE_CONSENT_ID_SUCCESS, createConsentConnectionRedirection),
        takeLatest(ActionTypes.GO_BACK, handleGoBack),
        takeLatest(ActionTypes.RECAPTCHA_ERROR, handleRecaptchaError),
        takeLatest(ActionTypes.RECAPTCHA_UPDATE, handleRecaptchaUpdate),
        takeLatest(ActionTypes.VALIDATION_TOKEN_UPDATE, handleValidationTokenUpdate),
        takeLatest(ActionTypes.INIT_REAUTHENTICATE_STAGE, handleReauthenticate),
        takeEvery(ActionTypes.INIT_REAUTHENTICATE_STAGE_ERROR, error),
        takeLatest(ActionTypes.REAUTHENTICATE_CONSENT_CONNECTION, reauthenticateConsentConnectionRedirection),
        takeEvery(ActionTypes.REAUTHENTICATE_CONSENT_CONNECTION_ERROR, error),        
        takeEvery(ActionTypes.GET_DISCOVERY_URLS_ERROR, error),
        takeLatest(ActionTypes.EXIT_APPLICATION, exitApplication),
        takeLatest(ActionTypes.CLEAR_CONSENT_ID_INIT, handleClearConsentIdInit),
    ]);
}
