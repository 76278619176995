import { SagaIterator } from '@redux-saga/core';
import ConsentConnectionService from '../../domain/services/ConsentConnectionService';
import { Container } from 'inversify';
import { getContext, put, apply, select } from '@redux-saga/core/effects';
import { CONTAINER } from '../../constants/di';
import IConsentConnectionService from '../../domain/services/IConsentConnectionService';
import ActionTypes from '../actions/ActionTypes';
import IConsentConnectionResponseDto from '../../domain/dto/IConsentConnectionResponseDto';
import ReauthenticateConsentConnectionAction from '../actions/ReauthenticateConsentConnectionAction';
import ReauthenticateConsentConnectionErrorAction from '../actions/ReauthenticateConsentConnectionErrorAction';
import ReauthenticateConsentConnectionSuccessAction from '../actions/ReauthenticateConsentConnectionSuccessAction';
import IAccessTokenStorage from '../../domain/services/IAccessTokenStorage';
import AccessTokenStorage from '../../domain/services/AccessTokenStorage';
import { ServiceUrls } from '../../types/ServiceUrls';
import getServiceUrls from '../selectors/getServiceUrls';
import GoToPageAction from '../actions/GoToPageAction';
import LoaderAction from '../../redux/actions/LoaderAction';

export default function* reauthenticateConsentConnectionRedirection(
    action: ReauthenticateConsentConnectionAction
): SagaIterator {
    const container: Container = yield getContext(CONTAINER);
    const consentConnectionService = container.get<IConsentConnectionService>(ConsentConnectionService);
    const accessTokenStorage: IAccessTokenStorage = container.get<IAccessTokenStorage>(AccessTokenStorage);
    const serviceUrls : ServiceUrls = yield select(getServiceUrls);

    try {
        if (!accessTokenStorage.hasToken()) {
            yield put<ReauthenticateConsentConnectionErrorAction>({
                type: ActionTypes.REAUTHENTICATE_CONSENT_CONNECTION_ERROR,
                payload: new Error('no access token could be found.'),
            });
            return;
        }

        const consentId: string = action.payload.consentId;
        const response: IConsentConnectionResponseDto = yield apply(
            consentConnectionService,
            consentConnectionService.reauthenticateConsentConnection,
            [serviceUrls.gatewayService, consentId, accessTokenStorage.getToken()!]
        );

        if (response.redirectUrl === null) {
            throw Error();
        }

        yield put<ReauthenticateConsentConnectionSuccessAction>({
            type: ActionTypes.REAUTHENTICATE_CONSENT_CONNECTION_SUCCESS,
            payload: {
                redirectionUrl: response.redirectUrl,
            },
        });

        yield put<LoaderAction>({
            type: ActionTypes.LOADER_EVENT,
            payload: null,
        });

        yield put<GoToPageAction>({
            type: ActionTypes.GO_TO_PAGE,
            payload: {
                url: '/consent',
            },
        });
    } catch (error) {
        yield put<ReauthenticateConsentConnectionErrorAction>({
            type: ActionTypes.REAUTHENTICATE_CONSENT_CONNECTION_ERROR,
            payload: error,
        });
    }
}
