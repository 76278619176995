import React from 'react';
import ProviderTile from './../../components/providerSelection/ProviderTile';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import CompleteProviderChoiceAction from '../../redux/actions/CompleteProviderChoiceAction';
import { connect } from 'react-redux';
import { IProviderProps } from '../../types/IProviderProps';
import { AccountType } from '../../types/AccountType';

interface IBasicProps {};

interface IDispatchToProps {
    onClick: (provider: IProviderProps) => void,
};

interface IStateToProps {
    accountType: AccountType,
    providers: IProviderProps[]
};

interface IProps extends IBasicProps, IStateToProps, IDispatchToProps {};

const ProviderTilesGroup: React.FC<IProps> = (props: IProps) => {
    const renderedTiles = props.providers.map((provider: IProviderProps) => (
        <ProviderTile key={provider.providerId} name={getDisplayName(provider, props.accountType)} onClick={props.onClick.bind(null, provider)} />
    ));

    return <>{renderedTiles}</>;
};

const getDisplayName = function (provider: IProviderProps, accountType: AccountType){
    if(provider.hasDisplayName)
        return provider.name;

    if(accountType === AccountType.Business)
        return provider.supportedAccounts.Business.join(", ");
    
    return provider.supportedAccounts.Personal.join(", ");
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    accountType: state.providerSelection.selectedAccountType,
    providers: state.providerSelection.currentProviders
});

const mapDispatchToProps = (dispatch: Dispatch<CompleteProviderChoiceAction>): IDispatchToProps => ({
    onClick: (provider: IProviderProps) => dispatch<CompleteProviderChoiceAction>({
        type: ActionTypes.COMPLETE_PROVIDER_SELECTION,
        payload: {providerId: provider.providerId}
    }),
});

export {
    ProviderTilesGroup
};
export default connect<IStateToProps, IDispatchToProps, IBasicProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(ProviderTilesGroup);