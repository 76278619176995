import { SagaIterator } from '@redux-saga/core';
import { getContext, apply, put, all, select } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import { CONTAINER } from '../../constants/di';
import InitReauthenticateStageAction from '../actions/ReauthenticateAction';
import InitReauthenticateStageErrorAction from '../actions/InitReauthenticateStageErrorAction';
import InitReauthenticateStageSuccessAction from '../actions/InitReauthenticateStageSuccessAction';
import ReauthenticateConsentConnectionErrorAction from '../actions/ReauthenticateConsentConnectionErrorAction';
import IProviderService from '../../domain/services/IProviderService';
import ProviderService from '../../domain/services/ProviderService';
import IConsentService from '../../domain/services/IConsentService';
import ConsentService from '../../domain/services/ConsentService';
import IConsentDto from '../../domain/dto/IConsentDto';
import CompleteProviderChoiceAction from '../actions/CompleteProviderChoiceAction';
import ActionTypes from '../actions/ActionTypes';
import UpdateBankChoiceAction from '../actions/UpdateBankChoiceAction';
import { IBankDto } from '../../domain/dto/IBankDto';
import { SimpleApplicationAction } from '../actions/ApplicationAction';
import IAccessTokenStorage from '../../domain/services/IAccessTokenStorage';
import AccessTokenStorage from '../../domain/services/AccessTokenStorage';
import getServiceUrls from '../selectors/getServiceUrls';
import { ServiceUrls } from '../../types/ServiceUrls';

export default function* handleReauthenticate(action: InitReauthenticateStageAction): SagaIterator {
    const container: Container = yield getContext(CONTAINER);
    const providerService = container.get<IProviderService>(ProviderService);
    const consentService = container.get<IConsentService>(ConsentService);
    const accessTokenStorage: IAccessTokenStorage = container.get<IAccessTokenStorage>(AccessTokenStorage);
    const serviceUrls : ServiceUrls = yield select(getServiceUrls);
    
    try {
        if (!accessTokenStorage.hasToken()) {
            yield put<InitReauthenticateStageErrorAction>({
                type: ActionTypes.INIT_REAUTHENTICATE_STAGE_ERROR,
                payload: new Error('No access token could be found.'),
            });
            return;
        }

        const consent: IConsentDto = yield apply(consentService, consentService.getConsent, [
            serviceUrls.consentService,
            action.payload.consentId,
            accessTokenStorage.getToken()!,
        ]);

        const bank: IBankDto = yield apply(providerService, providerService.getBank, [serviceUrls.providerSearchService, consent.providerId]);

        if (!bank.name) {
            throw Error();
        }

        let existingConsentEndDate: Date | undefined = undefined;

        var selectedProvider = bank.providers.find(function (element) {
            return element.providerId === consent.providerId;
        });

        if (selectedProvider?.maxConsentDays) {
            existingConsentEndDate = new Date(consent.consentStart);
            existingConsentEndDate.setUTCDate(existingConsentEndDate.getUTCDate() + selectedProvider.maxConsentDays);
        }
       
        yield all([
            put<UpdateBankChoiceAction>({
                type: ActionTypes.UPDATE_BANK_SELECTION,
                payload: {
                    bank: {
                        ...bank,
                        providers: bank.providers.map((provider) => ({
                            providerId: provider.providerId,
                            name: provider.name,
                            dataSource: provider.dataSource,
                            maxConsentDays: provider.maxConsentDays,
                            hasDisplayName: false,
                            supportedAccounts: { Business: [], Personal: [] },
                        })),
                    },
                },
            }),
            put<InitReauthenticateStageSuccessAction>({
                type: ActionTypes.INIT_REAUTHENTICATE_STAGE_SUCCESS,
                payload: {
                    consentEnd: existingConsentEndDate,
                    daysOfHistoricalTransactions: consent.daysOfHistoricalTransactions,
                    permissions: consent.permissions,
                    clientId: consent.applicationId,
                    customerReference: consent.customerReference,
                    configurationId: consent.configurationName,
                    consentStatus: consent.consentStatus,
                },
            }),
            put<CompleteProviderChoiceAction>({
                type: ActionTypes.COMPLETE_PROVIDER_SELECTION,
                payload: {
                    providerId: consent.providerId,
                },
            }),
        ]);

        yield put<SimpleApplicationAction>({
            type: ActionTypes.PROVIDER_PRESELECTION_SUCCESS,
        });
    } catch (error) {
        yield put<ReauthenticateConsentConnectionErrorAction>({
            type: ActionTypes.REAUTHENTICATE_CONSENT_CONNECTION_ERROR,
            payload: error,
        });
    }
}
