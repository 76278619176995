import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import LinkButton from '../buttons/LinkButton';
import Link from '../links/Link';

interface IProps {
    clearSearch: () => void,
    supportLink: string,
    supportLinkTarget?: string,
    supportEmailSubject?: string,
}

const SearchErrorMessage: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { clearSearch, supportLink, supportLinkTarget, supportEmailSubject } = props;

    const target = supportLinkTarget 
        ? supportLinkTarget
        : '';

    const subject = supportEmailSubject
        ? '?subject=' + supportEmailSubject
        : '';

    return (<div>
        <p>
            {t('Sorry, something went wrong on our side.  Try typing your search again.')}
        </p>
        <p>
            <Trans>If the problem persists, <Link href={supportLink + subject} target={target}>let us know</Link> or select from one of our most <LinkButton onClick={clearSearch}>popular banks</LinkButton></Trans>
        </p>
    </div>);
};

export default SearchErrorMessage;