export const ProviderServiceEndpoints = {
    getFavouriteBanks: (url: string) => {
        return `${url}/favourites`;
    },
    getBank: (url: string, providerId: number) => {
        return `${url}/provider/${providerId}`;
    },
    getBanks: (url: string, bankCode: string) => {
        return `${url}/search/bank-code/${bankCode}`;
    },
    searchBanks: (url: string) =>{
        return `${url}/search`;
    },
    countryCodes: (url: string)=> {
        return `${url}/countries`;
    }
};

export const GatewayServiceEndpoints = {
    createConsentConnection: (url: string, consentId: string) => {
        return `${url}/consents/${consentId}/connection`;
    },
    reauthenticateConsentConnection: (url: string, consentId: string) => {
        return `${url}/consents/${consentId}/connection/reauthenticate`;
    },
    connectionSummary: (url: string) => {
        return `${url}/consent/connection/summary`;
    },
    paymentAccountConfirmation: (url: string) => {
        return  `${url}/consent/connection/payment-confirmation`;
    }
};

export const ConsentServiceEndpoints = {
    consentBaseUrl: (url: string) => {
        return  url;
    },
    postConsent: (url: string) => {
        return `${url}`;
    },
    getConsent: (url: string, consentId: string) => {
        return `${url}/${consentId}`;
    }
};

export const InsightsApiServiceEndpoints = {
    insightsApiBaseUrl: (url: string) => { 
        return url;
    },
    savePaymentConfirmationData: (url: string) => {
        return `${url}/stored-data/v1/payment-account-confirmation/`;
    }
};


export const ConfigurationServiceEndpoints = {
    configurationBaseUrl: application.env.CONFIGURATION_API_BASE_URL,
    getTerms: `${application.env.CONFIGURATION_API_BASE_URL}/customer-configuration/{name}/terms`,
    getDiscoveryUrls: (appId: string) => { 
        return `${application.env.CONFIGURATION_API_BASE_URL}/application/${appId}/discovery`;
    },
    getSettings: `${application.env.CONFIGURATION_API_BASE_URL}/customer-configuration/{name}/connect-settings`,
};