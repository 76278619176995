import React from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import { IBankProps } from '../../types/IBankProps';
import BankTilesGrid from '../../components/bankSelect/BankTilesGrid';
import BankSearchList from '../../components/bankSelect/BankSearchList';
import { CompleteBankChoiceAction, GetBanksAction } from '../../redux/actions/BankSelectAction';
import { ICompleteBankChoiceActionPayload } from '../../types/ICompleteBankChoiceActionPayload';
import { SimpleApplicationAction } from '../../redux/actions/ApplicationAction';
import SearchErrorMessage from '../../components/bankSelect/SearchErrorMessage';
import moment from 'moment';

interface IDispatchToProps {
    onGetBanks: () => void,
    onBankSelected: (data: ICompleteBankChoiceActionPayload) => void,
    onShowAll: () => void,
    onClearSearch: () => void,
};

interface IStateToProps {
    banks: {
        loading: Boolean,
        query: string,
        data: IBankProps[],
        showAll: Boolean,
        hasError: Boolean,
    },
    selectedCountryCode: string,
};

interface IProps extends IStateToProps, IDispatchToProps { };

class BankResultsContainer extends React.Component<IProps> {
    componentDidMount() {
        this.props.onGetBanks();
    }

    render() {
        const { banks, selectedCountryCode, onBankSelected, onShowAll, onClearSearch } = this.props;
        
        if(this.props.banks.hasError) {
            const supportEmailSubject = 'DiDConnect Support ' +  moment().toISOString();

            return <SearchErrorMessage clearSearch={onClearSearch} supportLink={'mailto:support@directid.co'} supportLinkTarget={'_blank'} supportEmailSubject={supportEmailSubject} />
        }

        return this.props.banks.query ? (
          <BankSearchList
            selectBank={onBankSelected}
            onShowAll={onShowAll}
            selectedCountryCode={selectedCountryCode}
            {...banks}
          />
        ) : (
          <BankTilesGrid selectBank={onBankSelected} banks={banks} />
        );
    };
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    banks: state.banks,
    selectedCountryCode: state.selectedCountry,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onBankSelected: (data: ICompleteBankChoiceActionPayload) => dispatch<CompleteBankChoiceAction>({
        type: ActionTypes.COMPLETE_BANK_SELECTION,
        payload: data,
    }),
    onGetBanks: () => dispatch<GetBanksAction>({
        type: ActionTypes.GET_BANKS,
        payload: {},
    }),
    onShowAll: () => dispatch<SimpleApplicationAction>({
        type: ActionTypes.SHOW_ALL_BANKS,
    }),
    onClearSearch: () => dispatch<GetBanksAction>({
        type: ActionTypes.GET_BANKS,
        payload: { query: ""},
    })
});

export { BankResultsContainer };
export default connect<IStateToProps, IDispatchToProps, any, IApplicationState>(mapStateToProps, mapDispatchToProps)(BankResultsContainer);