import { Middleware, MiddlewareAPI, Dispatch, Action } from "redux";
import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';
import ActionTypes from '../actions/ActionTypes';
import IObfuscatedLoggedEvent from '../middlewares/IObfuscatedLoggedEvent';

interface ApplicationInsightsOptions {
    insights: ApplicationInsights,
    loggedEvents: (ActionTypes | IObfuscatedLoggedEvent)[],
    logPayload: boolean,
    eventNamePrefix?: string,
    globals?: {
        [key: string]: any
    },
};

type ActionWithOptionalPayload = Action & {
    payload?: any,
}

export const ObfuscatedDataValue = "obfuscatedData";

function canTrack(insights: ApplicationInsights) {
    return insights.config.instrumentationKey !== '';
}

function isIObfuscatedLoggedEvent(event: IObfuscatedLoggedEvent | ActionTypes | undefined): event is IObfuscatedLoggedEvent {
    return event !== undefined && (event as IObfuscatedLoggedEvent).event !== undefined;
}

function isActionTypesLoggedEvent(event: IObfuscatedLoggedEvent | ActionTypes): event is ActionTypes {
    return (event as ActionTypes).includes !== undefined;
}

function shouldTrack(action: Action, events: (ActionTypes | IObfuscatedLoggedEvent)[] | "*"): boolean {
    if (events === '*') {
        return true;
    }

    for (let key in events) {
        const event = events[key];
        if (isIObfuscatedLoggedEvent(event)) {
            if ((event as IObfuscatedLoggedEvent).event.includes(action.type)) {
                return true;
            }
        }
        else if (isActionTypesLoggedEvent(event) && event.includes(action.type)) {
            return true;
        }
    }
    return false;
};


function getObfuscatedEvent(action: Action, events: (ActionTypes | IObfuscatedLoggedEvent)[] | "*"): IObfuscatedLoggedEvent | undefined {
    if (events === '*') {
        return undefined;
    }

    for (let key in events) {
        var event = events[key];
        if (isIObfuscatedLoggedEvent(event)) {
            if ((event as IObfuscatedLoggedEvent).event.includes(action.type)) {
                return event;
            }
        }
    }
    return undefined;
};


function buildEvent(
    action: ActionWithOptionalPayload,
    logPayload: boolean,
    eventNamePrefix?: string,
    globals?: { [key: string]: any },
    obfuscatedLoggedEvent?: IObfuscatedLoggedEvent): IEventTelemetry {
    const actionName = eventNamePrefix ?
        `${eventNamePrefix || ''} ${action.type}` :
        action.type;

    const event: IEventTelemetry = {
        name: actionName,
    };

    if (logPayload && action.payload) {
        event.properties = {
            data: obfuscatedData(action.payload, obfuscatedLoggedEvent)
        };
    }

    if (globals) {
        event.properties = {
            ...event.properties,
            globals: globals
        };
    }

    return event;
};

const obfuscatedData: any = (data: any, obfuscatedLoggedEvent?: IObfuscatedLoggedEvent) => {
    if (obfuscatedLoggedEvent === undefined)
        return data;
        
    let obfuscatedData = { ...data };
    for (let prop in data) {
        for (let key in obfuscatedLoggedEvent.obfuscated) {
            if (prop.includes(obfuscatedLoggedEvent.obfuscated[key])) {
                obfuscatedData[prop] = ObfuscatedDataValue
            }
        }
    }
    return obfuscatedData;
};

function applicationInsights({
    loggedEvents,
    logPayload,
    insights,
    globals,
    eventNamePrefix,
}: ApplicationInsightsOptions) {
    const loggerMiddleware: Middleware =
        (_: MiddlewareAPI) =>
            (next: Dispatch) =>
                (action: ActionWithOptionalPayload) => {
                    const returnValue = next(action);

                    if (canTrack(insights) && shouldTrack(action, loggedEvents)) {
                        const event = buildEvent(
                            action,
                            logPayload,
                            eventNamePrefix,
                            globals,
                            getObfuscatedEvent(action, loggedEvents));

                        insights.trackEvent(event);
                        insights.flush();
                    }
                    return returnValue;
                }
  
    return loggerMiddleware;
};

export default applicationInsights;