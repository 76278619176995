import React from 'react';
import Link from '../links/Link';
import style from './recaptchaDisclaimer.module.scss';
import { Trans } from 'react-i18next';

const RecaptchaDisclaimer: React.FC = () => {
    return (<div className={style.disclaimer}>
        <p>
            <Trans>
                This site is protected by reCAPTCHA and the Google <Link href={'https://policies.google.com/privacy'} target="_blank">Privacy Policy</Link> and <Link href={'https://policies.google.com/terms'} target="_blank">Terms of Service</Link> apply.
            </Trans>
        </p>
    </div>);
};

export default RecaptchaDisclaimer;