import { injectable, inject } from "inversify";
import { AxiosInstance } from 'axios';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import { GatewayServiceEndpoints } from '../../constants/apiRoutes';
import IConnectionSummaryDto from '../dto/IConnectionSummaryDto';
import IConnectionSummaryService from "./IConnectionSummaryService";

@injectable()
export default class ConnectionSummaryService implements IConnectionSummaryService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getConnectionSummary(
        url: string,
        token: string
    ) : Promise<IConnectionSummaryDto> {
        const response = await this.apiClient.get<IConnectionSummaryDto>(
            GatewayServiceEndpoints.connectionSummary(url),
            {
                headers: { 
                    'Authorization': `Bearer ${token}`,
                }
            },
        );

        return response.data;
    }
}