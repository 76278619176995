
import qs from 'qs';
import { injectable } from 'inversify';
import ICallbackTokenScopeInitializer from './ICallbackTokenScopeInitializer';

@injectable()
export default class CallbackTokenScopeInitializer implements ICallbackTokenScopeInitializer {
    public init(): string | null {
        var fragmentParameters = qs.parse(window.location.hash.slice(1), {
            decoder: decodeURIComponent
        });

        var callbackTokenScope: string | null= (
            (hash) => hash.scope ? String(hash.scope) : null
        )(fragmentParameters);

        return callbackTokenScope;
    }
};