import React, { createRef,useEffect } from 'react';
import IPaymentConfirmationAccountDataProps from '../../types/IPaymentConfirmationAccountDataProps';
import TextInputWithValidation from '../../components/paymentAccountConfirmationSummary/textInputWithValidation';
import IFormFieldValue from '../../types/IFormFieldValue';
import BankCodeUserFeedbackFields from './helpers/bankCodeUserFeedbackFields';
import AccountNumberUserFeedbackFields from './helpers/accountNumberUserFeedbackFields';

interface IProps {
  data: IPaymentConfirmationAccountDataProps
  onAccountNumberChange: (value:string, accountId:string ) => void;
  onBankCodeChange: (value: string, accountId: string) => void;
  countryCode: string;
};

const setPlaceHolder = (isRequired:boolean, placeHolder:string, overridePlaceHolder:string) => {
  return isRequired
    ? placeHolder
    : overridePlaceHolder;
}

const setValue = (isRequired: boolean, field: IFormFieldValue) => {
  const value = isRequired
    ? field.userValue!
    : "";

  return value;
}

const AccountInputValidation: React.FC<IProps> = (props: IProps) => {

  const { accountNumberFormFieldValue: accountNumberValue,
    accountNumberIsRequired,
    bankCodeFormFieldValue: bankCodeValue,
    bankCodeIsRequired,
    accountId,
    accountNumber,
    bankCode } = props.data;
  
  const bankCodeUserFeedback = BankCodeUserFeedbackFields(props.countryCode, bankCodeValue.validationError);
  const accountNumberUserFeedback = AccountNumberUserFeedbackFields(props.countryCode, accountNumberValue.validationError);
  const accountNumberFormRef = createRef<HTMLFormElement>();  
  const bankCodeFormRef = createRef<HTMLFormElement>();  


  useEffect(() => {
    bankCodeFormRef.current!.reset();
    if (accountNumberIsRequired) {
      accountNumberFormRef.current!.reset();
    }
  });

    return (
      <div>
        <TextInputWithValidation
          resetRef={accountNumberFormRef}
          label={accountNumberUserFeedback.label}
          placeholder={setPlaceHolder(accountNumberIsRequired, accountNumberUserFeedback.placeHolder, accountNumber!)}
          isRequired={accountNumberIsRequired}
          isValid={accountNumberValue.isValid}
          alwaysDisplay={false}
          validationMessage={accountNumberUserFeedback.validationError}
          value={setValue(accountNumberIsRequired, accountNumberValue)}
          onInputChange={(e: React.FormEvent<HTMLInputElement>) => {
            props.data.accountNumberFormFieldValue.userValue =
              e.currentTarget.value;
            props.onAccountNumberChange(e.currentTarget.value, accountId);
          }}
        />
        <TextInputWithValidation
          resetRef={bankCodeFormRef}
          label={bankCodeUserFeedback.label}
          placeholder={setPlaceHolder(bankCodeIsRequired, bankCodeUserFeedback.placeHolder, bankCode!)}
          isRequired={bankCodeIsRequired}
          alwaysDisplay={true}
          isValid={bankCodeValue.isValid}
          validationMessage={bankCodeUserFeedback.validationError}
          value={setValue(bankCodeIsRequired, bankCodeValue)}
          onInputChange={(e: React.FormEvent<HTMLInputElement>) => {
            props.data.bankCodeFormFieldValue.userValue = e.currentTarget.value;
            props.onBankCodeChange(e.currentTarget.value, accountId);
          }}
        />
      </div>
    );
};

export default AccountInputValidation;