import { apply, getContext, put, select } from '@redux-saga/core/effects';

import ActionTypes from '../actions/ActionTypes';
import ConsentService from '../../domain/services/ConsentService';
import { Container } from 'inversify';
import CreateConsentErrorAction from '../actions/CreateConsentErrorAction';
import CreateConsentSuccessAction from '../actions/CreateConsentSuccessAction';
import { IApplicationProps } from '../../types/IApplicationProps';
import IConsentService from '../../domain/services/IConsentService';
import ICreateConsentDto from '../../domain/dto/ICreateConsentDto';
import { IProviderProps } from '../../types/IProviderProps';
import { SagaIterator } from '@redux-saga/core';
import { ServiceUrls } from '../../types/ServiceUrls';
import ValidationTokenUpdateAction from '../actions/ValidationTokenUpdateAction';
import getApplicationState from '../selectors/getApplicationState';
import getProviderSelected from '../../redux/selectors/getProviderSelected';
import getSelectedCountryCode from '../selectors/getSelectedCountryCode';
import getServiceUrls from '../selectors/getServiceUrls';

export default function* handleValidationTokenUpdate(action: ValidationTokenUpdateAction): SagaIterator {
    if (action.payload === null) {
        yield put<CreateConsentErrorAction>({
            type: ActionTypes.CREATE_CONSENT_ID_ERROR,
            payload: new Error('Validation Token is not present'),
        });

        return;
    }

    const container: Container = yield getContext('container');
    const consentService: IConsentService = container.get<IConsentService>(ConsentService);
    const applicationState: IApplicationProps = yield select(getApplicationState);
    const selectedCountry: string = yield select(getSelectedCountryCode);
    const serviceUrls: ServiceUrls = yield select(getServiceUrls);

    try {
        const selectedProvider: IProviderProps = yield select(getProviderSelected);

        const consentCreationResponse: ICreateConsentDto = yield apply(consentService, consentService.createConsent, [
            serviceUrls.consentService,
            applicationState.clientId,
            applicationState.customerReference,
            action.payload.validationToken,
            selectedProvider.providerId,
            selectedProvider.name,
            selectedProvider.dataSource,
            selectedCountry,
            applicationState.configurationId,
            selectedProvider.maxConsentDays,
            applicationState.invitationId,
        ]);
        yield put<CreateConsentSuccessAction>({
            type: ActionTypes.CREATE_CONSENT_ID_SUCCESS,
            payload: {
                consentId: consentCreationResponse.consentId,
                providerId: selectedProvider.providerId,
            },
        } as CreateConsentSuccessAction);
    } catch (e) {
        yield put<CreateConsentErrorAction>({
            type: ActionTypes.CREATE_CONSENT_ID_ERROR,
            payload: e,
        });
    }
}
