import React from 'react';
import ILoaderProps from '../../types/ILoaderProps';
import Spinner from '../spinner/Spinner';
import style from './loader.module.scss';
import RecaptchaDisclaimer from '../recaptcha/RecaptchaDisclaimer';
import { useTranslation } from 'react-i18next';
import ConditionalImage from "../app/ConditionalImage";

const Loader: React.FC<ILoaderProps> = (props: ILoaderProps) => {
    const { t } = useTranslation();

    return (props.display ? <div className={style.loader}>
            <h1>{props.logoText}</h1>
            {props.logoUrl && <div className={props.framedLogo ? style.framedImage : style.image}>
                <ConditionalImage
                    url={props.logoUrl}
                    alt={props.logoText || ''}
                    thresholdHeight={25}
                    thresholdWidth={44}
                />
            </div>}
        <div>
            <Spinner/>
            <p className={style.fadeIn}>{t(props.spinnerText || '')}</p>
            {props.showRecaptchaDisclaimer && <div className={style.recaptcha}>
                <RecaptchaDisclaimer />
            </div>}
        </div>
    </div> : null);
};

export default Loader;
