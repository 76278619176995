import React from 'react';
import styles from './findYourBankButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ButtonLink from "../buttons/LinkButton";
import { useTranslation } from 'react-i18next';

interface IProps {
    className?: string,
    focusSearch: () => void,
}

const FindYourBankButton: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className={styles.findYourBank}>
            <ButtonLink onClick={props.focusSearch}>
                <FontAwesomeIcon icon={faSearch} />
                <span className={styles.findYourBankText}>{t('Find your bank')}</span>
            </ButtonLink>
        </div>
    );
};

export default FindYourBankButton;