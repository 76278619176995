import React from 'react';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import { AccountType } from '../../types/AccountType';
import { IProviderSelectionProps } from '../../types/IProviderSelectionProps';
import UpdateSelectedAccountTypeAction from '../../redux/actions/UpdateSelectedAccountTypeAction';
import { IApplicationState } from '../../types/applicationState';
import ToggleButton from '../../components/buttons/ToggleButton';
import ToggleButtonGroup from '../../components/buttons/ToggleButtonGroup';

interface IBasicProps {
};

interface IDispatchToProps {
    onClick: (accountType: AccountType) => void,
};

interface IStateToProps {
    providerSelection: IProviderSelectionProps
};

interface IProps extends IBasicProps, IStateToProps, IDispatchToProps {};

const AccountTypeTabs: React.FC<IProps> = (props: IProps) => {
    return (
    <ToggleButtonGroup>
        <ToggleButton 
            active = {props.providerSelection.selectedAccountType === AccountType.Personal}
            isDisabled ={props.providerSelection.personalProviders.length === 0} 
            onClick={props.onClick.bind(null, AccountType.Personal)}>
                {AccountType.Personal}
        </ToggleButton>
        <ToggleButton 
        active = {props.providerSelection.selectedAccountType === AccountType.Business}
            isDisabled ={props.providerSelection.businessProviders.length === 0} 
            onClick={props.onClick.bind(null, AccountType.Business)}>
                {AccountType.Business}
        </ToggleButton>
    </ToggleButtonGroup>);
};

const mapStateToProps = (state: IApplicationState) => ({
    providerSelection: state.providerSelection
});

const mapDispatchToProps = (dispatch: Dispatch<UpdateSelectedAccountTypeAction>): IDispatchToProps => ({
    onClick: (accountType: AccountType) => dispatch<UpdateSelectedAccountTypeAction>({
        type: ActionTypes.UPDATE_SELECTED_ACCOUNT_TYPE,
        payload: {accountType: accountType}
    }),
});

export {
    AccountTypeTabs
};
export default connect<IStateToProps, IDispatchToProps, IBasicProps, IApplicationState>(mapStateToProps, mapDispatchToProps)(AccountTypeTabs);