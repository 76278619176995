import { SagaIterator } from '@redux-saga/core';
import { put } from '@redux-saga/core/effects';
import GetTermsSuccessAction from '../actions/GetTermsSuccessAction';
import ActionTypes from '../actions/ActionTypes';
import ConsentAcceptedAction from '../actions/ConsentAcceptedAction';

export default function* handleTermsSuccess(action: GetTermsSuccessAction): SagaIterator {
    if (window.hj) {
        if (window.opener != null) {
            window.hj(
                'tagRecording', ['Pop-up']
            );
        }
    }

    yield put<ConsentAcceptedAction>({
        type: ActionTypes.CONSENT_ACCEPTED,
    });
}
