import { inject, injectable } from 'inversify';

import { AxiosInstance } from 'axios';
import { ConsentServiceEndpoints } from '../../constants/apiRoutes';
import IConsentDto from '../dto/IConsentDto';
import IConsentRequest from '../dto/IConsentRequest';
import IConsentService from './IConsentService';
import ICreateConsentDto from '../dto/ICreateConsentDto';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';

@injectable()
export default class ConsentService implements IConsentService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async createConsent(
        url: string,
        clientId: string,
        customerReference: string,
        validationToken: string,
        providerId: number,
        providerName: string,
        dataSource: string,
        countryCode: string,
        configurationId?: string | undefined,
        maxConsentDays?: number | undefined,
        invitationId?: string | undefined
    ): Promise<ICreateConsentDto> {
        const data: IConsentRequest = {
            applicationId: clientId,
            customerReference: customerReference,
            validationToken: validationToken,
            providerId: providerId,
            providerName: providerName,
            dataSource: dataSource,
            maxConsentDays: maxConsentDays,
            countryCode: countryCode,
            configurationName: configurationId || 'default',
            invitationId: invitationId
        };

        const consent = await this.apiClient.post<ICreateConsentDto>(ConsentServiceEndpoints.postConsent(url), data);

        return consent.data;
    }

    public async getConsent(url: string, consentId: string, token: string): Promise<IConsentDto> {
        const consent = await this.apiClient.get<IConsentDto>(ConsentServiceEndpoints.getConsent(url, consentId), {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return {
            consentId: consent.data.consentId,
            consentStart: consent.data.consentStart,
            daysOfHistoricalTransactions: consent.data.daysOfHistoricalTransactions,
            permissions: consent.data.permissions,
            providerId: consent.data.providerId,
            applicationId: consent.data.applicationId,
            customerReference: consent.data.customerReference,
            configurationName: consent.data.configurationName,
            consentStatus: consent.data.consentStatus,
            invitationId: consent.data.invitationId,
        };
    }
}
