import React from 'react';
import styles from './bankSearchList.module.scss';
import { IBankProps } from '../../types/IBankProps';
import { useTranslation } from 'react-i18next';
import { ICompleteBankChoiceActionPayload } from '../../types/ICompleteBankChoiceActionPayload';
import { INITIAL_ITEMS_NUMBER } from '../../../src/constants/bankSelectConstants';
import Spinner from '../spinner/Spinner';
import BankListRow from './BankListRow';
import LinkButton from '../buttons/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import SearchFeedback from "./SearchFeedback";

interface IProps {
  loading: Boolean;
  data: IBankProps[];
  showAll: Boolean;
  selectedCountryCode: string;
  selectBank: (data: ICompleteBankChoiceActionPayload) => void;
  onShowAll: () => void;
}

const BankSearchList: React.FC<IProps> = (props) => {
  const { loading, data, showAll, selectBank, onShowAll, selectedCountryCode } =
    props;
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner></Spinner>
      </div>
    );
  }

  const numberOfItems = showAll ? data.length : INITIAL_ITEMS_NUMBER;

  return (
    <>
      <SearchFeedback count={data.length} countryCode={selectedCountryCode} />
      <ol className={styles.list} data-cy='bank_search_results_list'>
        {data.slice(0, numberOfItems).map((item) => (
          <BankListRow
            key={item.brandId}
            bank={item}
            onClick={selectBank.bind(null, item)}
          />
        ))}
      </ol>
      {!showAll && data.length > INITIAL_ITEMS_NUMBER && (
        <div className={styles.seeMoreButtonWrapper}>
          <LinkButton onClick={onShowAll}>
            <FontAwesomeIcon icon={faChevronDown} /> {t('See more banks')}
          </LinkButton>
        </div>
      )}
    </>
  );
};

export default BankSearchList;