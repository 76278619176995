import { injectable } from "inversify";
import ITokenDecoder from "./ITokenDecoder";
import jwt_decode from 'jwt-decode'
import IAuthTokenProps from "../types/IAuthTokenProps";

@injectable()
export default class TokenDecoder implements ITokenDecoder{
    Decode(token: string): IAuthTokenProps {
        return jwt_decode(token);
    }
}