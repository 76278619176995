import { injectable, inject } from "inversify";
import { AxiosInstance } from 'axios';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import { GatewayServiceEndpoints, InsightsApiServiceEndpoints } from '../../constants/apiRoutes';
import PaymentConfirmationAccountSummaryDto from '../dto/IPaymentAccountConfirmationSummaryDto';
import IPaymentAccountConfirmationService from './IPaymentAccountConfirmationService';
import IPaymentAccountConfirmationRequestDto from '../dto/IPaymentAccountConfirmationRequestDto';

@injectable()
export default class PaymentAccountConfirmationService implements IPaymentAccountConfirmationService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getPaymentAccountConfirmationSummaryData(
        url: string,
        accessToken: string
    ) : Promise<PaymentConfirmationAccountSummaryDto> {
        const response = await this.apiClient.get<PaymentConfirmationAccountSummaryDto>(
            GatewayServiceEndpoints.paymentAccountConfirmation(url),
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            },
        );

        return response.data;
    }

    public async savePaymentAccountConfirmationData(
        url: string,
        accessToken: string | null,
        consentId: string | null,
        applicationId: string,
        accountId: string,
        accountNumber: string | null,
        bankCode: string | null,
        accountNumberSource: string,
        bankCodeSource: string,
    ): Promise<any> {
        const data: IPaymentAccountConfirmationRequestDto = {
            consentId: consentId,
            applicationId: applicationId,
            accountId: accountId,
            accountNumber: accountNumber,
            bankCode: bankCode,
            accountNumberSource: accountNumberSource,
            bankCodeSource: bankCodeSource
        };

        return await this.apiClient
            .post(InsightsApiServiceEndpoints.savePaymentConfirmationData(url), data, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
    };
}