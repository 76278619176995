import ActionTypes from '../actions/ActionTypes';
import ITokenProps from '../../types/ITokenProps';
import { getContext, put, apply, select, } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import IPaymentAccountConfirmationService from '../../domain/services/IPaymentAccountConfirmationService';
import PaymentAccountConfirmationService from '../../domain/services/PaymentAccountConfirmationService';
import { CompletePaymentAccountConfirmationSummaryAction,
         CompletePaymentAccountConfirmationSummarySucccessAction,
         CompletePaymentAccountConfirmationSummaryErrorAction } from '../actions/CompletePaymentAccountConfirmationSummaryAction';
import IAccessTokenStorage from '../../domain/services/IAccessTokenStorage';
import AccessTokenStorage from '../../domain/services/AccessTokenStorage';
import CallbackTokenScopeEnum from '../../types/enums/callbackTokenScopeEnum';
import jwt_decode from 'jwt-decode';
import { RouterAction, push } from 'react-router-redux';
import LoaderAction from '../../redux/actions/LoaderAction';
import { ServiceUrls } from '../../types/ServiceUrls';
import getServiceUrls from '../selectors/getServiceUrls';

export default function* handleSavePaymentAccountConfirmationSummary(
    action: CompletePaymentAccountConfirmationSummaryAction) {

    const container: Container = yield getContext('container');
    const accessTokenStorage: IAccessTokenStorage = container
        .get<IAccessTokenStorage>(AccessTokenStorage);

    const paymentAccountConfirmationService: IPaymentAccountConfirmationService =
        container.get<IPaymentAccountConfirmationService>(PaymentAccountConfirmationService);
    const serviceUrls : ServiceUrls = yield select(getServiceUrls);

    try {
        yield put<LoaderAction>({
            type: ActionTypes.LOADER_EVENT,
            payload: {
                display: true,
                framedLogo: true,
                spinnerText: 'Saving your payment confirmation information',
                showRecaptchaDisclaimer: false,
            },
        });

        if (accessTokenStorage.hasCallbackTokenScope(CallbackTokenScopeEnum.PaymentConfirmation)) {
            let accessToken: string | null = accessTokenStorage.getToken();
            let decodedToken: ITokenProps;

            if (!accessToken) {
                throw new Error("ConsentId is not available. The token is null.");
            }

            decodedToken = jwt_decode(accessToken);

            yield apply(
                paymentAccountConfirmationService,
                paymentAccountConfirmationService.savePaymentAccountConfirmationData,
                [
                    serviceUrls.insightsService,
                    accessToken,
                    decodedToken.consent_id,
                    decodedToken.application_id,
                    action.payload.accountId,
                    action.payload.accountNumber,
                    action.payload.bankCode,
                    action.payload.accountNumberSource,
                    action.payload.bankCodeSource
                ]
            );

            yield put<LoaderAction>({
                type: ActionTypes.LOADER_EVENT,
                payload: null,
            });
            
            yield put<RouterAction>(push('/end-success'));

            yield put<CompletePaymentAccountConfirmationSummarySucccessAction>({
                type: ActionTypes.COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_SUCCESS,
            })
        }
    } catch (error) {
        yield put<CompletePaymentAccountConfirmationSummaryErrorAction>({
            type: ActionTypes.COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_ERROR,
            payload: error.message,
        });
    }
}