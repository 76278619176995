import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBankProps } from '../../types/IBankProps';
import PageComponent from '../../components/providerSelection/ProviderSelectionPage';
import { IApplicationState } from '../../types/applicationState';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import GoBackAction from '../../redux/actions/GoBackAction';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IProviderSelectionProps } from '../../types/IProviderSelectionProps';

interface IBasicProps {
    bank: IBankProps,
    providerSelection: IProviderSelectionProps
};

interface IStateToProps {
    bank: IBankProps,
    providerSelection: IProviderSelectionProps,
    isLoaderEnabled: Boolean,
};

interface IDispatchToProps {
    onClickBack: () => void,
};

type IProps = IStateToProps & IBasicProps & IDispatchToProps & RouteComponentProps<any>;

const ProviderSelectionPage: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation();
    
    if (props.isLoaderEnabled) {
        return null;
    }

    const {history, location, match, staticContext, ...passThroughProps} = props;

    return (
        <PageComponent {...passThroughProps} hasBackButton={history.length > 0} t={t} />
    );
};

const mapStateToProps = (state: IApplicationState) => ({
    bank: state.selectedBank,
    providerSelection: state.providerSelection,
    isLoaderEnabled: state.loader.display,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onClickBack: () => dispatch<GoBackAction>({type: ActionTypes.GO_BACK}),
});

export { ProviderSelectionPage };
export default connect<IStateToProps, IDispatchToProps, any, IApplicationState>(mapStateToProps, mapDispatchToProps)(withRouter(ProviderSelectionPage));