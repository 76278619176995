import React, { SyntheticEvent } from 'react';

interface IProps {
    url: string,
    alt: string,
    thresholdWidth: number,
    thresholdHeight: number,
}

interface IState {
    show: boolean,
}

class ConditionalImage extends React.Component<IProps, IState> {
    public readonly state: IState = {
        show: true,
    }

    constructor(props: IProps) {
        super(props);

        this.checkDimensions = this.checkDimensions.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    private checkDimensions(event: SyntheticEvent<HTMLImageElement, Event>) {
        const target = event.currentTarget;

        if (target == null) {
            return;
        }

        const { thresholdWidth, thresholdHeight } = this.props;

        const imgWidth = target.naturalWidth;
        const imgHeight = target.naturalHeight;
        
        if (imgWidth < thresholdWidth && imgHeight < thresholdHeight) {
            this.setState({
                show: false,
            });
        }
    }

    private handleError() {
        this.setState({
            show: false,
        });
    }

    render() {
        if (!this.state.show) {
            return null;
        }

        return (
            <img
                src={this.props.url}
                alt={this.props.alt}
                onLoad={this.checkDimensions}
                onError={this.handleError}
            />
        )
    }
}

export default ConditionalImage;