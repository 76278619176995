import React, { useEffect } from 'react';
import PageComponent from '../../components/paymentAccountConfirmationSummary/paymentAccountConfirmationSummaryPage';
import { IApplicationState } from '../../types/applicationState';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ActionTypes from '../../redux/actions/ActionTypes';
import { CompletePaymentAccountConfirmationSummaryAction, CompletePaymentAccountConfirmationSummaryCancelledErrorAction } from '../../redux/actions/CompletePaymentAccountConfirmationSummaryAction';
import ValidateAccountNumberAction from '../../redux/actions/ValidateAccountNumberAction';
import ValidateBankCodeAction from '../../redux/actions/ValidateBankCodeAction';
import IPaymentAccountConfirmationSummaryProps from '../../types/IPaymentAccountConfirmationSummaryProps';
import IPaymentConfirmationAccountDataProps from '../../types/IPaymentConfirmationAccountDataProps';

interface IStateToProps {
    paymentAccountConfirmationSummary: IPaymentAccountConfirmationSummaryProps,
    selectedPaymentAccountConfirmationAccountId?: string,
    isLoaderEnabled: Boolean
};
interface IDispatchToProps {
    onConfirm: (account: IPaymentConfirmationAccountDataProps) => void,
    onCompleteConnection: () => void,
    onAccountNumberChange: (value: string, accountId: string) => void;
    onBankCodeChange: (value: string, accountId: string) => void;
};
interface IProps extends IStateToProps, IDispatchToProps { };

const PaymentAccountConfirmationSummaryPage: React.FC<IProps> = (props: IProps) => {
    useEffect(() => {
        if (window.hj) {
            window.hj(
                'tagRecording',
                ['Screen: Payment Account Confirmation Summary']
            );
        }
    }, [])

    if (props.isLoaderEnabled) {
        return null;
    }

    return (
        <PageComponent
            confirm={props.onConfirm}
            finishConnection={props.onCompleteConnection}
            onAccountNumberChange={props.onAccountNumberChange}
            onBankCodeChange={props.onBankCodeChange}
            paymentAccountConfirmationSummary={props.paymentAccountConfirmationSummary}
        />
    );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    paymentAccountConfirmationSummary: state.paymentAccountConfirmationSummary!,
    selectedPaymentAccountConfirmationAccountId: state.selectedPaymentAccountConfirmationAccountId,
    isLoaderEnabled: state.loader.display,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onConfirm: (account: IPaymentConfirmationAccountDataProps) => dispatch<CompletePaymentAccountConfirmationSummaryAction>({
        type: ActionTypes.COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY,
        payload: {
            accountId: account.accountId,
            accountNumber: account.accountNumberIsRequired
                ? account.accountNumberFormFieldValue.userValue
                : account.accountNumber,
            bankCode: account.bankCodeIsRequired
                ? account.bankCodeFormFieldValue.userValue
                : account.bankCode,
            accountNumberSource: account.accountNumberIsRequired ? "User" : "Bank",
            bankCodeSource: account.bankCodeIsRequired ? "User" : "Bank"
        }
    }),
    onCompleteConnection: () => dispatch<CompletePaymentAccountConfirmationSummaryCancelledErrorAction>({
        type: ActionTypes.COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_CANCELLED_ERROR,
    }),
    onAccountNumberChange: (value: string, accountId: string) => dispatch<ValidateAccountNumberAction>({
        type: ActionTypes.VALIDATE_ACCOUNT_NUMBER,
        payload: { accountNumber: value, accountId: accountId }
    }),
    onBankCodeChange: (value: string, accountId: string) => dispatch<ValidateBankCodeAction>({
        type: ActionTypes.VALIDATE_BANK_CODE,
        payload: { bankCode: value, accountId: accountId }
    }),

});
export { PaymentAccountConfirmationSummaryPage };
export default connect<IStateToProps, IDispatchToProps, any, IApplicationState>(mapStateToProps, mapDispatchToProps)(PaymentAccountConfirmationSummaryPage);