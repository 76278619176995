import { put, take, all } from '@redux-saga/core/effects';
import ActionTypes from '../actions/ActionTypes';
import { SimpleApplicationAction } from '../actions/ApplicationAction';

export default function* initialiseApplication() {
    yield put<SimpleApplicationAction>({
        type: ActionTypes.INIT_STATE,
    });

    yield all([
        take(ActionTypes.GET_COUNTRY_CODES_SUCCESS),
        take([
            ActionTypes.PROVIDER_PRESELECTION_SUCCESS,
            ActionTypes.INIT_FINAL_STAGE_SUCCESS,
            ActionTypes.INIT_BANK_SEARCH_STAGE_SUCCESS,
        ])
    ]);

    yield put<SimpleApplicationAction>({
        type: ActionTypes.INIT_APPLICATION_SUCCESS,
    });
}