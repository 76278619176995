import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import SelectCountryCodeAction from '../actions/SelectCountryCodeAction';

type Nullable<T> = T | null;

const countrySelectionReducer: Reducer<Nullable<string>, SelectCountryCodeAction> = (state: Nullable<string> = null, action: SelectCountryCodeAction): Nullable<string> => {
    if (action.type !== ActionTypes.SELECT_COUNTRY_CODE) {
        return state;
    }

    return action.payload.countryCode;
};

export default countrySelectionReducer;
