import React from 'react';
import styles from './bankTilesGrid.module.scss';
import BankTile from './BankTile';
import { IBankProps } from '../../types/IBankProps';
import { ICompleteBankChoiceActionPayload } from '../../types/ICompleteBankChoiceActionPayload';
import { MAX_NUMBER_OF_TILES } from '../../constants/bankSelectConstants';
import Spinner from '../spinner/Spinner';
import { useTranslation } from 'react-i18next';

interface IProps {
    banks: {
        loading: Boolean,
        data: IBankProps[]
    },
    selectBank: (data: ICompleteBankChoiceActionPayload) => void,
}

const BankTilesGrid: React.FC<IProps> = (props) => {
    const { selectBank, banks } = props;
    const { data, loading } = banks;
    const {t} = useTranslation();

    if (loading) {
        return (<div className={styles.loading}>
            <Spinner></Spinner>
        </div>);
    }

    return (<>
        <p>{t('Some popular banks')}:</p>
        <div className={styles.bankTileContainer}>
        {data.map((item, index) => (
            index < MAX_NUMBER_OF_TILES ?
                <BankTile data={item} key={index} onClick={selectBank.bind(null, {brandId: item.brandId})} />
                : <div key={index}/>
        ))}
        </div>
    </>);
};

export default BankTilesGrid;
