import React, { ReactNode } from 'react';
import styles from './toggleButtonGroup.module.scss';

interface IProps {
    children: ReactNode,
}

const ToggleButtonGroup: React.FC<IProps> = (props) => {
    return (<div className={styles.toggleGroup} {...props}></div>);
}

export default ToggleButtonGroup;