import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import InitReauthenticateFinalStageAction from '../actions/InitReauthenticateFinalStageAction';
import IConsentEndProps from '../../types/IConsentEndProps';

type NullableIConsentEndProps = IConsentEndProps | null;

const consentEndReducer: Reducer<NullableIConsentEndProps, InitReauthenticateFinalStageAction> = (
    state: NullableIConsentEndProps = null,
    action: InitReauthenticateFinalStageAction
): NullableIConsentEndProps => {
    if (action.type !== ActionTypes.INIT_REAUTHENTICATE_FINAL_STAGE) {
        return state;
    }

    return { ...action.payload };
};

export default consentEndReducer;
