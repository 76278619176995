import React, { useEffect } from 'react';
import IErrorProps from '../../types/IErrorProps';
import { connect } from 'react-redux';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import { CompleteConnectionAction } from '../../redux/actions/CompleteConnectionAction';
import GoToPageAction from '../../redux/actions/GoToPageAction';
import ActionTypes from '../../redux/actions/ActionTypes';
import ErrorScreenTypeEnum from '../../types/enums/errorScreenTypeEnum';
import LoadingError from '../../components/error/LoadingError';
import GenericError from '../../components/error/GenericError';
import ProviderUnavailableError from '../../components/error/ProviderUnavailableError';
import DataSharingCancelled from '../../components/error/DataSharingCancelled';
import ReAuthenticateCancelled from '../../components/error/ReAuthenticateCancelled';
import PaymentAccoutConfirmationError from '../../components/error/PaymentAccoutConfirmationError';
import IPaymentAccountConfirmationSummaryProps from '../../types/IPaymentAccountConfirmationSummaryProps';

interface IStateToProps {
    oldConsentEndDate: Date | undefined;
    paymentAccountConfirmationSummary: IPaymentAccountConfirmationSummaryProps | undefined;
    error: IErrorProps;
}

interface IDispatchToProps {
    onChooseDifferentBank: () => void;
    onCompleteConnection: () => void;
}

interface IProps extends IStateToProps, IDispatchToProps { }

const ErrorContainer: React.FC<IProps> = ({
    error,
    paymentAccountConfirmationSummary,
    oldConsentEndDate,
    onChooseDifferentBank,
    onCompleteConnection,
}: IProps) => {

    useEffect(() => {
        if (window.hj && error.code) {
                window.hj(
                    'tagRecording',
                    [`Error: ${error.code}`]
                );
        }
    }, [error.code])

    switch (error.type) {
        case ErrorScreenTypeEnum.GenericError:
            return <GenericError code={error.code} chooseDifferentBank={onChooseDifferentBank} />;
        case ErrorScreenTypeEnum.LoadingError:
            return <LoadingError code={error.code} finishConnection={onCompleteConnection} />;
        case ErrorScreenTypeEnum.ProviderUnavailableError:
            return (
                <ProviderUnavailableError
                    code={error.code}
                    chooseDifferentBank={onChooseDifferentBank}
                    finishConnection={onCompleteConnection}
                />
            );
        case ErrorScreenTypeEnum.DataSharingCancelledError:
            return (
                <DataSharingCancelled
                    chooseDifferentBank={onChooseDifferentBank}
                    finishConnection={onCompleteConnection}
                />
            );
        case ErrorScreenTypeEnum.ReAuthenticateCancelledError:
            return (
                <ReAuthenticateCancelled
                    finishConnection={onCompleteConnection}
                    oldConsentEndDate={oldConsentEndDate}
                />
            );
        case ErrorScreenTypeEnum.PaymentAccountConfirmationCancelledError:
            return (
                <PaymentAccoutConfirmationError
                    finishConnection={onCompleteConnection}
                    errorType={error.type}
                    customerName={paymentAccountConfirmationSummary!.customerName}
                />
            );
        case ErrorScreenTypeEnum.PaymentAccountConfirmationError:
            return (
                <PaymentAccoutConfirmationError
                    finishConnection={onCompleteConnection}
                    errorType={error.type}
                    customerName={paymentAccountConfirmationSummary!.customerName}
                />
            );
    }
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    error: state.error,
    paymentAccountConfirmationSummary: state.paymentAccountConfirmationSummary,
    oldConsentEndDate: state.consentEnd?.oldConsentEnd ? new Date(state.consentEnd!.oldConsentEnd) : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onChooseDifferentBank: () =>
        dispatch<GoToPageAction>({
            type: ActionTypes.GO_TO_PAGE,
            payload: {
                url: '/bank-select',
            },
        }),
    onCompleteConnection: () =>
        dispatch<CompleteConnectionAction>({
            type: ActionTypes.COMPLETE_CONNECTION,
        }),
});

export { ErrorContainer };
export default connect<IStateToProps, IDispatchToProps, any, IApplicationState>(
    mapStateToProps,
    mapDispatchToProps
)(ErrorContainer);
