import { SagaIterator } from '@redux-saga/core';
import { select } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import { push, RouterAction } from 'react-router-redux';
import { apply, delay, getContext, put } from 'redux-saga/effects';
import { ISettingsDto } from '../../domain/dto/ISettingsDto';
import ConfigurationStorageService from '../../domain/services/ConfigurationStorageService';
import ConsentStorage from '../../domain/services/ConsentStorage';
import IConfigurationStorageService from '../../domain/services/IConfigurationStorageService';
import IConsentStorage from '../../domain/services/IConsentStorage';
import IValidationTokenStorage from '../../domain/services/IValidationTokenStorage';
import ValidationTokenStorage from '../../domain/services/ValidationTokenStorage';
import { IApplicationProps } from '../../types/IApplicationProps';
import ActionTypes from '../actions/ActionTypes';
import { ExitApplicationAction, ExitApplicationToggleAction } from '../actions/ExitApplicationAction';
import LoaderAction from '../actions/LoaderAction';
import getApplicationState from '../selectors/getApplicationState';
import getSettings from '../selectors/getSettings';

export function* exitApplication(_: ExitApplicationAction): SagaIterator {
    yield put<LoaderAction>({
        type: ActionTypes.LOADER_EVENT,
        payload: null,
    });
    const container: Container = yield getContext('container');
    const settings: ISettingsDto = yield select(getSettings);
    const appProps: IApplicationProps = yield select(getApplicationState);
    
    const accessTokenManager: IValidationTokenStorage = container.get<IValidationTokenStorage>(ValidationTokenStorage);
    const consentStorage: IConsentStorage = container.get<IConsentStorage>(ConsentStorage);
    const configurationStorageService: IConfigurationStorageService = container.get<IConfigurationStorageService>(ConfigurationStorageService);
    yield apply(accessTokenManager, accessTokenManager.removeValidationToken, []);
    yield apply(consentStorage, consentStorage.removeConsentEnd, []);
    yield apply(configurationStorageService,configurationStorageService.removeItem,[appProps.clientId]);
    if (settings.redirectUrl) {
        appProps.hideExitButton = true;
        yield put<ExitApplicationToggleAction>({
            type: ActionTypes.EXIT_APPLICATION_TOGGLE,
            payload: appProps
        });


        yield put<LoaderAction>({
            type: ActionTypes.LOADER_EVENT,
            payload: {
                display: true,
                showRecaptchaDisclaimer: false,
                spinnerText: settings.customerName
                    ? `Redirecting you back to ${settings.customerName}`
                    : undefined,
            },
        });

        if (window.hj) {
            window.hj(
                'tagRecording',
                ['Screen: Exit Application']
            );
        }

        yield put<RouterAction>(push('/'));
        yield delay(2000);
        window.location.assign(
            settings.redirectUrl + 
            `?error=customer_cancelled&state=error&customer_ref=${(encodeURIComponent(appProps.customerReference))}`);
    } else {
        window.close();
    }
}
