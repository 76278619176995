import { SagaIterator } from '@redux-saga/core';
import { apply, getContext, put, select } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import ValidationTokenInitializer from '../../utils/ValidationTokenInitializer';
import IValidationTokenInitializer from '../../utils/IValidationTokenInitializer';
import { SimpleApplicationAction } from '../actions/ApplicationAction';
import IValidationTokenStorage from '../../domain/services/IValidationTokenStorage';
import ValidationTokenStorage from '../../domain/services/ValidationTokenStorage';
import ActionTypes from '../actions/ActionTypes';
import { IApplicationProps } from '../../types/IApplicationProps';
import getApplicationState from '../selectors/getApplicationState';
import LoaderAction from '../actions/LoaderAction';

export function* initValidationTokenFromFragment(_: SimpleApplicationAction): SagaIterator {
    const container: Container = yield getContext('container');
    const validationTokenInitializer: IValidationTokenInitializer = container
        .get<IValidationTokenInitializer>(ValidationTokenInitializer);
    const validationTokenStorage: IValidationTokenStorage = container
        .get<IValidationTokenStorage>(ValidationTokenStorage);
    const applicationState: IApplicationProps = yield select(getApplicationState);
    
    const hasValidationToken = yield apply(
        validationTokenStorage,
        validationTokenStorage.hasValidationToken,
        []);

    if (hasValidationToken) {
        applicationState.useValidationToken = true;
    }
    else {
        const validationToken = yield apply(validationTokenInitializer, validationTokenInitializer.init, []);

        if (validationToken) {
            yield apply(
                validationTokenStorage,
                validationTokenStorage.setValidationToken,
                [validationToken]);
            
            applicationState.useValidationToken = true;
        }
    }

    yield put<LoaderAction>({
        type: ActionTypes.LOADER_EVENT,
        payload: {
            showRecaptchaDisclaimer: !applicationState.useValidationToken
        },
    });

    yield put<SimpleApplicationAction>({
        type: ActionTypes.INIT_VALIDATION_TOKEN_COMPLETE,
    });
}