import { IApplicationState } from "../../types/applicationState";
import {ISettingsDto} from "../../domain/dto/ISettingsDto";

export default function getSettings(state: IApplicationState): ISettingsDto | null {
    if (!state || !state.settings) {
        throw Error();
    }

    return state.settings;
};
