import React, { useEffect } from 'react';
import commonStyles from './style/endResultStageCommon.module.scss';
import PrimaryButton from '../buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import IConnectionSummaryProps from '../../types/IConnectionSummaryProps';
import ConnectionSummaryTile from './ConnectionSummaryTile';
import BankUpdated from './BankUpdated';
import BankConnected from './BankConnected';

interface IProps {
    bankName: string;
    companyName: string;
    redirectUrlExists: boolean;
    connectionSummary?: IConnectionSummaryProps;
    finishConnection?: () => void;
    extendedConsentEnd?: Date;
    reAuthentication?: boolean;
}

const SuccessScreen: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const { bankName, companyName, connectionSummary, finishConnection, redirectUrlExists, extendedConsentEnd, reAuthentication } = props;

    useEffect(() => {
        if (window.hj) {
            if (redirectUrlExists) {
                window.hj(
                    'tagRecording', ['Redirect']
                );
            }

            if (window.opener != null) {
                window.hj(
                    'tagRecording', ['Pop-up']
                );
            }
        }
    }, [redirectUrlExists])

    return (
        <div className={commonStyles.screen}>
            <div className={commonStyles.topWrapper}>
                {reAuthentication ? (
                    <BankUpdated
                        bankName={bankName}
                        companyName={companyName}
                        extendedConsentEnd={extendedConsentEnd}
                    />
                ) : (
                    <BankConnected bankName={bankName} companyName={companyName} />
                )}
                {connectionSummary && !reAuthentication && (
                    <div className={commonStyles.connectionSummaryTilesWrap}>
                        {connectionSummary.accounts.map((acc, idx) => (
                            <ConnectionSummaryTile key={idx} account={acc} />
                        ))}
                    </div>
                )}
                {redirectUrlExists ? (
                    <div className={commonStyles.bottomContent}>
                        {t('You may now continue to {{companyName}}.', { companyName: companyName })}
                    </div>
                ) : (
                    <div className={commonStyles.bottomContent}>
                        {t('You may now close this window.')}
                    </div>
                )}
            </div>
            <div className={commonStyles.buttonWrapper}>
                {(redirectUrlExists || window.opener != null) && (
                    <PrimaryButton onClick={finishConnection}>
                        {redirectUrlExists ? t('Continue') : t('Close window')}
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};

export default SuccessScreen;
