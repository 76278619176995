import { Reducer } from 'redux';
import GetTermsSuccessAction from '../actions/GetTermsSuccessAction';
import ActionTypes from '../actions/ActionTypes';
import IConsentConfigurationProps from '../../types/IConsentConfigurationProps';

type NullableITermsProps = IConsentConfigurationProps | null;

const getTermsReducer: Reducer<NullableITermsProps, GetTermsSuccessAction> = (state: NullableITermsProps = null, action: GetTermsSuccessAction): NullableITermsProps => {
    if (action.type !== ActionTypes.GET_TERMS_SUCCESS) {
        return state;
    }

    return {
        ...action.payload.consentConfiguration
    };
};

export default getTermsReducer;
