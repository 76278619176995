import { IApplicationState } from '../../types/applicationState';
import { IProviderProps } from '../../types/IProviderProps';
import { IBankProps } from '../../types/IBankProps';

export default function getProviderSelected(state: IApplicationState): IProviderProps {
    if (!state || !state.selectedProvider) {
        throw Error();
    }

    const providerId: number = state.selectedProvider;
    const selectedBank: IBankProps = state.selectedBank;

    var selectedProvider = selectedBank.providers.find(function (element) {
        return element.providerId === providerId;
    });
    if (selectedProvider === undefined) {
        throw Error(`providerId[${providerId}] could not be found in selected bank[${selectedBank.name}].`);
    }
    return selectedProvider;
}
