import React from 'react';
import { IBankProps } from '../../types/IBankProps';
import ScaledImage from '../app/ScaledImage';
import styles from './bankListRow.module.scss';

interface IProps {
    bank: IBankProps,
    onClick?: () => void,
}

function getClassNameByLength(str: string) {
    return str.length > 35 ? styles.longText : undefined;
}

const BankListRow: React.FC<IProps> = (props: IProps) => {
    const { bank, onClick } = props;

    return (<li tabIndex={0} onClick={onClick} className={styles.row}>
        <div className={styles.imageWrapperOuter}>
            <div className={styles.imageWrapper}>
                <ScaledImage alt={bank.name} url={bank.logoUrl} scaleWidth={64} scaleHeight={48} />
            </div>
        </div>
        <span className={getClassNameByLength(bank.name)} data-cy="bank_name_matched_results">{bank.name}</span>
    </li>);
};

export default BankListRow;