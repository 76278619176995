import { IApplicationState } from "../../types/applicationState";
import { IBankProps } from '../../types/IBankProps';

export default function getBankSelected(state: IApplicationState): IBankProps  {
    
    if(!state || !state.selectedBank)
    {
        throw Error();
    }
    
    return state.selectedBank;
};