import { SagaIterator } from '@redux-saga/core';
import { getContext, apply, put, all, select } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import { CONTAINER } from '../../constants/di';
import { BankCodePreSelectChoiceAction, BankCodePreSelectErrorAction } from '../actions/PreSelectProviderChoiceAction';
import IProviderService from '../../domain/services/IProviderService';
import ProviderService from '../../domain/services/ProviderService';
import ActionTypes from '../actions/ActionTypes';
import { SimpleApplicationAction } from '../actions/ApplicationAction';
import CompleteProviderChoiceAction from '../actions/CompleteProviderChoiceAction';
import UpdateBankChoiceAction from '../actions/UpdateBankChoiceAction';
import { IBankDto } from '../../domain/dto/IBankDto';
import getServiceUrls from '../selectors/getServiceUrls';
import { ServiceUrls } from '../../types/ServiceUrls';

export default function* handleBankCodePreSelection(action: BankCodePreSelectChoiceAction): SagaIterator {
    const container: Container = yield getContext(CONTAINER);
    const providerService = container.get<IProviderService>(ProviderService);
    const serviceUrls: ServiceUrls = yield select(getServiceUrls);

    try {
        const banks: IBankDto[] = yield apply(providerService, providerService.getBanks, [
            serviceUrls.providerSearchService,
            action.payload.bankCode,
            action.payload.countryCode,
        ]);

        assertSingleProvider(banks);

        const bank = banks.first();
        const firstProvider = bank.providers.first();

        yield all([
            put<UpdateBankChoiceAction>({
                type: ActionTypes.UPDATE_BANK_SELECTION,
                payload: {
                    bank: {
                        ...bank,
                        providers: bank.providers.map((provider) => ({
                            providerId: provider.providerId,
                            name: provider.name,
                            maxConsentDays: provider.maxConsentDays,
                            dataSource: provider.dataSource,
                            hasDisplayName: false,
                            supportedAccounts: { Business: [], Personal: [] },
                        })),
                    },
                },
            }),
            put<CompleteProviderChoiceAction>({
                type: ActionTypes.COMPLETE_PROVIDER_SELECTION,
                payload: { providerId: firstProvider.providerId },
            }),
        ]);

        yield put<SimpleApplicationAction>({
            type: ActionTypes.BANK_CODE_PRESELECTION_SUCCESS,
        });
    } catch (error) {
        yield put<BankCodePreSelectErrorAction>({
            type: ActionTypes.BANK_CODE_PRESELECTION_ERROR,
            payload: error,
        });
    }
}

const assertSingleProvider = (banks: IBankDto[]): void => {
    if (banks.length !== 1) {
        throw Error('Expected one bank only to be present.');
    }

    const bank = banks.first();
    if (!bank?.name) {
        throw Error('Bank name missing');
    }
    if (bank?.providers?.length > 1) {
        throw Error('Expected one provider only to be present.');
    }
};
