import { Reducer } from 'redux';
import InitStateSuccessAction from '../actions/InitStateSuccessAction';
import InitReauthenticateStageSuccessAction from '../actions/InitReauthenticateStageSuccessAction';
import CreateConsentConnectionSuccessAction from '../actions/CreateConsentConnectionSuccessAction';
import CreateConsentSuccessAction from '../actions/CreateConsentSuccessAction';
import InitAccessTokenDecodeAction from '../actions/initAccessTokenDecodeAction';
import ClearConsentIdAction from '../actions/ClearConsentIdAction';
import ReauthenticateConsentConnectionSuccessAction from '../actions/ReauthenticateConsentConnectionSuccessAction';
import ActionTypes from '../actions/ActionTypes';
import { IApplicationProps } from '../../types/IApplicationProps';
import { InitGetSettingsSuccessAction } from '../actions/GetSettings';

const initialState: IApplicationProps = {
    clientId: '',
    rpName: '',
    customerReference: '',
    language: 'en-GB',
    useValidationToken: false,
    hideExitButton: false,
};

const initStateReducer: Reducer<IApplicationProps, InitStateSuccessAction | InitReauthenticateStageSuccessAction | InitAccessTokenDecodeAction | CreateConsentConnectionSuccessAction | CreateConsentSuccessAction | ClearConsentIdAction | ReauthenticateConsentConnectionSuccessAction | InitGetSettingsSuccessAction> = (
    state = initialState,
    action: InitStateSuccessAction | InitReauthenticateStageSuccessAction | InitAccessTokenDecodeAction | CreateConsentConnectionSuccessAction | CreateConsentSuccessAction | ClearConsentIdAction | ReauthenticateConsentConnectionSuccessAction | InitGetSettingsSuccessAction
) => {
    if (action.type === ActionTypes.INIT_STATE_SUCCESS) {
        return { ...action.payload };
    }
    if (action.type === ActionTypes.INIT_GET_SETTINGS_SUCCESS) {
        return { ...action.payload }
    }
    if (action.type === ActionTypes.INIT_REAUTHENTICATE_STAGE_SUCCESS) {
        return {
            ...state,
            clientId: action.payload.clientId,
            customerReference: action.payload.customerReference,
            configurationId: action.payload.configurationId,
        };
    }
    if (action.type === ActionTypes.INIT_ACCESS_TOKEN_DECODE) {
        return {
            ...state,
            clientId: action.payload
        };
    }
    if (action.type === ActionTypes.CREATE_CONSENT_ID_CONNECTION_SUCCESS) {
        return {
            ...state,
            redirectUrl: action.payload.redirectUrl
        };
    }
    if (action.type === ActionTypes.REAUTHENTICATE_CONSENT_CONNECTION_SUCCESS) {
        return {
            ...state,
            redirectUrl: action.payload.redirectionUrl
        };
    }
    if (action.type === ActionTypes.CREATE_CONSENT_ID_SUCCESS) {
        return {
            ...state,
            consentId: action.payload.consentId
        };
    }
    if (action.type === ActionTypes.CLEAR_CONSENT_ID) {
        return {
            ...state,
            consentId: undefined,
            redirectUrl: undefined
        };
    }

    return state;
};

export default initStateReducer;