import React from 'react';
import SingleSelect from '../forms/SingleSelect';
import Countries from '../../constants/countries';
import styles from './countryDropdown.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface IProps {
    countryCodes: string[];
    selectedCountryCode?: string;
    onCountryCodeChange: (event: React.FormEvent<HTMLSelectElement>) => void;
}

const convertCountriesToSelectOptions = (countries: string[], t: i18next.TFunction) => {
    const options = [];

    for (const countryCode of countries) {
        const name: string = Countries[countryCode];

        if (!name) {
            continue;
        }

        options.push({
            value: countryCode,
            label: `${t(name)} (${countryCode})`
        });
    }

    options.sort((a, b) => a.label > b.label ? 1 : -1);

    return options;
};

const CountryDropdown: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const { selectedCountryCode, countryCodes, onCountryCodeChange } = props;
    const selectedValue = selectedCountryCode || countryCodes.first();

    const options = convertCountriesToSelectOptions(countryCodes, t);

    if (options.length < 2) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.display}>
                <span className={styles.label}>{selectedValue}</span>
                <img
                    className={styles.flag}
                    src={`${application.env.CDN_BASE_URL}/${selectedValue}.svg`}
                    alt={selectedValue} />
                <FontAwesomeIcon className={styles.caret} icon={faCaretDown} />
            </div>
            <div className={styles.dropdown}>
                <SingleSelect
                    options={options}
                    value={selectedValue}
                    onChangeValue={onCountryCodeChange}
                    data-cy="country_select"
                />
            </div>
        </div>
    );
};

export default CountryDropdown;