import { Reducer } from 'redux';
import { ServiceUrls } from '../../types/ServiceUrls';
import ActionTypes from '../actions/ActionTypes';
import { GetServiceUrlsSuccessAction } from '../actions/GetServiceUrls';

type NullableProps = ServiceUrls | null;

const serviceUrlsReducer: Reducer<NullableProps, GetServiceUrlsSuccessAction> = (state: NullableProps = null, action: GetServiceUrlsSuccessAction): NullableProps => {
    if (action.type !== ActionTypes.GET_DISCOVERY_URLS_SUCCESS) {
        return state;
    }

    return action.payload;
};

export default serviceUrlsReducer;
