import ValidationHelpers from './validationHelpers';
import RoutingNumberLengths from '../../types/enums/RoutingNumberLengthEnum';
import IFormFieldValue from '../../types/IFormFieldValue';
import PaymentConfirmationErrorEnum from '../../types/enums/PaymentConfirmationValidationErrorEnum';

const US: string = 'US';
const CANADA: string = 'CA';

export default class BankCodeValidator {
    static bankDataIsValid = (bankCode: string | null, countryCode: string) : boolean => {
        if(!bankCode ||
           ValidationHelpers.isMasked(bankCode) ||
           !BankCodeValidator.routingNumberIsValidForCountry(bankCode, countryCode)) {
               return false;
           }

           return true;
    }

    static isValidBankCode = (bankCode: string | null | undefined, countryCode: string | undefined, originalValue: string | null) : IFormFieldValue => {
        let validationResult: IFormFieldValue = {
            isValid: false,
            userValue: bankCode as string,
            validationError: null,
        };

        if(!bankCode){
            return validationResult;
        }
        else
        if(!BankCodeValidator.routingNumberIsValidForCountry(bankCode, countryCode)) {
            validationResult.validationError = PaymentConfirmationErrorEnum.BANK_CODE_FORMAT_INVALID;
            return validationResult;
        }
        else
        if(originalValue && !ValidationHelpers.digitsAppearInOrder(bankCode, originalValue)) {
            validationResult.validationError = PaymentConfirmationErrorEnum.BANK_CODE_DOES_NOT_MATCH_BANK;
            return validationResult;
        }

        validationResult.isValid = true;
        return validationResult;
    };

    private static routingNumberIsValidForCountry = (routingNumber: string | null, countryCode: string | undefined) : boolean => {
        let isValid = true;

        switch(countryCode) {
            case CANADA:
                isValid = BankCodeValidator.isValidCanadianRoutingNumber(routingNumber);
                break;
            case US:
                isValid = BankCodeValidator.isValidUsRoutingNumber(routingNumber);
                break;
        }

        return isValid;
    }

    private static isValidUsRoutingNumber = (routingNumber: string | null | undefined) : boolean => {
        if(!routingNumber) {
            return false;
        }

        return ValidationHelpers.isOfLength(routingNumber, RoutingNumberLengths.USA_ABA) &&
               ValidationHelpers.passesChecksumAlgorithm(routingNumber)
    }

    private static isValidCanadianRoutingNumber = (routingNumber: string | null | undefined) : boolean => {
        if(!routingNumber) {
            return false;
        }

        return routingNumber.startsWith("0") &&
               (ValidationHelpers.isOfLength(routingNumber, RoutingNumberLengths.CANADIAN_EFT) || 
                ValidationHelpers.isOfLength(routingNumber, RoutingNumberLengths.CANADIAN_MICR));
    }
}