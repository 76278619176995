// import and re-export translations from `json` files
// the structure of json file is as follows:
// {
//     "original string": "translated string"
// }
//
// and must be transformed into translations
import fr from './fr.json';
import es from './es.json';
import it from './it.json';
import nl from './nl.json';
import pt from './pt.json';
import zhCN from './zh-CN.json';
import zhHK from './zh-HK.json';
import zhTW from './zh-TW.json';

const filterEmpty = (translations: { [key: string]: string }) => {
    const filtered: { [key: string]: string } = {};

    for (const [key, value] of Object.entries(translations)) {
        if (value === '') {
            continue;
        }

        filtered[key] = value;
    }

    return filtered;
}

export default {
    "fr": { translations: filterEmpty(fr) },
    "es": { translations: filterEmpty(es) },
    "it": { translations: filterEmpty(it) },
    "nl": { translations: filterEmpty(nl) },
    "pt": { translations: filterEmpty(pt) },
    "zh-CN": { translations: filterEmpty(zhCN) },
    "zh-HK": { translations: filterEmpty(zhHK) },
    "zh-TW": { translations: filterEmpty(zhTW) },
};