import React, { useEffect } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import PermissionClustersAccordion from '../../containers/consent/PermissionClustersAccordion';
import IdCoTerms from '../../containers/consent/IdCoTerms';
import styles from './consentPageContent.module.scss';
import i18next from 'i18next';
import RecaptchaDisclaimer from '../recaptcha/RecaptchaDisclaimer';
import Link from '../links/Link';
import { Trans } from 'react-i18next';
import ReauthenticateConsentPageContent from './ReauthenticateConsentPageContent';
import CreateConsentPageContent from './CreateConsentPageContent';
import ConsentStatus from '../../domain/enums/ConsentStatus';
import YapilyDisclaimer from '../yapily/YapilyDisclaimer';
import { IProviderSelectionProps } from '../../types/IProviderSelectionProps';

/* prettier-ignore */
interface IProps {
  onClickBack: () => void;
  t: i18next.TFunction;
  bankName: string;
  brandId: number;
  clientId: string;
  bankLogoUrl?: string;
  terms: string;
  hasBackButton: boolean;
  replacementTokens: { [key: string]: any };
  useValidationToken: boolean;
  oldConsent?: Date;
  consentStatus?: ConsentStatus;
  redirectUrl?: string;
  isImplicit?: boolean;
  showYapilyDisclaimer: boolean;
  consentId: string | undefined;
  providerSelection: IProviderSelectionProps;
  onCreateConsentSuccess: (consentId: string | undefined) => void;
  disableDefaultDisclaimer: boolean | undefined;
}

const ConsentPageContent: React.FC<IProps> = (props: IProps) => {
  const {
    t,
    bankName,
    brandId,
    clientId,
    bankLogoUrl,
    oldConsent,
    consentStatus,
    redirectUrl,
    replacementTokens,
    terms,
    hasBackButton,
    isImplicit,
    onCreateConsentSuccess,
    onClickBack,
    showYapilyDisclaimer,
    useValidationToken,
    consentId,
    providerSelection,
    disableDefaultDisclaimer,
  } = props;

  useEffect(() => {
    if (window.hj) {
      if (providerSelection) {
        window.hj('tagRecording', [
          `${providerSelection.selectedAccountType} Account`,
        ]);
      }

      if (clientId && brandId) {
        window.hj('identify', clientId, {
          brandId,
        });
      }
    }
  }, [providerSelection, clientId, brandId]);

  const handleRedirect = () => {
    onCreateConsentSuccess(consentId);
    redirectUrl && window.location.replace(redirectUrl);
  };

  return (
    <>
      {consentStatus === ConsentStatus.Active || consentStatus === ConsentStatus.Expired || consentStatus === ConsentStatus.Revoked ? (
        <ReauthenticateConsentPageContent
          t={t}
          bankName={bankName}
          bankLogoUrl={bankLogoUrl}
          oldConsent={oldConsent!}
          consentStatus={consentStatus}
          isImplicit={isImplicit}
        />
      ) : (
        <CreateConsentPageContent t={t} bankName={bankName} bankLogoUrl={bankLogoUrl} isImplicit={isImplicit} />
      )}
      {(!isImplicit || isImplicit === undefined) && <>
        <PermissionClustersAccordion t={t} replacementTokens={replacementTokens} />
        <IdCoTerms />
        <p>{terms}</p>
      </>}
      <div className={styles.buttonsWrapper}>
        <div data-cy="back_button" className={styles.buttonWrapper}>
          {hasBackButton && <SecondaryButton onClick={onClickBack}>{t('Back')}</SecondaryButton>}
        </div>
        <div data-cy="continue_button" className={styles.buttonWrapper}>
          <PrimaryButton onClick={handleRedirect}
            isDisabled={redirectUrl && consentId ? false : true}
          >{t(isImplicit ? 'Continue' : 'Confirm')}</PrimaryButton>
        </div>
      </div>
      <div className={styles.recaptcha} style={{ marginTop: (disableDefaultDisclaimer ?? showYapilyDisclaimer) ? 0 : '2em' }}>
        {(!disableDefaultDisclaimer ?? !showYapilyDisclaimer) && (<p className="defaultDisclaimer"><Trans>DirectID is made by The IDCo. Ltd., a company authorised and regulated in the UK as an Account Information Services Provider by the Financial Conduct Authority, registration number <Link href={'https://register.fca.org.uk/s/firm?id=0010X000046GUvIQAW'} target="_blank">798579</Link>. For further details please see our <Link href={'https://www.direct.id/policies'} target="_blank">Privacy Policy</Link>.</Trans></p>)}
        {showYapilyDisclaimer && <YapilyDisclaimer />}
        {!useValidationToken && <RecaptchaDisclaimer />}
      </div>
    </>
  );
};

export default ConsentPageContent;
