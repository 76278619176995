import React, { useEffect } from 'react';
import ActionTypes from '../../redux/actions/ActionTypes';
import { connect } from 'react-redux';
import { IApplicationState } from '../../types/applicationState';
import SuccessScreen from '../../components/endResultStage/SuccessScreen';
import { CompleteConnectionAction } from '../../redux/actions/CompleteConnectionAction';
import { Dispatch } from 'redux';
import { IApplicationProps } from '../../types/IApplicationProps';
import IConnectionSummaryProps from '../../types/IConnectionSummaryProps';

interface IStateToProps {
    application: IApplicationProps;
    connectionSummary?: IConnectionSummaryProps;
    extendedConsentEnd?: Date;
}

interface IDispatchToProps {
    onCompleteConnection: () => void;
}

interface IProps extends IStateToProps, IDispatchToProps {}

const ConnectionSuccessPage: React.FC<IProps> = (props: IProps) => {

    useEffect(() => {
        if (window.hj) {
            window.hj(
                'tagRecording',
                ['Screen: Connection Success']
            );
        }
    }, [])

    return (
        <SuccessScreen
            bankName={props.application.state ? props.application.state.ProviderName : ''}
            companyName={props.application.state ? props.application.state.ApplicationName : ''}
            redirectUrlExists={!!props.application.redirectUrl}
            finishConnection={props.onCompleteConnection}
            connectionSummary={props.connectionSummary}
            extendedConsentEnd={props.extendedConsentEnd}
            reAuthentication = {props.application.reAuthentication}
        />
    );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    application: state.application,
    connectionSummary: state.connectionSummary,
    extendedConsentEnd: state.consentEnd?.consentEnd ? new Date(state.consentEnd!.consentEnd) : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onCompleteConnection: () =>
        dispatch<CompleteConnectionAction>({
            type: ActionTypes.COMPLETE_CONNECTION,
        }),
});

export { ConnectionSuccessPage };
export default connect<IStateToProps, IDispatchToProps, any, IApplicationState>(
    mapStateToProps,
    mapDispatchToProps
)(ConnectionSuccessPage);
