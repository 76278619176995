
import qs from 'qs';
import { injectable } from 'inversify';
import IValidationTokenInitializer from './IValidationTokenInitializer';

@injectable()
export default class ValidationTokenInitializer implements IValidationTokenInitializer {
    public init(): string | null {
        var fragmentParameters = qs.parse(window.location.hash.slice(1), {
            decoder: decodeURIComponent
        });

        var validationToken: string | null = (
            (hash) => hash.validation_token ? String(hash.validation_token) : null
        )(fragmentParameters);

        return validationToken;
    }
};