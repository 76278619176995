import React from 'react';
import styles from './quitButton.module.scss';
import { IApplicationState } from '../../types/applicationState';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons'
import LinkButton from './LinkButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import  { islightColor }  from '../../utils/ColorHelper'

interface IProps {
    className?: string,
    exitApplication?: () => void,
    hideExitButton: boolean,
    rpName?: string,
    backgroundColor?: string,
};

const QuitButton: React.FC<IProps> = (props) => {  
    const { t } = useTranslation();
    let isLightBackgorund: boolean = true;
    if(props.backgroundColor){
        isLightBackgorund = islightColor(props.backgroundColor);
    }
    return (        
        <div className={`${styles.quitButtonContainer} ${isLightBackgorund ? styles.quitButtonContainerLight : styles.quitButtonContainerDark}`} hidden={props.hideExitButton}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft as IconProp} />
            <LinkButton onClick={props.exitApplication}>                
                {t('Cancel and return to {{rpName}}', { rpName: props.rpName})}
            </LinkButton>
        </div>
    );
};

const mapStateToProps = (state: IApplicationState): IProps => ({
    hideExitButton: state.application.hideExitButton,
    rpName: state?.settings?.customerName,
});

export default connect<IProps, any, any, IApplicationState>(mapStateToProps)(QuitButton);