import React, {useEffect} from 'react';
import styles from './consentPageContent.module.scss';
import i18next from 'i18next';
import ConditionalImage from '../app/ConditionalImage';

interface IProps {
    t: i18next.TFunction;
    bankName: string;
    bankLogoUrl?: string;
    isImplicit?: boolean;
}

const getSharingInformation = (t: i18next.TFunction, bankName: string, isImplicit: boolean | undefined) => {
    if (isImplicit) {
        return `${t('To continue, we\'ll connect you securely to your bank.')}`;
    }

    return `${t(
        'To continue, DirectID needs your permission to access the following information from your {{bankName}} account',
        { bankName: bankName }
    )}:`;
};

const getTitle = (t: i18next.TFunction, bankName: string, isImplicit: boolean | undefined) => {
    if(isImplicit){
        return `${t('Connect your {{bankName}} account', { bankName: bankName })}`;
    }

    return `${t('Consent to share financial information')}`;
};

const CreateConsentPageContent: React.FC<IProps> = (props: IProps) => {
    const { t, bankName, isImplicit, bankLogoUrl } = props;
    const sharingInformation = getSharingInformation(t, bankName, isImplicit);
    const title = getTitle(t, bankName, isImplicit );
    const additionalSharingInformation = `${t('Please ensure you have your bank details at hand before proceeding. DirectID will not be able to see any of your login details.')}`;

    
    useEffect(()=>{
        if (window.hj) {
            window.hj(
                 'tagRecording',
                 ['Screen: Create Consent']
             );
        }
    },[])

    return (
        <>
            {bankLogoUrl ? (
                <div className={styles.introduction} data-cy="consent_page_introduction_section">
                    <h1 className={styles.title} data-cy="consent_page_introduction_title">{title}</h1>
                    <p className={styles.paragraph} data-cy="consent_page_introduction_paragraph">{sharingInformation}</p>
                    {isImplicit && <p className={styles.additionalParagraph}>{additionalSharingInformation}</p>}
                    <div className={styles.imageWrapper}>
                        <ConditionalImage
                            url={bankLogoUrl}
                            alt={bankName}
                            thresholdHeight={25}
                            thresholdWidth={44}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <h1 data-cy="consent_page_introduction_title">{title}</h1>
                    <p>{sharingInformation}</p>
                </>
            )}
        </>
    );
};

export default CreateConsentPageContent;
