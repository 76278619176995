enum ActionTypes {
    UNKNOWN = 'UNKNOWN',
    INIT_APPLICATION = 'INIT APPLICATION',
    INIT_APPLICATION_SUCCESS = 'INIT APPLICATION SUCCESS',
    INIT_STATE = 'INIT STATE',
    INIT_STATE_SUCCESS = 'INIT STATE SUCCESS',
    INIT_STATE_ERROR = 'INIT STATE ERROR',
    INIT_ACCESS_TOKEN = 'INIT ACCESS TOKEN',
    INIT_ACCESS_TOKEN_COMPLETE = 'INIT ACCESS TOKEN COMPLETE',
    INIT_CALLBACK_TOKEN_SCOPE = 'INIT CALLBACK TOKEN SCOPE',
    INIT_CALLBACK_TOKEN_SCOPE_COMPLETE = 'INIT CALLBACK TOKEN SCOPE COMPLETE',
    INIT_VALIDATION_TOKEN = 'INIT VALIDATION TOKEN',
    INIT_VALIDATION_TOKEN_COMPLETE = 'INIT VALIDATION TOKEN COMPLETE',
    GET_COUNTRY_CODES = 'GET COUNTRY CODES',
    GET_COUNTRY_CODES_SUCCESS = 'GET COUNTRY CODES SUCCESS',
    GET_COUNTRY_CODES_ERROR = 'GET COUNTRY CODES ERROR',
    PROVIDER_PRESELECTION = 'PROVIDER PRESELECTION',
    PROVIDER_PRESELECTION_SUCCESS = 'PROVIDER PRESELECTION SUCCESS',
    PROVIDER_PRESELECTION_ERROR = 'PROVIDER PRESELECTION ERROR',
    BANK_CODE_PRESELECTION = 'BANK CODE PRESELECTION',
    BANK_CODE_PRESELECTION_SUCCESS = 'BANK CODE PRESELECTION SUCCESS',
    BANK_CODE_PRESELECTION_ERROR = 'BANK CODE PRESELECTION ERROR',
    INIT_FINAL_STAGE = 'INIT FINAL STAGE',
    INIT_FINAL_STAGE_SUCCESS = 'INIT FINAL STAGE SUCCESS',
    INIT_BANK_SEARCH_STAGE = 'INIT BANK SEARCH STAGE',
    INIT_BANK_SEARCH_STAGE_SUCCESS = 'INIT BANK SEARCH STAGE SUCCESS',
    INIT_BANK_SEARCH_STAGE_ERROR = 'INIT BANK SEARCH STAGE ERROR',
    POPULATE_COUNTRY_CODES = 'POPULATE COUNTRY CODES',
    SELECT_COUNTRY_CODE = 'SELECT COUNTRY CODE',
    GET_CONNECTION_SUMMARY = 'GET CONNECTION SUMMARY',
    GET_CONNECTION_SUMMARY_SUCCESS = 'GET CONNECTION SUMMARY SUCCESS',
    GET_CONNECTION_SUMMARY_ERROR = 'GET CONNECTION SUMMARY ERROR',
    UPDATE_BANK_SELECTION = 'UPDATE BANK SELECTION',
    CREATE_PROVIDER_SELECTION = 'CREATE PROVIDER SELECTION',
    COMPLETE_PROVIDER_SELECTION = 'COMPLETE PROVIDER SELECTION',
    GO_TO_PAGE = 'GO TO PAGE',
    GO_BACK = 'GO BACK',
    GET_FAVOURITE_BANKS = 'GET FAVOURITE BANKS',
    GET_FAVOURITE_BANKS_SUCCESS = 'GET FAVOURITE BANKS SUCCESS',
    GET_FAVOURITE_BANKS_ERROR = 'GET FAVOURITE BANKS ERROR',
    GET_BANKS = 'GET BANKS',
    GET_BANKS_SUCCESS = 'GET BANKS SUCCESS',
    GET_BANKS_ERROR = 'GET BANKS ERROR',
    SHOW_ALL_BANKS = 'SHOW ALL BANKS',
    COMPLETE_BANK_SELECTION = 'COMPLETE BANK SELECTION',
    COMPLETE_CONNECTION = 'COMPLETE CONNECTION',
    UPDATE_SELECTED_ACCOUNT_TYPE = 'UPDATE SELECTED ACCOUNT TYPE',
    GET_TERMS_SUCCESS = 'GET TERMS SUCCESS',
    GET_TERMS_ERROR = 'GET TERMS ERROR',
    CONSENT_ACCEPTED = 'CONSENT ACCEPTED',
    CONSENT_ACCEPTED_ERROR = 'CONSENT ACCEPTED ERROR',
    CREATE_CONSENT_SUCCESS_INIT = 'CREATE CONSENT SUCCESS INITIATION',
    CREATE_CONSENT_SUCCESS = 'CREATE CONSENT SUCCESS',
    CREATE_CONSENT_ID_SUCCESS = 'CREATE CONSENT ID SUCCESS',
    CREATE_CONSENT_ID_ERROR = 'CREATE CONSENT ID ERROR',
    CREATE_CONSENT_ID_CONNECTION_SUCCESS = 'CREATE CONSENT ID CONNECTION SUCCESS',
    CREATE_CONSENT_ID_CONNECTION_ERROR = 'CREATE CONSENT ID CONNECTION ERROR',
    RECAPTCHA_UPDATE = 'RECAPTCHA UPDATE',
    RECAPTCHA_ERROR = 'RECAPTCHA ERROR',
    VALIDATION_TOKEN_UPDATE = 'VALIDATION TOKEN UPDATE',
    RESET_SELECTION_ACTION = 'RESET SELECTION ACTION',
    LOADER_EVENT = 'LOADER EVENT',
    GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY = 'GET PAYMENT ACCOUNT CONFIRMATION SUMMARY',
    GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_SUCCESS = 'GET PAYMENT ACCOUNT CONFIRMATION SUMMARY SUCCESS',
    GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_ERROR = 'GET PAYMENT ACCOUNT CONFIRMATION SUMMARY ERROR',
    VALIDATE_BANK_DATA = 'VALIDATE_BANK_DATA',
    VALIDATE_BANK_DATA_COMPLETED = 'VALIDATE BANK DATA COMPLETED',
    VALIDATE_ACCOUNT_NUMBER = 'VALIDATE_ACCOUNT_NUMBER',
    VALIDATE_BANK_CODE = 'VALIDATE_BANK_CODE',
    VALIDATION_ERROR = 'VALIDATION ERROR',
    ACCOUNT_NUMBER_VALIDATION_COMPLETE = 'ACCOUNT NUMBER VALIDATION COMPLETE',
    BANK_CODE_VALIDATION_COMPLETE = 'BANK CODE VALIDATION COMPLETE',
    COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY = 'COMPLETE PAYMENT ACCOUNT CONFIRMATION SUMMARY',
    COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_SUCCESS = 'COMPLETE PAYMENT ACCOUNT CONFIRMATION SUMMARY SUCCESS',
    COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_ERROR = 'COMPLETE PAYMENT ACCOUNT CONFIRMATION SUMMARY ERROR',
    COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_CANCELLED_ERROR = 'COMPLETE PAYMENT ACCOUNT CONFIRMATION SUMMARY CANCELLED ERROR',
    REDIRECTION_ERROR = 'REDIRECTION ERROR',
    INIT_REAUTHENTICATE_STAGE = 'INIT REAUTHENTICATE STAGE',
    INIT_REAUTHENTICATE_STAGE_SUCCESS = 'INIT REAUTHENTICATE STAGE SUCCESS',
    INIT_REAUTHENTICATE_STAGE_ERROR = 'INIT REAUTHENTICATE STAGE ERROR',
    REAUTHENTICATE_CONSENT_CONNECTION = 'REAUTHENTICATE CONSENT CONNECTION',
    REAUTHENTICATE_CONSENT_CONNECTION_SUCCESS = 'REAUTHENTICATE CONSENT CONNECTION SUCCESS',
    REAUTHENTICATE_CONSENT_CONNECTION_ERROR = 'REAUTHENTICATE CONSENT CONNECTION ERROR',
    INIT_REAUTHENTICATE_FINAL_STAGE = 'INIT REAUTHENTICATE FINAL STAGE',
    GET_DISCOVERY_URLS = 'GET DISCOVERY URLS',
    GET_DISCOVERY_URLS_SUCCESS = 'GET DISCOVERY URLS SUCCESS',
    GET_DISCOVERY_URLS_ERROR = 'GET DISCOVERY URLS ERROR',
    INIT_ACCESS_TOKEN_DECODE = "INIT ACCESS TOKEN DECODE",
    INIT_ACCESS_TOKEN_DECODE_SUCCESS = "INIT ACCESS TOKEN DECODE SUCCESS",
    INIT_ACCESS_TOKEN_DECODE_ERROR = "INIT ACCESS TOKEN DECODE ERROR",
    GET_SETTINGS = 'GET SETTINGS',
    GET_SETTINGS_SUCCESS = 'GET SETTINGS SUCCESS',
    INIT_GET_SETTINGS_SUCCESS = 'INIT GET SETTINGS SUCCESS',
    GET_SETTINGS_ERROR = 'GET SETTINGS ERROR',
    EXIT_APPLICATION = 'EXIT APPLICATION',
    EXIT_APPLICATION_TOGGLE = 'EXIT APPLICATION TOGGLE',
    CLEAR_CONSENT_ID_INIT = 'CLEAR CONSENT ID INIT',
    CLEAR_CONSENT_ID = 'CLEAR CONSENT ID',
}

export default ActionTypes;
