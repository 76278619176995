import { SagaIterator } from '@redux-saga/core';
import { apply, put, getContext } from '@redux-saga/core/effects';
import ActionTypes from '../actions/ActionTypes';
import InitStateSuccessAction from '../actions/InitStateSuccessAction';
import { Container } from 'inversify';
import StateInitializer from '../../utils/StateInitializer';
import IStateInitializer from '../../utils/IStateInitializer';
import { IApplicationProps } from '../../types/IApplicationProps';
import { SimpleApplicationAction, ErrorAction } from '../actions/ApplicationAction';

export function* initStateFromQueryString(_: SimpleApplicationAction): SagaIterator {
    const container: Container = yield getContext('container');
    const stateInitializer = container.get<IStateInitializer>(StateInitializer);
    const application: IApplicationProps = yield apply(stateInitializer, stateInitializer.init, []);

    if (!application.state && !(application.clientId && application.customerReference) && !application.consentId) {
        yield put<ErrorAction>({
            type: ActionTypes.INIT_STATE_ERROR,
        });
        return;
    }
    yield put<InitStateSuccessAction>({
        type: ActionTypes.INIT_STATE_SUCCESS,
        payload: application,
    });
}
