import { put, apply, getContext, select } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import ActionTypes from '../actions/ActionTypes';
import { Container } from 'inversify';
import getApplicationState from '../selectors/getApplicationState';
import { IApplicationProps } from '../../types/IApplicationProps';
import IProviderService from '../../domain/services/IProviderService';
import ProviderService from '../../domain/services/ProviderService';
import { ICountryListDto } from '../../domain/dto/ICountryListDto';
import SelectCountryCodeAction from '../actions/SelectCountryCodeAction';
import PopulateCountryCodesAction from '../actions/PopulateCountryCodesAction';
import { ErrorAction, SimpleApplicationAction } from '../actions/ApplicationAction';
import { ServiceUrls } from '../../types/ServiceUrls';
import getServiceUrls from '../selectors/getServiceUrls';

export default function* getCountryCodes(): SagaIterator {
    const container: Container = yield getContext('container');
    const providerService = container.get<IProviderService>(ProviderService);
    const applicationState: IApplicationProps = yield select(getApplicationState);
    const serviceUrls: ServiceUrls = yield select(getServiceUrls);
    
    try {
        const data: ICountryListDto = yield apply(providerService, providerService.getCountryCodesList, [
            serviceUrls.providerSearchService,
            applicationState.clientId,
            applicationState.configurationId,
        ]);

        yield put<PopulateCountryCodesAction>({
            type: ActionTypes.POPULATE_COUNTRY_CODES,
            payload: data.countries
        });
        yield put<SelectCountryCodeAction>({
            type: ActionTypes.SELECT_COUNTRY_CODE,
            payload: {
                countryCode: data.defaultCountry,
                skipFetchingBanks: true,
            }
        });
        yield put<SimpleApplicationAction>({
            type: ActionTypes.GET_COUNTRY_CODES_SUCCESS,
        });
    } catch (err) {
        yield put<ErrorAction>({
            type: ActionTypes.GET_COUNTRY_CODES_ERROR,
            payload: err
        });
    }
}
