import { put, take, select, putResolve } from '@redux-saga/core/effects';
import ActionTypes from '../actions/ActionTypes';
import InitStateSuccessAction from '../actions/InitStateSuccessAction';
import GetCountryCodesAction from '../actions/GetCountryCodesAction';
import { ErrorAction, InitialisationAction, SimpleApplicationAction } from '../actions/ApplicationAction';
import PreSelectProviderChoiceAction, { BankCodePreSelectChoiceAction } from '../actions/PreSelectProviderChoiceAction';
import InitReauthenticateStageAction from '../actions/ReauthenticateAction';
import getApplicationState from '../selectors/getApplicationState';
import { IApplicationProps } from '../../types/IApplicationProps';
import GetServiceUrlsAction from '../actions/GetServiceUrls';
import GetSettingsAction from "../actions/GetSettings";
import { ExitApplicationToggleAction } from '../actions/ExitApplicationAction';
import getCorrelationId from '../../utils/CorrelationIdHelper';

export default function* handleInitStateSuccess(action: InitStateSuccessAction) {
    const applicationState: IApplicationProps = yield select(getApplicationState);
    const correlationId = getCorrelationId();

    if (window.hj) {
        if (applicationState.providerId) {
            window.hj(
                'identify',
                applicationState.clientId,
                {
                    providerId: applicationState.providerId,
                }
            );
        }

        if (correlationId) {
            window.hj(
                'identify',
                applicationState.clientId,
                {
                    correlationId: correlationId
                }
            );
        }

        if (applicationState.reAuthentication) {
            window.hj(
                'tagRecording', ['Reauthentication']
            );
        }

        if(window.opener != null) {
            window.hj(
                'tagRecording', ['Pop-up']
            );
        }
    }

    if (!applicationState.hideExitButton) {
        applicationState.hideExitButton = true;
        yield put<ExitApplicationToggleAction>({
            type: ActionTypes.EXIT_APPLICATION_TOGGLE,
            payload: applicationState
        });
    }

    if (action.payload.consentId) {
        if (window.hj && applicationState.clientId) {
            window.hj(
                'identify',
                applicationState.clientId,
                {
                    consentId: action.payload.consentId
                }
            );
        }

        yield put<SimpleApplicationAction>({
            type: ActionTypes.INIT_ACCESS_TOKEN,
        });

        yield take(ActionTypes.INIT_ACCESS_TOKEN_COMPLETE);

        yield put<GetSettingsAction>({
            type: ActionTypes.GET_SETTINGS,
        });

        yield putResolve<GetServiceUrlsAction>({
            type: ActionTypes.GET_DISCOVERY_URLS,
        });

        yield take(ActionTypes.GET_DISCOVERY_URLS_SUCCESS);

        yield put<InitReauthenticateStageAction>({
            type: ActionTypes.INIT_REAUTHENTICATE_STAGE,
            payload: {
                consentId: action.payload.consentId,
            },
        });
        return;
    }

    yield put<GetSettingsAction>({
        type: ActionTypes.GET_SETTINGS,
    });

    yield put<SimpleApplicationAction>({
        type: ActionTypes.INIT_VALIDATION_TOKEN,
    });

    yield take(ActionTypes.INIT_VALIDATION_TOKEN_COMPLETE);

    yield putResolve<GetServiceUrlsAction>({
        type: ActionTypes.GET_DISCOVERY_URLS,
    });

    yield take(ActionTypes.GET_DISCOVERY_URLS_SUCCESS);

    yield put<GetCountryCodesAction>({
        type: ActionTypes.GET_COUNTRY_CODES,
    });

    if (action.payload.state) {
        yield put<SimpleApplicationAction>({
            type: ActionTypes.INIT_ACCESS_TOKEN,
        });

        yield take(ActionTypes.INIT_ACCESS_TOKEN_COMPLETE);

        yield putResolve<GetServiceUrlsAction>({
            type: ActionTypes.GET_DISCOVERY_URLS,
        });

        yield take(ActionTypes.GET_DISCOVERY_URLS_SUCCESS);

        yield put<SimpleApplicationAction>({
            type: ActionTypes.INIT_CALLBACK_TOKEN_SCOPE,
        });

        yield take(ActionTypes.INIT_CALLBACK_TOKEN_SCOPE_COMPLETE);

        yield put<InitialisationAction>({
            type: ActionTypes.INIT_FINAL_STAGE,
            payload: action.payload,
        });

        if (applicationState.error) {
            yield put<ErrorAction>({
                type: ActionTypes.REDIRECTION_ERROR,
                payload: action.payload.error,
            });
            return;
        }
        return;
    }

    if (action.payload.providerId) {
        yield put<PreSelectProviderChoiceAction>({
            type: ActionTypes.PROVIDER_PRESELECTION,
            payload: {
                providerId: action.payload.providerId,
            },
        });
        return;
    }

    if (action.payload.bankCode && action.payload.countryCode) {
        yield put<BankCodePreSelectChoiceAction>({
            type: ActionTypes.BANK_CODE_PRESELECTION,
            payload: {
                bankCode: action.payload.bankCode,
                countryCode: action.payload.countryCode,
            },
        });
        return;
    }

    yield put<SimpleApplicationAction>({
        type: ActionTypes.INIT_BANK_SEARCH_STAGE,
    });
}
