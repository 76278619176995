import { injectable, inject } from 'inversify';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import IConfigurationStorageService from "./IConfigurationStorageService";
import {ISettingsDto} from "../dto/ISettingsDto";

@injectable()
export default class ConfigurationStorageService implements IConfigurationStorageService{

    @inject(ThirdPartyTypes.LocalStorage)
    private localStorage!: Storage;

    setItem(name:string,value: string,): void {
        this.localStorage.setItem(this.getCompositeKey(name), value);
    }

    hasItem(name:string): boolean {
        return null !== this.localStorage.getItem(this.getCompositeKey(name));
    }

    getItem(name:string): ISettingsDto {
        return JSON.parse(this.localStorage.getItem(this.getCompositeKey(name))!);
    }

    removeItem(name:string) {
        this.localStorage.removeItem(this.getCompositeKey(name));
    }

    private getCompositeKey(primaryKey: string) {
        return `did_config.${primaryKey}`;
    }

}
