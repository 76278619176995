import React from 'react';
import styles from './error.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';

interface IProps {
    finishConnection: () => void;
    oldConsentEndDate: Date | undefined;
}

const ReAuthenticateCancelled: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.screen}>
            <div className={styles.topWrapper}>
                <FontAwesomeIcon icon={faTimes} className={styles.icon} />
                <h1>{t('Bank access refresh cancelled')}</h1>
                {props.oldConsentEndDate ? (
                <>
                <div className={styles.content}>
                    {t('Access to your bank data has not been changed and will expire on the following date:')}
                </div>
                <div className={`${styles.bottomContent} ${styles.thick}`}>
                    {props.oldConsentEndDate?.toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                    })}
                </div>
                </>
                ):(
                <div className={styles.content}>
                    {t('Access to your bank data has not been changed.')}
                </div>
                )}
            </div>
            <div className={styles.buttonWrapper}>
                <PrimaryButton onClick={props.finishConnection}>{t('Quit')}</PrimaryButton>
            </div>
        </div>
    );
};

export default ReAuthenticateCancelled;
