import React from 'react';
import styles from './error.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import ErrorSupportLink from './ErrorSupportLink';

interface IProps {
  code?: string
  chooseDifferentBank: () => void,
  finishConnection: () => void,
}

const ProviderUnavailableError: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.screen}>
      <div className={styles.topWrapper}>
        <FontAwesomeIcon icon={faExclamationTriangle} className={styles.icon} />
        <h1>{t('Unable to connect to provider')}</h1>
        <div className={styles.content}>
          <p>{t(`Sorry, We are currently unable to connect to your provider.`)}</p>
          <p>{t(`You may wish to choose another provider, or try selecting a different account type.`)}</p>
          <p>{t(`If the problem persists, please contact our`)} <ErrorSupportLink errorCode={props.code} /></p>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <PrimaryButton onClick={props.chooseDifferentBank}>{t('Return to Bank Select')}</PrimaryButton>
        <SecondaryButton onClick={props.finishConnection}>{t('Quit')}</SecondaryButton>
      </div>
    </div>
  );
};

export default ProviderUnavailableError;