import React from 'react';
import styles from './paymentAccountConfirmationSummaryPage.module.scss';
import { useTranslation } from 'react-i18next';
import IPaymentAccountConfirmationSummaryProps from '../../types/IPaymentAccountConfirmationSummaryProps';
import IPaymentConfirmationAccountDataProps from '../../types/IPaymentConfirmationAccountDataProps';
import SingleSelect from '../forms/SingleSelect';

interface IProps {
    paymentAccountConfirmationSummary: IPaymentAccountConfirmationSummaryProps,
    onAccountSelectionChange: (account: IPaymentConfirmationAccountDataProps) => void;
    selectedAccountId: string;
};

const convertpaymentAccountConfirmationSummaryToSelectOptions = (accounts: IPaymentConfirmationAccountDataProps[]) => {
    const options = [];

    for (const account of accounts) {
        const name: string = `${account.displayName}: ${account.accountNumber} - ${account.accountType}` ;
        
        options.push({
            value: account.accountId,
            label: name
        });
    }
    options.sort((a, b) => a.label > b.label ? 1 : -1);
    return options;
};

const SelectAccountPage: React.FC<IProps> = (props: IProps) => {

    const { t } = useTranslation();
    const accounts = props.paymentAccountConfirmationSummary.accounts; 
    const options = convertpaymentAccountConfirmationSummaryToSelectOptions(accounts);

    const selectAccount = (accountId: string, accounts: IPaymentConfirmationAccountDataProps[]): IPaymentConfirmationAccountDataProps => {
        for (const account of accounts) {
            if (account.accountId === accountId) {
                return account;
            }
        }
        throw Error(`accountId[${accountId}] for Payment Confirmation could not be found.`);
    };

    return (
      <div className={styles.dropdown}>
        <div className={styles.content}>{t("Select Account")}</div>
        <SingleSelect
          options={options}
          value={props.selectedAccountId}
          onChangeValue={e =>
            props.onAccountSelectionChange(
              selectAccount(e.currentTarget.value, accounts)
            )
          }
        />
      </div>
    );
};

export default SelectAccountPage;