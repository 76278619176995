export default class ValidationHelpers {
    static isOfLength = (value: string | null, length: Number) : boolean => {
        if(!value) {
            return false;
        }

        return value.length === length;
    }

    static isBetweenLengthRange = (value: string | null, minLength: Number, maxLength: Number) : boolean => {
        if(!value) {
            return false;
        }

        return value.length >= minLength && value.length <= maxLength;
    }

    static digitsAppearInOrder = (value: string | null | undefined, originalValue: string | null) : boolean => {
        if(!value || !originalValue) {
            return false;
        }

        let originalNumbers: string | null = ValidationHelpers
            .stripNonNumericCharacters(originalValue);

        if(!originalNumbers) {
            return false;
        }
        
        return value.indexOf(originalNumbers) >= 0;
    }

    static passesChecksumAlgorithm = (value: string | null) : boolean => {
        if(!value) {
            return false;
        }
        
        let  n: number = 0;
        let i: number = 0;        
        let validChecksum: boolean = false;

        for (i = 0; i < value.length; i += 3) {
            n += ValidationHelpers.charToChecksumDigit(value.charAt(i)) * 3 +
                 ValidationHelpers.charToChecksumDigit(value.charAt(i + 1)) * 7 +
                 ValidationHelpers.charToChecksumDigit(value.charAt(i + 2));
        }

        // If the resulting sum is an even multiple of ten (but not zero),
        // the aba routing number is good.
        if (n !== 0 && n % 10 === 0){
            validChecksum = true;
        }

        return validChecksum;
    }

    static isMasked = (value: string | null | undefined) => {
        if(!value) {
            return true;
        }

        const originalValue: string = value.valueOf();        
        const strippedValue: string | null = ValidationHelpers
            .stripNonNumericCharacters(value);
        
        return strippedValue !== null &&
               strippedValue.length > 0 &&
               originalValue.length !== strippedValue.length;
    }
    
    static stripNonNumericCharacters = (value: string | null | undefined) : string | null => {
        if(!value) {
            return null;
        }

        return value.replace(/\D/g, '');
    }

    private static charToChecksumDigit = (character: string) : number => {
        let result = parseInt(character, 10);
        return result !== Number.NaN
            ? result
            : 0;
    }
};