import { SagaIterator } from "@redux-saga/core";
import ConsentConnectionService from '../../domain/services/ConsentConnectionService';
import CreateConsentConnectionAction from "../actions/CreateConsentConnectionAction";
import GoToPageAction from '../actions/GoToPageAction';
import LoaderAction from '../../redux/actions/LoaderAction';
import { Container } from "inversify";
import { getContext, put, apply, select } from "@redux-saga/core/effects";
import { CONTAINER } from "../../constants/di";
import IConsentConnectionService from "../../domain/services/IConsentConnectionService";
import ActionTypes from "../actions/ActionTypes";
import IConsentConnectionResponseDto from "../../domain/dto/IConsentConnectionResponseDto";
import CreateConsentConnectionSuccessAction from "../actions/CreateConsentConnectionSuccessAction";
import CreateConsentConnectionErrorAction from "../actions/CreateConsentConnectionErrorAction";
import { ServiceUrls } from "../../types/ServiceUrls";
import getServiceUrls from "../selectors/getServiceUrls";
import { IApplicationProps } from '../../types/IApplicationProps';
import getApplicationState from '../selectors/getApplicationState';

export function* createConsentConnectionRedirection(action: CreateConsentConnectionAction): SagaIterator {
    const applicationState: IApplicationProps = yield select(getApplicationState);
    const container: Container = yield getContext(CONTAINER);
    const consentConnectionService = container.get<IConsentConnectionService>(ConsentConnectionService);
    const serviceUrls: ServiceUrls = yield select(getServiceUrls);

    try {
        const consentId: string = action.payload.consentId;
        const response: IConsentConnectionResponseDto =
            yield apply(consentConnectionService, consentConnectionService.createConsentConnection, [serviceUrls.gatewayService, consentId]);
            
        if (response.redirectUrl === null) {
            throw Error();
        }        

        yield put<CreateConsentConnectionSuccessAction>({
            type: ActionTypes.CREATE_CONSENT_ID_CONNECTION_SUCCESS,
            payload: {
                redirectUrl: response.redirectUrl
            }
        });
        
        yield put<LoaderAction>({
            type: ActionTypes.LOADER_EVENT,
            payload: null,
        });

        yield put<GoToPageAction>({
            type: ActionTypes.GO_TO_PAGE,
            payload: {
                url: '/consent',
            },
        });

        if (window.hj && consentId && applicationState.clientId) {
            window.hj(
                'identify',
                applicationState.clientId,
                {
                    consentId: action.payload.consentId
                }
            );
        }
    }
    catch (error) {
        yield put<CreateConsentConnectionErrorAction>({ type: ActionTypes.CREATE_CONSENT_ID_CONNECTION_ERROR, payload: error });
    }
}