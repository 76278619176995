import React, { useEffect } from 'react';
import i18next from 'i18next';
import { IBankProps } from '../../types/IBankProps';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import ProviderTilesGroup from '../../containers/providerSelection/ProviderTilesGroup';
import styles from './providerSelectionPage.module.scss';
import { IProviderSelectionProps } from '../../types/IProviderSelectionProps';
import AccountTypeTabs from '../../containers/providerSelection/AccountTypeTabs';

interface IProps {
    bank: IBankProps,
    providerSelection: IProviderSelectionProps,
    t: i18next.TFunction,
    onClickBack: () => void,
    hasBackButton: boolean,
};

const ProviderSelectionPage: React.FC<IProps> = (props: IProps) => {
    const t = props.t;

    useEffect(() => {
        if (window.hj) {
            window.hj(
                'tagRecording',
                ['Screen: Select Bank Account']
            );
        }
    }, [])

    return (
        <>
            <h1>{t('Select which {{bankName}} account to connect', {bankName: props.bank.name})}</h1>
            <p>{t('Please select the account you\'d like to connect')}:</p>
            <AccountTypeTabs> </AccountTypeTabs>
            <div className={styles.providerSelectionWrapper}>
                <ProviderTilesGroup />
            </div>
            <div data-cy="back_button" className={styles.backButtonWrapper}>
                {props.hasBackButton && <SecondaryButton onClick={props.onClickBack}>{t('Back')}</SecondaryButton>}
            </div>
        </>
    );
};

export default ProviderSelectionPage;