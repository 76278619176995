import React from 'react';
import styles from './error.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../buttons/PrimaryButton';
import ErrorSupportLink from './ErrorSupportLink';
import ErrorCode from './ErrorCode';

interface IProps {
  code?: string
  chooseDifferentBank: () => void
}

const GenericError: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.screen}>
      <div className={styles.topWrapper}>
        <FontAwesomeIcon icon={faExclamationTriangle} className={styles.icon} />
        <h1>{t('Something went wrong')}</h1>
        <div >
          <p>
            {t(`Sorry. We encountered an unexpected error.`)}
            <ErrorCode errorCode={props.code} />
          </p>
          <p>{t(`Please try again. If the problem persists, please contact our`)} <ErrorSupportLink errorCode={props.code} /></p>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <PrimaryButton onClick={props.chooseDifferentBank}>{t('Return to Bank Select')}</PrimaryButton>
      </div>
    </div>
  );
};

export default GenericError;
