import { injectable, inject } from 'inversify';
import IConsentStorage from './IConsentStorage';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import DomainTypes from '../../di/DomainTypes';
import IConsentEndProps from '../../types/IConsentEndProps';

const DID_CONSENT_STORE_KEY = 'did.consent';
const DID_CONSENT_END_KEY = 'consent_end';
const DID_OLD_CONSENT_END_KEY = 'old_consent_end';

@injectable()
export default class ConsentStorage implements IConsentStorage {
    @inject(ThirdPartyTypes.LocalStorage)
    private localStorage!: Storage;

    @inject(DomainTypes.CorrelationId)
    private correlationId!: String;

    setConsentEnd(consentEnd: IConsentEndProps): void {
        this.localStorage.setItem(
            this.getCompositeKey(DID_CONSENT_STORE_KEY, DID_CONSENT_END_KEY),
            consentEnd.consentEnd
        );
        this.localStorage.setItem(
            this.getCompositeKey(DID_CONSENT_STORE_KEY, DID_OLD_CONSENT_END_KEY),
            consentEnd.oldConsentEnd
        );
    }
    hasConsentEnd(): boolean {
        return (
            null !==
            (this.localStorage.getItem(this.getCompositeKey(DID_CONSENT_STORE_KEY, DID_CONSENT_END_KEY)) &&
                this.localStorage.getItem(this.getCompositeKey(DID_CONSENT_STORE_KEY, DID_OLD_CONSENT_END_KEY)))
        );
    }

    getConsentEnd(): IConsentEndProps | null {
        var consentEnd = this.localStorage.getItem(this.getCompositeKey(DID_CONSENT_STORE_KEY, DID_CONSENT_END_KEY));
        var oldConsentEnd = this.localStorage.getItem(
            this.getCompositeKey(DID_CONSENT_STORE_KEY, DID_OLD_CONSENT_END_KEY)
        );

        if (consentEnd && oldConsentEnd) {
            return {
                consentEnd: consentEnd,
                oldConsentEnd: oldConsentEnd,
            };
        }

        return null;
    }

    removeConsentEnd(): void {
        this.localStorage.removeItem(this.getCompositeKey(DID_CONSENT_STORE_KEY, DID_CONSENT_END_KEY));
        this.localStorage.removeItem(this.getCompositeKey(DID_CONSENT_STORE_KEY, DID_OLD_CONSENT_END_KEY));
    }

    private getCompositeKey(primaryKey: string, secondaryKey: string) {
        return `${primaryKey}.${secondaryKey}.${this.correlationId}`;
    }
}
