import { combineReducers } from 'redux';
import loader from './loader';
import initStateReducer from './initState';
import providerSelectedReducer from './providerSelected';
import bankSelectedReducer from './bankSelected';
import { getBanksReducer } from './getBanks';
import getSettingsReducer from "./getSettings";
import getTermsReducer from './getTerms';
import reauthenticateConsentReducer from './reauthenticateConsent';
import countrySelectionReducer from './countrySelection';
import countryListReducer from './countryList';
import connectionSummaryReducer from './connectionSummary';
import errorReducer from './error';
import paymentAccountConfirmationSummaryReducer from './paymentAccountConfirmationSummary';
import providerSelectionReducer from './providerSelection';
import consentEndReducer from './consentEnd';
import serviceUrls from './serviceUrls';

export default combineReducers({
    application: initStateReducer,
    selectedProvider: providerSelectedReducer,
    providerSelection: providerSelectionReducer,
    selectedBank: bankSelectedReducer,
    banks: getBanksReducer,
    consentConfiguration: getTermsReducer,
    consent: reauthenticateConsentReducer,
    error: errorReducer,
    loader: loader,
    countries: countryListReducer,
    selectedCountry: countrySelectionReducer,
    connectionSummary: connectionSummaryReducer,
    paymentAccountConfirmationSummary: paymentAccountConfirmationSummaryReducer,
    consentEnd: consentEndReducer,
    serviceUrls: serviceUrls,
    settings: getSettingsReducer,
});
