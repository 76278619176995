import React from 'react';
import styles from './textInput.module.scss';
import IFormInputProps from './interfaces/IFormInputProps';
import TextInputTypes from './enums/TextInputTypes';

interface IProps extends IFormInputProps {
    value?: string,
    type?: TextInputTypes,
    maxLength?: number,
    pattern?: string,
    placeholder?: string,
    forwardedRef?: React.RefObject<HTMLInputElement>,
    readOnly?:boolean,
}

const TextInput: React.FC<IProps> = (props) => {
    const { onChangeValue, forwardedRef, readOnly, ...passThroughProps } = props;
    const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
        if (evt.currentTarget.validity.valid === true) {
            onChangeValue!(evt);
        }
        else {
            evt.currentTarget.value = '';
        }
    }

    const pattern = passThroughProps.type === TextInputTypes.DIGIT ? "\\d*" : undefined;
    const onInput = passThroughProps.type === TextInputTypes.DIGIT ? handleChange : onChangeValue;

    return (<input
        readOnly={readOnly}
        pattern={pattern}
        ref={props.forwardedRef}
        className={styles.input}
        onChange={onInput}
        {...passThroughProps} />);
}

TextInput.defaultProps = {
    type: TextInputTypes.TEXT,
};

export default TextInput;