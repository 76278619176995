import React from 'react';
import { IApplicationState } from '../../types/applicationState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import CountryDropdown from '../../components/bankSelect/CountryDropdown';
import ActionTypes from '../../redux/actions/ActionTypes';

interface IDispatchToProps {
    onCountryCodeChange: (event: React.FormEvent<HTMLSelectElement>) => void;
};

interface IStateToProps {
    countryCodes: string[];
    selectedCountryCode: string;
};

interface IProps extends IStateToProps, IDispatchToProps { };

const CountryDropdownContainer: React.FC<IProps> = (props: IProps) => {
    return (<CountryDropdown {...props} />);
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    countryCodes: state.countries,
    selectedCountryCode: state.selectedCountry,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onCountryCodeChange: (event: React.FormEvent<HTMLSelectElement>) => dispatch({
        type: ActionTypes.SELECT_COUNTRY_CODE,
        payload: {
            countryCode: event.currentTarget.value,
        },
    }),
});

export { CountryDropdownContainer };
export default connect<IStateToProps, IDispatchToProps, any, IApplicationState>(mapStateToProps, mapDispatchToProps)(CountryDropdownContainer);