import { put, apply, getContext, select } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import ActionTypes from '../actions/ActionTypes';
import { GetBanksAction } from '../actions/BankSelectAction';
import { Container } from 'inversify';
import IBankSelectService from "../../domain/services/IBankSelectService";
import BankSelectService from "../../domain/services/BankSelectService";
import getApplicationState from '../selectors/getApplicationState';
import { IApplicationProps } from '../../types/IApplicationProps';
import { IBankDto } from '../../domain/dto/IBankDto';
import getBankSearchQuery from '../selectors/getBankSearchQuery';
import getSelectedCountryCode from '../selectors/getSelectedCountryCode';
import LoaderAction from '../actions/LoaderAction';
import getLoaderState from '../selectors/getLoader';
import ILoaderProps from '../../types/ILoaderProps';
import SelectCountryCodeAction from '../actions/SelectCountryCodeAction';
import getServiceUrls from '../selectors/getServiceUrls';
import { ServiceUrls } from '../../types/ServiceUrls';
import { ExitApplicationToggleAction } from '../actions/ExitApplicationAction';

export default function* getBanks(action: GetBanksAction | SelectCountryCodeAction): SagaIterator {
    if (action.type === ActionTypes.SELECT_COUNTRY_CODE && action.payload.skipFetchingBanks) {
        return;
    }

    const container: Container = yield getContext('container');
    const bankSelectApi = container.get<IBankSelectService>(BankSelectService);
    const applicationState: IApplicationProps = yield select(getApplicationState);
    const selectedCountryCode: string | undefined = yield select(getSelectedCountryCode);
    const query: string = yield select(getBankSearchQuery);
    const serviceUrls: ServiceUrls = yield select(getServiceUrls);

    try {
        let response: IBankDto[];
        if (applicationState.hideExitButton) {
            applicationState.hideExitButton = false;
            yield put<ExitApplicationToggleAction>({
                type: ActionTypes.EXIT_APPLICATION_TOGGLE,
                payload: applicationState
            });
        }

        // if query is empty fetch favourite banks, otherwise perform search
        if (!query || query === '') {
            response = yield apply(bankSelectApi, bankSelectApi.getFavouriteBanks, [
                serviceUrls.providerSearchService,
                applicationState.clientId,
                applicationState.configurationId,
                selectedCountryCode,
            ]);
        } else {
            if (window.hj) {
                window.hj(
                    'tagRecording',
                    ['Searched']
                );
            }

            response = yield apply(bankSelectApi, bankSelectApi.getBanks, [
                serviceUrls.providerSearchService,
                applicationState.clientId,
                applicationState.configurationId,
                { query },
                selectedCountryCode,
            ]);
        }

        const loaderState: ILoaderProps = yield select(getLoaderState);
        if (loaderState.display) {
            yield put<LoaderAction>({
                type: ActionTypes.LOADER_EVENT,
                payload: null,
            });
        }

        if (window.hj && query) {
            if (response.length) {
                window.hj(
                    'tagRecording',
                    ['Search Results Listed']
                );
            } else if (response.length === 0) {
                window.hj(
                    'tagRecording',
                    ['Search Results None']
                );
            }
        }

        yield put({ type: ActionTypes.GET_BANKS_SUCCESS, payload: response });
    } catch (err) {
        yield put({ type: ActionTypes.GET_BANKS_ERROR, payload: err });
    }
}
