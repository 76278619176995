import { SagaIterator } from '@redux-saga/core';
import { getContext, apply, put, all, select } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import { CONTAINER } from '../../constants/di';
import PreSelectProviderChoiceAction, { ProviderPreSelectErrorAction } from '../actions/PreSelectProviderChoiceAction';
import IProviderService from '../../domain/services/IProviderService';
import ProviderService from '../../domain/services/ProviderService';
import CompleteProviderChoiceAction from '../actions/CompleteProviderChoiceAction';
import ActionTypes from '../actions/ActionTypes';
import UpdateBankChoiceAction from '../actions/UpdateBankChoiceAction';
import { IBankDto } from '../../domain/dto/IBankDto';
import { SimpleApplicationAction } from '../actions/ApplicationAction';
import getServiceUrls from '../selectors/getServiceUrls';
import { ServiceUrls } from '../../types/ServiceUrls';

export default function* handleProviderPreSelection(action: PreSelectProviderChoiceAction): SagaIterator {
    const container: Container = yield getContext(CONTAINER);
    const providerService = container.get<IProviderService>(ProviderService);
    const serviceUrls: ServiceUrls = yield select(getServiceUrls);

    try {
        const bank: IBankDto = yield apply(providerService, providerService.getBank, [serviceUrls.providerSearchService, action.payload.providerId]);

        if (!bank.name) {
            throw Error();
        }

        yield all([
            put<UpdateBankChoiceAction>({
                type: ActionTypes.UPDATE_BANK_SELECTION,
                payload: {
                    bank: {
                        ...bank,
                        providers: bank.providers.map((provider) => ({
                            providerId: provider.providerId,
                            name: provider.name,
                            maxConsentDays: provider.maxConsentDays,
                            dataSource: provider.dataSource,
                            hasDisplayName: false,
                            supportedAccounts: { Business: [], Personal: [] },
                        })),
                    },
                },
            }),
            put<CompleteProviderChoiceAction>({
                type: ActionTypes.COMPLETE_PROVIDER_SELECTION,
                payload: { providerId: action.payload.providerId },
            }),
        ]);

        yield put<SimpleApplicationAction>({
            type: ActionTypes.PROVIDER_PRESELECTION_SUCCESS,
        });
    } catch (error) {
        yield put<ProviderPreSelectErrorAction>({
            type: ActionTypes.PROVIDER_PRESELECTION_ERROR,
            payload: error,
        });
    }
}
