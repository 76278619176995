import { Reducer } from 'redux';
import ILoaderProps from '../../types/ILoaderProps';
import ActionTypes from '../actions/ActionTypes';
import LoaderAction from '../actions/LoaderAction';

export const initialState: ILoaderProps = {
    display: true,
    showRecaptchaDisclaimer: false,
    spinnerText: "Loading DirectID",
};

const resetState: ILoaderProps = {
    display: false,
    showRecaptchaDisclaimer: false,
    logoUrl: undefined,
    spinnerText: undefined,
};

const loader: Reducer<ILoaderProps, LoaderAction> = (state = initialState, action: LoaderAction) => {
    if (action.type !== ActionTypes.LOADER_EVENT) {
        return state;
    }

    if (!action.payload) {
        return {...resetState};
    }

    return {...state, ...action.payload};
};

export default loader



