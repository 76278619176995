import React from 'react';
import styles from './footer.module.scss';
import FooterLink from "../links/FooterLink";
import { useTranslation } from 'react-i18next';

interface IProps {
    className?: string,
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
}

const Footer: React.FC<IProps> = () => {
    const { t } = useTranslation();

    return (
      <footer className={styles.footer}>
        <div className={styles.footerDid}>
          DirectID &copy; {new Date().getFullYear()}
        </div>
        <div className={styles.links}>
          <FooterLink
            href='https://www.direct.id/resources/frequently-asked-questions'
            target='_blank'
          >
            {t('FAQs')}
          </FooterLink>
          <FooterLink href='https://www.direct.id/policies' target='_blank'>
            {t('Policies')}
          </FooterLink>
        </div>
      </footer>
    );
};

export default Footer;