import { IApplicationState } from "../../types/applicationState";
import IPaymentAccountConfirmationSummaryProps from "../../types/IPaymentAccountConfirmationSummaryProps";

export default function getPaymentAccountConfirmationSummary(state: IApplicationState): IPaymentAccountConfirmationSummaryProps | undefined {
    if (!state) {
        throw Error();
    }

    return state.paymentAccountConfirmationSummary;
};
