import { IApplicationState } from '../../types/applicationState';
import IConsentProps from '../../types/IConsentProps';

export default function getConsent(state: IApplicationState): IConsentProps | undefined {
    if (!state) {
        throw Error();
    }

    return state.consent;
}
