enum ErrorScreenTypeEnum {
    LoadingError = 'Loading',
    GenericError = 'Generic',
    ProviderUnavailableError = 'Provider',
    DataSharingCancelledError = 'DataSharing',
    ReAuthenticateCancelledError = 'ReAuthenticate',
    PaymentAccountConfirmationCancelledError = 'PaymentAccountConfirmationCancelled',
    PaymentAccountConfirmationError = 'PaymentAccountConfirmation',
}

export default ErrorScreenTypeEnum;
