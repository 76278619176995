import qs from 'qs';
import { injectable } from 'inversify';
import IAccessTokenInitializer from './IAccessTokenInitializer';

@injectable()
export default class AccessTokenInitializer implements IAccessTokenInitializer {
    public init(): string | null {
        var fragmentParameters = qs.parse(window.location.hash.slice(1), {
            decoder: decodeURIComponent,
        });

        var accessToken: string | null = ((hash) => (hash.access_token ? String(hash.access_token) : null))(
            fragmentParameters
        );

        return accessToken;
    }
}
