import { AxiosInstance } from 'axios';
import { ISearchBankActionPayload } from '../../types/ISearchBankActionPayload';
import { injectable, inject } from 'inversify';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import IBankSelectService from './IBankSelectService';
import { IBankDto } from '../dto/IBankDto';
import { ProviderServiceEndpoints } from '../../constants/apiRoutes';
import { CUSTOMER_APP_ID_HEADER, CONFIGURATION_NAME_HEADER } from '../../constants/constants';

@injectable()
export default class BankSelectService implements IBankSelectService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getFavouriteBanks(serviceUrl: string, clientId: string, configurationName: string | undefined, countryCode: string | undefined): Promise<IBankDto[]> {
        const url = this.addCountryCode(            
            ProviderServiceEndpoints.getFavouriteBanks(serviceUrl),
            countryCode
        );
        const headers = this.getHeaders(clientId, configurationName);

        return (await this.apiClient.get(url, {
            headers: headers,
        })).data;
    };

    public async getBanks(serviceUrl: string, clientId: string, configurationName: string | undefined, data: ISearchBankActionPayload, countryCode: string | undefined): Promise<IBankDto[]> {
        const url = this.addCountryCode(
            `${ProviderServiceEndpoints.searchBanks(serviceUrl)}/${data.query}`,
            countryCode
        );
        const headers = this.getHeaders(clientId, configurationName);
        
        return (await this.apiClient.get(url, {
            headers: headers,
        })).data;
    };

    private addCountryCode(url: string, countryCode: string | undefined): string {
        if (!countryCode) {
            return url;
        }

        const link = url.includes('?') ? '&' : '?';

        return `${url}${link}countryCode=${countryCode}`;
    }

    private getHeaders(clientId: string, configurationId: string | undefined): {[key: string]: string} {
        const headers: {[key: string]: string} = {
            [CUSTOMER_APP_ID_HEADER]: clientId,
        };

        if (configurationId) {
            headers[CONFIGURATION_NAME_HEADER] = configurationId;
        }

        return headers;
    }
}
