import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import styles from './contentLayout.module.scss';
import { DEFAULT_BACKGROUND_COLOR } from "../../constants/styling";
import { IApplicationState } from "../../types/applicationState";
import { ISettingsDto } from "../../domain/dto/ISettingsDto";
import { connect } from "react-redux";
import QuitButton from '../buttons/QuitButton';
import { ExitApplicationAction } from '../../redux/actions/ExitApplicationAction';
import ActionTypes from '../../redux/actions/ActionTypes';
import { Dispatch } from 'redux';

interface IStateToProps {
    settings: ISettingsDto,
}

interface IDispatchToProps {
    onExitApplication: () => void;
}

interface IProps extends IStateToProps, IDispatchToProps { }

const ContentLayout: React.FC<IProps> = ({ children, settings, onExitApplication }) => {
    const [backgroundColor, setBackgroundColor] = useState<string>();
    const displayQuitButton: boolean = window.opener || settings?.redirectUrl;

    useEffect(() => {
        settings?.customColors?.backgroundColor ?
            setBackgroundColor(settings.customColors.backgroundColor) :
            setBackgroundColor(DEFAULT_BACKGROUND_COLOR);
    }, [settings]);

    return (
        <div className={styles.pageContainer} style={{ backgroundColor }}>
            <Header />
            <div className={styles.outerContainer}>
                <div className={styles.innerContent}>
                    {children}
                </div>
                {
                    displayQuitButton &&
                    <QuitButton backgroundColor={backgroundColor}
                        exitApplication={onExitApplication}
                    ></QuitButton>
                }
            </div>
            <Footer />
        </div>
    );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    settings: state.settings,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
    onExitApplication: () =>
        dispatch<ExitApplicationAction>({
            type: ActionTypes.EXIT_APPLICATION,
        }),
});

export default connect<IStateToProps, IDispatchToProps, any, IApplicationState>(mapStateToProps, mapDispatchToProps)(ContentLayout);
