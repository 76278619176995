import React from 'react';

interface IProps {
  errorCode?: string
}

const ErrorCode: React.FC<IProps> = ({errorCode}: IProps) => {
  return (
    <>{`${errorCode? ` (${errorCode})` : ''}`}</>
  );
};

export default ErrorCode;