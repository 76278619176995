import { Container, BindingScopeEnum } from 'inversify';
import StateInitializer from '../utils/StateInitializer';
import IStateInitializer from '../utils/IStateInitializer';
import BankSelectService from '../domain/services/BankSelectService';
import IBankSelectService from '../domain/services/IBankSelectService';
import { Base64 as Base64Instance, Base64_ as Base64 } from 'js-base64';
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import IConfigurationService from '../domain/services/IConfigurationService';
import ConfigurationService from '../domain/services/ConfigurationService';
import ThirdPartyTypes from './ThirdPartyTypes';
import DomainTypes from './DomainTypes';
import IConsentConnectionService from '../domain/services/IConsentConnectionService';
import ConsentConnectionService from '../domain/services/ConsentConnectionService';
import IConsentService from '../domain/services/IConsentService';
import ConsentService from '../domain/services/ConsentService';
import IRecaptchaService from '../domain/services/IRecaptchaService';
import RecaptchaService from '../domain/services/RecaptchaService';
import { RefObject } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import IProviderService from '../domain/services/IProviderService';
import ProviderService from '../domain/services/ProviderService';
import IAccessTokenInitializer from '../utils/IAccessTokenInitializer';
import AccessTokenInitializer from '../utils/AccessTokenInitializer';
import CallbackTokenScopeInitializer from '../utils/CallbackTokenScopeInitializer';
import ICallbackTokenScopeInitializer from '../utils/ICallbackTokenScopeInitializer';
import IValidationTokenInitializer from '../utils/IValidationTokenInitializer';
import ValidationTokenInitializer from '../utils/ValidationTokenInitializer';
import IAccessTokenStorage from '../domain/services/IAccessTokenStorage';
import AccessTokenStorage from '../domain/services/AccessTokenStorage';
import IValidationTokenStorage from '../domain/services/IValidationTokenStorage';
import ValidationTokenStorage from '../domain/services/ValidationTokenStorage';
import IConnectionSummaryService from '../domain/services/IConnectionSummaryService';
import ConnectionSummaryService from '../domain/services/ConnectionSummaryService';
import IPaymentAccountConfirmationService from '../domain/services/IPaymentAccountConfirmationService';
import PaymentAccountConfirmationService from '../domain/services/PaymentAccountConfirmationService';
import IDateTimeSource from '../utils/IDateTimeSource';
import DateTimeSource from '../utils/DateTimeSource';
import IConsentStorage from '../domain/services/IConsentStorage';
import ConsentStorage from '../domain/services/ConsentStorage';
import ITokenDecoder from '../utils/ITokenDecoder';
import TokenDecoder from '../utils/TokenDecoder';
import IConfigurationStorageService from "../domain/services/IConfigurationStorageService";
import ConfigurationStorageService from "../domain/services/ConfigurationStorageService";

export default function configureContainer(recaptchaRef: RefObject<ReCAPTCHA>, correlationId: String): Container {
    const container = new Container({ defaultScope: BindingScopeEnum.Singleton });

    container.bind<Base64>(ThirdPartyTypes.Base64).toConstantValue(Base64Instance);
    container.bind<AxiosInstance>(ThirdPartyTypes.Axios).toFactory(() => {
        const axios = Axios.create();

        axios.interceptors.request.use((config: AxiosRequestConfig) => {
            config.headers['Request-Id'] = correlationId;

            return config;
        });

        return axios;
    });
    container.bind<ApplicationInsights>(ThirdPartyTypes.ApplicationInsights).toDynamicValue(() => {
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: process.env.APP_INSIGHTS_KEY || '',
            },
        });

        appInsights.loadAppInsights();

        return appInsights;
    });

    container.bind<Storage>(ThirdPartyTypes.SessionStorage).toConstantValue(sessionStorage);
    container.bind<Storage>(ThirdPartyTypes.LocalStorage).toConstantValue(localStorage);
    container.bind<String>(DomainTypes.CorrelationId).toConstantValue(correlationId);
    container.bind<IAccessTokenInitializer>(AccessTokenInitializer).toSelf();
    container.bind<ICallbackTokenScopeInitializer>(CallbackTokenScopeInitializer).toSelf();
    container.bind<IValidationTokenInitializer>(ValidationTokenInitializer).toSelf();
    container.bind<IAccessTokenStorage>(AccessTokenStorage).toSelf();
    container.bind<IValidationTokenStorage>(ValidationTokenStorage).toSelf();
    container.bind<IStateInitializer>(StateInitializer).toSelf();
    container.bind<IBankSelectService>(BankSelectService).toSelf();
    container.bind<IProviderService>(ProviderService).toSelf();
    container.bind<IConsentConnectionService>(ConsentConnectionService).toSelf();
    container.bind<IConfigurationService>(ConfigurationService).toSelf();
    container.bind<IConsentService>(ConsentService).toSelf();
    container.bind<IConnectionSummaryService>(ConnectionSummaryService).toSelf();
    container.bind<IPaymentAccountConfirmationService>(PaymentAccountConfirmationService).toSelf();
    container.bind<IRecaptchaService>(RecaptchaService).toDynamicValue(() => new RecaptchaService(recaptchaRef));
    container.bind<IDateTimeSource>(DateTimeSource).toSelf();
    container.bind<IConsentStorage>(ConsentStorage).toSelf();
    container.bind<ITokenDecoder>(TokenDecoder).toSelf();
    container.bind<IConfigurationStorageService>(ConfigurationStorageService).toSelf();

    return container;
}
