import React from 'react';
import { connect } from 'react-redux';
import Accordion from '../../components/accordion/Accordion';
import PermissionCluster from '../../domain/enums/PermissionCluster';
import i18next from 'i18next';
import { IApplicationState } from '../../types/applicationState';
import PermissionClusterDescriptions from '../../constants/PermissionClustersDescriptions';

interface IBasicProps {
    t: i18next.TFunction,
    replacementTokens: {[key: string]: any}
};

interface IStateToProps {
    permissionClusters: PermissionCluster[]
};

type IProps = IStateToProps & IBasicProps;

const PermissionClustersAccordion: React.FC<IProps> = (props: IProps) => {
    const t = props.t;

    const items = [];
    const defaultPermission = PermissionClusterDescriptions.get(PermissionCluster.AccountDetails);
    if (defaultPermission) {
        items.push({
                title: t(defaultPermission.title),
                content: defaultPermission.content.map((contentPart) => t(contentPart))
        });
    }

    for (const permissionCluster of props.permissionClusters) {
        const desc = PermissionClusterDescriptions.get(permissionCluster);

        if (desc) {
            items.push({
                title: t(desc.title),
                content: desc.content.map((contentPart) => t(contentPart, props.replacementTokens))
            });
        }
    }

    return <Accordion items={items} />
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
    permissionClusters: state.consentConfiguration ? state.consentConfiguration.permissionClusters : [],
});

export { PermissionClustersAccordion }
export default connect<IStateToProps, null, IBasicProps, IApplicationState>(mapStateToProps)(PermissionClustersAccordion);