import { useTranslation } from 'react-i18next';
import IUserFeedbackFields from './IUserFeedbackFields'
import PaymentConfirmationValidationErrorEnum from '../../../types/enums/PaymentConfirmationValidationErrorEnum';

const BankCodeUserFeedbackFields = (countryCode: string, type: PaymentConfirmationValidationErrorEnum | null): IUserFeedbackFields => {
    const { t } = useTranslation();

    const doesNotMatchBank: Map<string, string> = new Map([
        ['US', t("Please make sure you have entered a valid routing number")],
        ['CA', t("Please make sure you have entered a valid routing number")]]);
    const doesNotMatchBankDefault = t("Please make sure you have entered a valid bank code")
    
    const formatInvalid: Map<string, string> = new Map([
        ['US', t("Routing number must be 9-digits and be a valid routing number")],
        ['CA', t('Routing number must be either 8-digits long or, 9-digits starting with a 0')]]);
    const formatInvalidDefault = t('Bank code is not valid')

    const placeHolder: Map<string, string> = new Map([
        ['US', t("Please confirm your routing number")],
        ['CA', t("Please confirm your routing number")]]);
    const placeHolderDefault = t("Please confirm your bank code")

    const label: Map<string, string> = new Map([
        ['US', t("Routing number")],
        ['CA', t("Routing number")]]);
    const labelDefault = t("Bank code")
  
    const getFeedbackError = (countryCode: string, type: PaymentConfirmationValidationErrorEnum | null): string | undefined => {
        
        if (type === null) {
            return undefined;
        }
        
        switch (type) {
            case PaymentConfirmationValidationErrorEnum.BANK_CODE_DOES_NOT_MATCH_BANK:
                return doesNotMatchBank.has(countryCode) ? doesNotMatchBank.get(countryCode) : doesNotMatchBankDefault;
            case PaymentConfirmationValidationErrorEnum.BANK_CODE_FORMAT_INVALID:
            default:
                return formatInvalid.has(countryCode) ? formatInvalid.get(countryCode) : formatInvalidDefault;
        }
    };

    const getPlaceHolder = (countryCode: string): string => {
        return placeHolder.has(countryCode) ? placeHolder.get(countryCode)! : placeHolderDefault;
    };

    const getLabel = (countryCode: string): string => {
        return label.has(countryCode) ? label.get(countryCode)! : labelDefault;
    };

    return {
        label: getLabel(countryCode),
        placeHolder: getPlaceHolder(countryCode),
        validationError: getFeedbackError(countryCode, type)
    };
}

export default BankCodeUserFeedbackFields;