import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import { ProviderServiceEndpoints } from '../../constants/apiRoutes';
import IProviderService from './IProviderService';
import { IBankDto } from '../dto/IBankDto';
import { ICountryListDto } from '../dto/ICountryListDto';
import { CUSTOMER_APP_ID_HEADER, CONFIGURATION_NAME_HEADER } from '../../constants/constants';

interface ICountryCodeResponse {
    code: string;
}

interface ICountryCodesResponse {
    defaultCountry: string;
    countries: ICountryCodeResponse[],
}

@injectable()
export default class ProviderService implements IProviderService {
    @inject(ThirdPartyTypes.Axios)
    private apiClient!: AxiosInstance;

    public async getBank(url: string, providerId: number): Promise<IBankDto> {        
        return (await this.apiClient.get(ProviderServiceEndpoints.getBank(url, providerId))).data;
    };

    public async getBanks(url: string, bankCode: string, countryCode: string): Promise<IBankDto[]> {
        return (await this.apiClient.get(ProviderServiceEndpoints.getBanks(url, bankCode), {
            params: {
                countryCode: countryCode
            }
        })).data;
    };

    public async getCountryCodesList(url: string, clientId: string, configurationName?: string): Promise<ICountryListDto> {
        const headers: any = {
            [CUSTOMER_APP_ID_HEADER]: clientId,
          };
      
          if (configurationName) {
            headers[CONFIGURATION_NAME_HEADER] = configurationName;
          }
        
        const data = (await this.apiClient
            .get<ICountryCodesResponse>(
                ProviderServiceEndpoints.countryCodes(url),
                { headers })
        ).data;

        return {
            defaultCountry: data.defaultCountry,
            countries: data.countries.map(c => c.code),
        };
    }
}
