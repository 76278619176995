import { injectable, inject } from 'inversify';
import IValidationTokenStorage from './IValidationTokenStorage';
import ThirdPartyTypes from '../../di/ThirdPartyTypes';
import DomainTypes from '../../di/DomainTypes';

const DID_VALIDATION_TOKEN_STORE_KEY = 'did.validation_token';

@injectable()
export default class ValidationTokenStorage implements IValidationTokenStorage {

    @inject(ThirdPartyTypes.LocalStorage)
    private localStorage!: Storage;

    @inject(DomainTypes.CorrelationId)
    private correlationId!: String;

    setValidationToken(validationToken: string): void {
        this.localStorage.setItem(
            this.getCompositeKey(DID_VALIDATION_TOKEN_STORE_KEY),
            validationToken);
    }
    
    hasValidationToken(): boolean {
        return null !== this.localStorage.getItem(
            this.getCompositeKey(DID_VALIDATION_TOKEN_STORE_KEY));
    }

    getValidationToken(){
        return this.localStorage.getItem(
            this.getCompositeKey(DID_VALIDATION_TOKEN_STORE_KEY));
    }
    removeValidationToken(){
        this.localStorage.removeItem(this.getCompositeKey(DID_VALIDATION_TOKEN_STORE_KEY));
    }

    private getCompositeKey(primaryKey: string) {
        return `${primaryKey}.${this.correlationId}`;
    }
}