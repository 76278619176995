import IRecaptchaService from './IRecaptchaService';
import { RefObject } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default class RecaptchaService implements IRecaptchaService {
    private readonly reference: RefObject<ReCAPTCHA>;

    public constructor(reference: RefObject<ReCAPTCHA>) {
        this.reference = reference;
    }

    trigger(): void {
        if (!this.reference.current) {
            throw Error('Reference does not have an element associated with it.');
        }

        this.reference.current.execute();
    }

    reset(): void {
        if (!this.reference.current) {
            throw Error('Reference does not have an element associated with it.');
        }

        this.reference.current.reset();
    }
}
