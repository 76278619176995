import { SagaIterator } from '@redux-saga/core';
import { apply, getContext, put } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import AccessTokenInitializer from '../../utils/AccessTokenInitializer';
import IAccessTokenInitializer from '../../utils/IAccessTokenInitializer';
import { SimpleApplicationAction } from '../actions/ApplicationAction';
import IAccessTokenStorage from '../../domain/services/IAccessTokenStorage';
import AccessTokenStorage from '../../domain/services/AccessTokenStorage';
import ActionTypes from '../actions/ActionTypes';
import IAuthTokenProps from '../../types/IAuthTokenProps';
import ITokenDecoder from '../../utils/ITokenDecoder'
import TokenDecoder from '../../utils/TokenDecoder'
import InitAccessTokenDecodeAction from '../actions/initAccessTokenDecodeAction';

export function* initAccessTokenFromFragment(_: SimpleApplicationAction): SagaIterator {
    const container: Container = yield getContext('container');
    const accessTokenInitializer: IAccessTokenInitializer = container
        .get<IAccessTokenInitializer>(AccessTokenInitializer);
    const accessTokenStorage: IAccessTokenStorage = container
        .get<IAccessTokenStorage>(AccessTokenStorage);
    const tokenDecoder: ITokenDecoder = container
    .get<ITokenDecoder>(TokenDecoder);

    const accessToken = (yield apply(accessTokenInitializer, accessTokenInitializer.init, []));

    if (accessToken) {
        yield apply(accessTokenStorage, accessTokenStorage.setToken, [accessToken]);
        let decodedToken: IAuthTokenProps = tokenDecoder.Decode(accessToken);
        if(decodedToken.appid !== undefined){        
            yield put<InitAccessTokenDecodeAction>({
                type: ActionTypes.INIT_ACCESS_TOKEN_DECODE,
                payload: decodedToken.appid
            });
        }
    }

    yield put<SimpleApplicationAction>({
        type: ActionTypes.INIT_ACCESS_TOKEN_COMPLETE,
    });
}