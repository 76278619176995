import AccountNumberValidator from '../../utils/validators/accountNumberValidator';
import BankCodeValidator from '../../utils/validators/bankCodeValidator';

import ActionTypes from '../actions/ActionTypes';
import { ErrorAction } from '../actions/ApplicationAction';
import AccountNumberValidationCompleteAction from '../actions/AccountNumberValidationCompleteAction';
import BankCodeValidationCompleteAction from '../actions/BankCodeValidationCompleteAction';
import { put, select } from '@redux-saga/core/effects';
import getPaymentAccountConfirmationSummary from '../selectors/getPaymentAccountConfirmationSummary';

import ValidateAccountNumberAction from '../actions/ValidateAccountNumberAction';
import ValidateBankCodeAction from '../actions/ValidateBankCodeAction';
import IPaymentAccountConfirmationSummaryProps from '../../types/IPaymentAccountConfirmationSummaryProps';
import IPaymentConfirmationAccountDataProps from '../../types/IPaymentConfirmationAccountDataProps';
import IFormFieldValue from '../../types/IFormFieldValue';

export function* handleAccountNumberFormFieldValidation(action: ValidateAccountNumberAction) {
    try {
        var summary : IPaymentAccountConfirmationSummaryProps = yield select(getPaymentAccountConfirmationSummary);

        const selectedAccount = getSelectedAccount(action.payload.accountId, summary.accounts);

        let validationResult : IFormFieldValue = 
            AccountNumberValidator
                .isValidAccountNumber(
                    action.payload.accountNumber,
                    selectedAccount.accountNumber);

        selectedAccount.accountNumberFormFieldValue = validationResult;

        yield put<AccountNumberValidationCompleteAction>({
            type: ActionTypes.ACCOUNT_NUMBER_VALIDATION_COMPLETE,
            payload: summary,
        });

    } catch (error) {
        yield put<ErrorAction>({
            type: ActionTypes.VALIDATION_ERROR,
            payload: error,
        });
    }
}

export function* handleBankCodeFormFieldValidation(action: ValidateBankCodeAction) {

    try {
        var summary : IPaymentAccountConfirmationSummaryProps = yield select(getPaymentAccountConfirmationSummary);

        const selectedAccount = getSelectedAccount(action.payload.accountId, summary.accounts);

        let validationResult : IFormFieldValue = 
        BankCodeValidator
            .isValidBankCode(
                action.payload.bankCode,
                summary.countryCode,
                selectedAccount.bankCode);

        selectedAccount.bankCodeFormFieldValue = validationResult;

        yield put<BankCodeValidationCompleteAction>({
            type: ActionTypes.BANK_CODE_VALIDATION_COMPLETE,
            payload: summary,
        });

    } catch (error) {
        yield put<ErrorAction>({
            type: ActionTypes.VALIDATION_ERROR,
            payload: error,
        });
    }
}

const getSelectedAccount = (accountId: string,  accounts: IPaymentConfirmationAccountDataProps[]) => {
    var selectedAccount = accounts.find(acc => {
        return acc.accountId.valueOf() === accountId;
    });

    if(selectedAccount === undefined) {
        throw new Error(`no account with Id ${accountId} was found`);
    }

    return selectedAccount;
}