import RecaptchaUpdateAction from '../actions/RecaptchaUpdateAction';
import { SagaIterator } from '@redux-saga/core';
import { put, getContext, apply } from '@redux-saga/core/effects';
import ActionTypes from '../actions/ActionTypes';
import ValidationTokenUpdateAction from '../actions/ValidationTokenUpdateAction';
import ConsentAcceptedAction from '../actions/ConsentAcceptedAction';
import { Container } from 'inversify';
import IRecaptchaService from '../../domain/services/IRecaptchaService';
import RecaptchaService from '../../domain/services/RecaptchaService';

export default function* handleRecaptchaUpdate(action: RecaptchaUpdateAction): SagaIterator {
    const container: Container = yield getContext('container');
    const recaptchaService: IRecaptchaService = container.get<IRecaptchaService>(RecaptchaService);

    // re-trigger ReCAPTCHA on failure
    if (!action.payload) {
        yield put<ConsentAcceptedAction>({
            type: ActionTypes.CONSENT_ACCEPTED,
        });

        return;
    }

    yield apply(recaptchaService, recaptchaService.reset, []);

    yield put<ValidationTokenUpdateAction>({
        type: ActionTypes.VALIDATION_TOKEN_UPDATE,
        payload: { validationToken: action.payload },
    });
}
