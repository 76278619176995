import { Reducer } from 'redux';
import IErrorProps from '../../types/IErrorProps';
import ActionTypes from '../actions/ActionTypes';
import ErrorScreenType from '../../types/enums/errorScreenTypeEnum';
import {
    isErrorAction,
    getErrorCodeFromResponse,
    selectRedirectionErrorScreenType,
} from '../../utils/ErrorHandlingHelpers';

const initialState: IErrorProps = {
    type: ErrorScreenType.GenericError,
};

const errorReducer: Reducer<IErrorProps> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.REDIRECTION_ERROR:
            return {
                type: selectRedirectionErrorScreenType(action.payload),
                code: action.payload,
            };
        case ActionTypes.INIT_STATE_ERROR:
            return { type: ErrorScreenType.LoadingError, code: 'parameters_missing' };
        case ActionTypes.GET_COUNTRY_CODES_ERROR:
            const code = getErrorCodeFromResponse(action);
            return {
                type: code === 'configuration_invalid' ? ErrorScreenType.LoadingError : ErrorScreenType.GenericError,
                code: code ? code : 'load_countries_failed',
            };
        case ActionTypes.COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_ERROR:
        case ActionTypes.GET_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_ERROR:
            return { type: ErrorScreenType.PaymentAccountConfirmationError, code: 'unexpected_error' };
        case ActionTypes.COMPLETE_PAYMENT_ACCOUNT_CONFIRMATION_SUMMARY_CANCELLED_ERROR:
            return { type: ErrorScreenType.PaymentAccountConfirmationCancelledError, code: 'customer_cancelled' };
        case ActionTypes.INIT_REAUTHENTICATE_STAGE_ERROR:
            return { type: ErrorScreenType.ReAuthenticateCancelledError, code: action.payload };
        default:
            if (isErrorAction(action)) {
                return { type: ErrorScreenType.GenericError, code: getErrorCodeFromResponse(action) };
            }
            return state;
    }
};

export default errorReducer;
