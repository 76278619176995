import {AnyAction} from 'redux';
import RedirectionErrorTypeEnum from '../types/enums/redirectionErrorTypeEnum';
import ErrorScreenTypeEnum from '../types/enums/errorScreenTypeEnum';

export function getErrorCodeFromResponse(action: AnyAction) {
  if(action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.code) {
    return action.payload.response.data.code;
  }

  return undefined;
}

export function isErrorAction(action: AnyAction) {
  return action.type.endsWith('ERROR');
}

export function selectRedirectionErrorScreenType(redirectionErrorType: RedirectionErrorTypeEnum): ErrorScreenTypeEnum {
  switch(redirectionErrorType) {
    case RedirectionErrorTypeEnum.CustomerCancelled:
      return ErrorScreenTypeEnum.DataSharingCancelledError;
    case  RedirectionErrorTypeEnum.ProviderTemporarilyUnavailable:
      return ErrorScreenTypeEnum.ProviderUnavailableError;
    default:
      return ErrorScreenTypeEnum.GenericError;
  }
}