import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../types/applicationState';
import ActionTypes from '../../redux/actions/ActionTypes';
import ConsentPageContent from '../../components/consent/ConsentPageContent';
import GoBackAction from '../../redux/actions/GoBackAction';
import CreateConsentSuccessButtonAction from '../../redux/actions/CreateConsentSuccessButtonAction';
import IConsentConfigurationProps from '../../types/IConsentConfigurationProps';
import { IApplicationProps } from '../../types/IApplicationProps';
import { IBankProps } from '../../types/IBankProps';
import { IProviderSelectionProps } from '../../types/IProviderSelectionProps';
import ConsentStatus from '../../domain/enums/ConsentStatus';
import getProviderSelected from '../../redux/selectors/getProviderSelected';

interface IBasicProps {}

interface IStateToProps {
  terms: string;
  bankLogoUrl?: string;
  isLoaderEnabled: Boolean;
  replacementTokens: { [key: string]: any };
  application: IApplicationProps;
  selectedBank: IBankProps;
  oldConsent?: Date;
  consentStatus?: ConsentStatus;
  redirectUrl?: string;
  isImplicit?: boolean;
  providerSelection: IProviderSelectionProps;
  showYapilyDisclaimer: boolean;
  disableDefaultDisclaimer?: boolean;
}

interface IDispatchToProps {
  onClickBack: () => void;
  onCreateConsentSuccess: (consentId: string | undefined) => void;
}

type IProps = IStateToProps &
  IBasicProps &
  IDispatchToProps &
  RouteComponentProps<any>;

const ConsentPage: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();

  if (props.isLoaderEnabled) {
    return null;
  }

  return (
    <ConsentPageContent
      t={t}
      replacementTokens={props.replacementTokens}
      terms={props.terms}
      bankName={props.selectedBank.name}
      bankLogoUrl={props.bankLogoUrl}
      brandId={props.selectedBank.brandId}
      clientId={props.application.clientId}
      hasBackButton={
        props.history.length > 0 &&
        !props.application.providerId &&
        !props.application.bankCode
      }
      onClickBack={props.onClickBack}
      useValidationToken={props.application.useValidationToken}
      oldConsent={props.oldConsent}
      consentStatus={props.consentStatus}
      redirectUrl={props.application.redirectUrl}
      isImplicit={props.isImplicit}
      showYapilyDisclaimer={props.showYapilyDisclaimer}
      consentId={props.application.consentId}
      providerSelection={props.providerSelection}
      onCreateConsentSuccess={props.onCreateConsentSuccess}
      disableDefaultDisclaimer={props.disableDefaultDisclaimer}
    />
  );
};

function getReplacementTokens(
  consentConfiguration?: IConsentConfigurationProps
) {
  const days: number =
    consentConfiguration && consentConfiguration.daysOfHistoricalTransactions
      ? consentConfiguration.daysOfHistoricalTransactions
      : 0;

  return { daysOfHistoricalTransactions: days };
}

const mapStateToProps = (state: IApplicationState) => ({
  selectedBank: state.selectedBank,
  bankLogoUrl: state.selectedBank.logoUrl || undefined,
  terms: state.consentConfiguration ? state.consentConfiguration.terms : '',
  isLoaderEnabled: state.loader.display,
  replacementTokens: getReplacementTokens(state.consentConfiguration),
  application: state.application,
  providerSelection: state.providerSelection,
  oldConsent: state.consentConfiguration?.oldConsentEndDate,
  consentStatus: state.consentConfiguration?.consentStatus,
  redirectUrl: state.application.redirectUrl,
  isImplicit: state.consentConfiguration?.isImplicit,
  showYapilyDisclaimer: getProviderSelected(state)?.dataSource === 'Yapily',
  disableDefaultDisclaimer: state.settings?.legal?.disableDefaultDisclaimer,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onClickBack: () => dispatch<GoBackAction>({ type: ActionTypes.GO_BACK }),
    onCreateConsentSuccess: (consentId: string | undefined) => dispatch<CreateConsentSuccessButtonAction>({ type: ActionTypes.CREATE_CONSENT_SUCCESS, payload: {consentId}})
});

export { ConsentPage };

export default connect<IStateToProps, IDispatchToProps, any, IApplicationState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ConsentPage));
