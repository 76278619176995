import { put, apply, getContext, select } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import ActionTypes from '../actions/ActionTypes';
import { Container } from 'inversify';
import getApplicationState from '../selectors/getApplicationState';
import { IApplicationProps } from '../../types/IApplicationProps';
import { GetServiceUrlsSuccessAction } from '../actions/GetServiceUrls';
import ConfigurationService from "../../domain/services/ConfigurationService"
import IConfigurationService from '../../domain/services/IConfigurationService';
import { ErrorAction } from '../actions/ApplicationAction';
import { ServiceUrls } from '../../types/ServiceUrls';

export default function* getDiscoveryUrls(): SagaIterator {
    const container: Container = yield getContext('container');
    const configurationService = container.get<IConfigurationService>(ConfigurationService);
    const applicationState: IApplicationProps = yield select(getApplicationState);
    
    try {
        const serviceUrls: ServiceUrls = yield apply(
            configurationService, 
            configurationService.getDiscoveryUrls, [
            applicationState.clientId
        ]);

        yield put<GetServiceUrlsSuccessAction>({
            type: ActionTypes.GET_DISCOVERY_URLS_SUCCESS,
            payload: serviceUrls
        });

    } catch (err) {
        yield put<ErrorAction>({
            type: ActionTypes.GET_DISCOVERY_URLS_ERROR,
            payload: err
        });
    }
}