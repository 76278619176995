import PermissionCluster from "../domain/enums/PermissionCluster";

interface PermissionContentDescription {
    title: string,
    content: string[],
};

const PermissionClusterDescriptions: Map<PermissionCluster, PermissionContentDescription> = new Map([
    [PermissionCluster.AccountDetails, {
        title: 'Your account details',
        content: [
            'Your account name, number and sort code',
            'Any other name by which you refer to this account',
            'Your Name, Email and Address',
        ]
    }],
    [PermissionCluster.Balances, {
        title: 'Your account balances',
        content: [
            'Your current and available balances',
        ]
    }],
    [PermissionCluster.Transactions, {
        title: 'Your account transactions',
        content: [
            'Incoming and outgoing transactions for the last {{daysOfHistoricalTransactions}} days',
        ]
    }],
    [PermissionCluster.StandingOrders, {
        title: 'Your standing orders',
        content: [
            'The name of the creditor',
            'The payment amount and frequency',
            'The date of the final payment',
        ]
    }],
    [PermissionCluster.DirectDebits, {
        title: 'Your direct debits',
        content: [
            'The payee name',
            'The date and amount of the most recent payment',
        ]
    }]
]);
export default PermissionClusterDescriptions;