import React from 'react';
import {
    Accordion as RAccordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import styles from './accordion.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

interface IItem {
    title: string,
    content: string | string[],
};

interface IProps {
    items: IItem[],
};

const getContent = (item: IItem) => {
    if (Array.isArray(item.content)) {
        const nodes = [];

        let idx = 0;
        for (const contentPart of item.content) {
            nodes.push(<li key={idx}>{contentPart}</li>);
            idx++;
        }

        return <ul>{nodes}</ul>;
    }

    return <p>{item.content}</p>;
};

const Accordion: React.FC<IProps> = (props: IProps) => {
    const elements: JSX.Element[] = [];

    for (const item of props.items) {
        const element = (
            <AccordionItem key={item.title} className={styles.accordionItem}>
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.accordionButton}>
                        {item.title}
                        <FontAwesomeIcon className={styles.accordionButtonArrow} icon={faCaretDown} />
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.accordionPanel}>
                    {getContent(item)}
                </AccordionItemPanel>
            </AccordionItem>
        );

        elements.push(element);
    }

    return <RAccordion className={styles.accordion} allowMultipleExpanded={true} allowZeroExpanded={true}>{elements}</RAccordion>;
};

export default Accordion;