import React from 'react';
import { useTranslation } from 'react-i18next';
import IConsentConfigurationProps from '../../types/IConsentConfigurationProps';
import { IApplicationState } from '../../types/applicationState';
import { connect } from 'react-redux';
import IdCoTermsContent from '../../components/consent/IdCoTermsContent';
import i18next from 'i18next';

interface IBasicProps {}

interface IStateToProps {
    revokedOrExpiredConsent: boolean;
    consent?: IConsentConfigurationProps;
}

type IProps = IStateToProps & IBasicProps;

const getConsentText = (
    t: i18next.TFunction,
    revokedOrExpiredConsent: boolean,
    consent?: IConsentConfigurationProps
) => {
    if (consent?.isOneTimeAccess) {
        return t('This is a one-time access consent');
    }

    if (consent?.consentEndDate) {
        return revokedOrExpiredConsent
            ? t('Once updated, this access is valid until ')
            : t('This access is valid until ');
    }

    return null;
};

const IdCoTerms: React.FC<IProps> = (props: IProps) => {
    const { consent, revokedOrExpiredConsent } = props;
    const { t } = useTranslation();
    const consentText = getConsentText(t, revokedOrExpiredConsent, consent);

    if (!props.consent) {
        return null;
    }

    return <IdCoTermsContent t={t} consentText={consentText} consentDate={consent?.consentEndDate} />;
};

const mapStateToProps = (state: IApplicationState) => ({
    consent: state.consentConfiguration,
    revokedOrExpiredConsent: !!state.consent,
});

export { IdCoTerms };
export default connect<IStateToProps, any, any, IApplicationState>(mapStateToProps, null)(IdCoTerms);
