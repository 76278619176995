import { IApplicationState } from "../../types/applicationState";
import ILoaderProps from "../../types/ILoaderProps";

export default function getLoaderState(state: IApplicationState): ILoaderProps  {
    
    if(!state || !state.loader)
    {
        throw Error();
    }
    
    return state.loader;
};