import { SagaIterator } from '@redux-saga/core';
import CompleteProviderChoiceAction from '../actions/CompleteProviderChoiceAction';
import { put, getContext, select, apply } from '@redux-saga/core/effects';
import { Container } from 'inversify';
import IConfigurationService from '../../domain/services/IConfigurationService';
import ConfigurationService from '../../domain/services/ConfigurationService';
import { IApplicationProps } from '../../types/IApplicationProps';
import GetTermsSuccessAction from '../actions/GetTermsSuccessAction';
import ActionTypes from '../actions/ActionTypes';
import ITermsDto from '../../domain/dto/ITermsDto';
import getApplicationState from '../selectors/getApplicationState';
import LoaderAction from '../actions/LoaderAction';
import { ErrorAction } from '../actions/ApplicationAction';
import IDateTimeSource from '../../utils/IDateTimeSource';
import DateTimeSource from '../../utils/DateTimeSource';
import getProviderSelected from '../../redux/selectors/getProviderSelected';
import getBankSelected from '../../redux/selectors/getBankSelected';
import { IProviderProps } from '../../types/IProviderProps';
import { IBankProps } from '../../types/IBankProps';
import IConsentStorage from '../../domain/services/IConsentStorage';
import ConsentStorage from '../../domain/services/ConsentStorage';
import getConsent from '../../redux/selectors/getConsent';
import IConsentProps from '../../types/IConsentProps';
import ConsentStatus from '../../domain/enums/ConsentStatus';

export default function* handleProviderSelection(action: CompleteProviderChoiceAction): SagaIterator {
    const container: Container = yield getContext('container');
    const configurationService: IConfigurationService = container.get<IConfigurationService>(ConfigurationService);
    const dateSource: IDateTimeSource = container.get<IDateTimeSource>(DateTimeSource);
    const applicationState: IApplicationProps = yield select(getApplicationState);
    const consentStorage: IConsentStorage = container.get<IConsentStorage>(ConsentStorage);
    
    try {
        const bankSelected: IBankProps = yield select(getBankSelected);

        yield put<LoaderAction>({
            type: ActionTypes.LOADER_EVENT,
            payload: {
                display: true,
                logoText: bankSelected.name,
                logoUrl: bankSelected?.logoUrl,
                framedLogo: true,
                spinnerText: "Loading...",
                showRecaptchaDisclaimer: false,
            },
        });

        const selectedProvider: IProviderProps = yield select(getProviderSelected);

        let oldConsentEndDate: Date | undefined = undefined;
        let consentEndDate: Date | undefined = undefined;
        let consentStatus: ConsentStatus = ConsentStatus.Pending;

        const consentConfiguration: ITermsDto = yield apply(configurationService, configurationService.getTerms, [
            applicationState.clientId,
            applicationState.language,
            applicationState.configurationId,
        ]);

        let daysOfHistoricalTransactions = consentConfiguration.daysOfHistoricalTransactions;
        let permissionClusters = consentConfiguration.permissionClusters;

        if (consentConfiguration.consentEnd) {
            consentEndDate = new Date(consentConfiguration.consentEnd);
        }

        if (selectedProvider.maxConsentDays) {
            var providerEndDate = dateSource.DateNow();
            providerEndDate.setUTCDate(providerEndDate.getUTCDate() + selectedProvider.maxConsentDays);

            if (consentEndDate === undefined || providerEndDate < consentEndDate) consentEndDate = providerEndDate;
        }

        if (applicationState.consentId) {
            var consent: IConsentProps = yield select(getConsent);

            daysOfHistoricalTransactions = consent.daysOfHistoricalTransactions;
            permissionClusters = consent.permissions;
            consentStatus = consent.consentStatus;
            if(consent.consentEnd) { 
                oldConsentEndDate = new Date(consent.consentEnd);
                consentStorage.setConsentEnd({
                    consentEnd: consentEndDate!.toUTCString(),
                    oldConsentEnd: oldConsentEndDate!.toUTCString(),
                });
            }
        }

        const terms = consentConfiguration.terms.length > 0 ? consentConfiguration.terms.first().termText : '';

        yield put<GetTermsSuccessAction>({
            type: ActionTypes.GET_TERMS_SUCCESS,
            payload: {
                consentConfiguration: {
                    terms: terms,
                    isImplicit: consentConfiguration.isImplicit,
                    isOneTimeAccess: consentConfiguration.isOneTimeAccess,
                    consentEndDate: consentEndDate,
                    oldConsentEndDate: oldConsentEndDate,
                    consentStatus: consentStatus, 
                    daysOfHistoricalTransactions: daysOfHistoricalTransactions,
                    permissionClusters: permissionClusters,
                },
            },
        });
    } catch (e) {
        yield put<ErrorAction>({
            type: ActionTypes.GET_TERMS_ERROR,
            payload: e,
        });
    }
}
