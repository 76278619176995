import { take, put } from '@redux-saga/core/effects';
import ActionTypes from '../actions/ActionTypes';
import { RouterAction, push } from 'react-router-redux';
import { SimpleApplicationAction } from '../actions/ApplicationAction';
import LoaderAction from '../actions/LoaderAction';

export default function* initBankSearch() {
    yield take(ActionTypes.GET_COUNTRY_CODES_SUCCESS);
    yield put<RouterAction>(push('/bank-select'));

    yield put<SimpleApplicationAction>({
        type: ActionTypes.INIT_BANK_SEARCH_STAGE_SUCCESS,
    });

    yield put<LoaderAction>({
        type: ActionTypes.LOADER_EVENT,
        payload: null,
    });
}