import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';

type NullableIProviderSelected = number | null;

const providerSelectedReducer: Reducer<NullableIProviderSelected> = (
    state: NullableIProviderSelected = null,
    action
): NullableIProviderSelected => {
    if (action.type !== ActionTypes.COMPLETE_PROVIDER_SELECTION && action.type !== ActionTypes.RESET_SELECTION_ACTION) {
        return state;
    }

    if (!action.payload || !action.payload.providerId) {
        return null;
    }

    return action.payload.providerId;
};

export default providerSelectedReducer;
