import React from 'react';
import IFormInputProps from './interfaces/IFormSelectProps';
import ISelectOption from './interfaces/ISelectOption';
import styles from './singleSelect.module.scss';

interface IProps extends IFormInputProps {
    options: ISelectOption[],
}

const SingleSelect: React.FC<IProps> = (props) => {
    const {options, onChangeValue, ...otherProps} = props;

    return (
        <select className={styles.select} onChange={onChangeValue} {...otherProps}>
            {options.map((opt) => {
                return <option className={styles.option} key={opt.value} value={opt.value}>{opt.label}</option>;
            })}
        </select>
    );
};

export default SingleSelect;