import { createStore, applyMiddleware} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './redux/sagas';
import combinedReducers from './redux/reducers/index'
import { History } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { Container } from 'inversify';
import applicationInsights from './redux/middlewares/applicationInsights';
import ThirdPartyTypes from './di/ThirdPartyTypes';
import ActionTypes from './redux/actions/ActionTypes';
import { nameof } from './utils/Nameof';
import IObfuscatedLoggedEvent from './redux/middlewares/IObfuscatedLoggedEvent';
import { ValidationTokenUpdateActionPayload }   from './redux/actions/ValidationTokenUpdateAction';


interface Context {
    container: Container,
    correlationId: String,
};

const loggedEvents:(ActionTypes | IObfuscatedLoggedEvent)[] =  [
    ActionTypes.INIT_STATE,
    ActionTypes.INIT_STATE_ERROR,
    ActionTypes.INIT_GET_SETTINGS_SUCCESS,
    ActionTypes.INIT_STATE_SUCCESS,
    ActionTypes.COMPLETE_PROVIDER_SELECTION,
    ActionTypes.GET_FAVOURITE_BANKS,
    ActionTypes.GET_FAVOURITE_BANKS_ERROR,
    ActionTypes.GET_FAVOURITE_BANKS_SUCCESS,
    ActionTypes.GET_BANKS,
    ActionTypes.GET_BANKS_ERROR,
    ActionTypes.GET_BANKS_SUCCESS,
    ActionTypes.COMPLETE_BANK_SELECTION,
    ActionTypes.CREATE_PROVIDER_SELECTION,
    ActionTypes.PROVIDER_PRESELECTION,
    ActionTypes.PROVIDER_PRESELECTION_SUCCESS,
    ActionTypes.PROVIDER_PRESELECTION_ERROR,
    ActionTypes.BANK_CODE_PRESELECTION,
    ActionTypes.BANK_CODE_PRESELECTION_SUCCESS,
    ActionTypes.BANK_CODE_PRESELECTION_ERROR,
    ActionTypes.UPDATE_SELECTED_ACCOUNT_TYPE,
    ActionTypes.COMPLETE_CONNECTION,
    ActionTypes.CONSENT_ACCEPTED,
    ActionTypes.CONSENT_ACCEPTED_ERROR,
    ActionTypes.CREATE_CONSENT_SUCCESS,
    ActionTypes.CREATE_CONSENT_ID_ERROR,
    ActionTypes.CREATE_CONSENT_ID_SUCCESS,
    ActionTypes.CREATE_CONSENT_ID_CONNECTION_ERROR,
    ActionTypes.CREATE_CONSENT_ID_CONNECTION_SUCCESS,
    ActionTypes.RECAPTCHA_UPDATE,
    ActionTypes.RECAPTCHA_ERROR,
    ActionTypes.EXIT_APPLICATION,
    ActionTypes.REDIRECTION_ERROR,
    {
        event: ActionTypes.VALIDATION_TOKEN_UPDATE,
        obfuscated: [nameof<ValidationTokenUpdateActionPayload>("validationToken")]
    }
];

export default function configureStore(history: History, context: Context) {
    const composeEnhancers = composeWithDevTools({});
    const saga = createSagaMiddleware({
        context: context,
    });
    const router = routerMiddleware(history);
    const insightsMiddleware = applicationInsights({
        insights: context.container.get(ThirdPartyTypes.ApplicationInsights),
        globals: {
            env: process.env.NODE_ENV,
            correlationId: context.correlationId,
            version: process.env.VERSION,
        },
        logPayload: true,
        loggedEvents: loggedEvents,
    });

    const store = createStore(
        combinedReducers,
        undefined,
        composeEnhancers(applyMiddleware(saga, router, insightsMiddleware))
    );

    saga.run(rootSaga);

    return store;
};
