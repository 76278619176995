/**
 * There are the types we may need to support. We can expand this list whenever needed.
 *
 * Please consider TextInput props before adding new types, e.g. "month" and "number"
 * types are not suitable despite technically being text inputs.
 */
enum TextInputTypes {
    TEXT = "text",
    EMAIL = "email",
    PASSWORD = "password",
    SEARCH = "search",
    DIGIT = "digit",
}

export default TextInputTypes;