import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import { IProviderSelectionProps } from '../../types/IProviderSelectionProps';
import { AccountType } from '../../types/AccountType';

const providerSelectionReducer: Reducer<IProviderSelectionProps | null> = (state: IProviderSelectionProps | null = null, action): IProviderSelectionProps | null => {
    if (action.type !== ActionTypes.CREATE_PROVIDER_SELECTION &&
        action.type !== ActionTypes.UPDATE_SELECTED_ACCOUNT_TYPE &&
        action.type !== ActionTypes.RESET_SELECTION_ACTION) {
        return state;
    }

    if (!action.payload || (!action.payload.providerSelection && !action.payload.accountType)) {
        return null;
    }

    if (state != null && action.payload.accountType) {
        let accountType = action.payload.accountType;
        let currentProviders = accountType === AccountType.Personal
            ? state.personalProviders.map(provider => ({ ...provider }))
            : state.businessProviders.map(provider => ({ ...provider }));
            
        return {
            selectedAccountType: action.payload.accountType,
            currentProviders: currentProviders,
            personalProviders: state.personalProviders.map(provider => ({ ...provider })),
            businessProviders: state.businessProviders.map(provider => ({ ...provider }))
        }
    }

    return action.payload.providerSelection;
};

export default providerSelectionReducer;
